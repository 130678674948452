import React from 'react';
import {Redirect} from 'react-router';
import {isAuthenticated} from '../../api';
import Page from '../../components/Page/Page';
import InviteMe from '../../forms/AnonymousIndexInviteMe/AnonymousIndexInviteMe';

const Index = () => {
  if (isAuthenticated()) {
    return <Redirect to='/projects' />;
  }

  return (
    <Page>
      <h1>Registrovat</h1>
      <div className='invite'>
        <InviteMe />
      </div>
    </Page>
  );
};

export default Index;
