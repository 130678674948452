import axios from 'axios';
import {push} from 'connected-react-router';
import {NotificationManager} from 'react-notifications';
import {displayErrors} from '../../api/index';
import {
  teamCreateMutation,
  teamFetchDataQuery,
  teamFetchQuery,
  teamUpdateMutation,
} from '../../api/queries';
import {setCookie} from '../../helpers/utils';

import * as actionTypes from './actionTypes';
import {customersFetch} from './customers';
import {formBegin, formError, formSuccess} from './forms';
import {projectsFetch} from './projects';
// eslint-disable-next-line import/no-cycle
import {userTeamsFetch} from './user';

export const teamFetchBegin = () => {
  return {
    type: actionTypes.FETCH_TEAM_BEGIN,
  };
};

export const teamFetchSuccess = team => {
  return {
    type: actionTypes.FETCH_TEAM_SUCCESS,
    id: Number(team.id),
    name: team.name,
    subscription: team.subscription,
    members: team.members,
    invitations: team.invitations,
  };
};

export const teamFetchFailure = error => {
  return {
    type: actionTypes.FETCH_TEAM_FAILURE,
    error,
  };
};

export const teamFetch = teamId => {
  return dispatch => {
    dispatch(teamFetchBegin());

    axios
      .post('/graphql', teamFetchQuery(teamId))
      .then(res => {
        console.log(res);
        displayErrors(res);
        const {team} = res.data.data;
        dispatch(teamFetchSuccess(team));
      })
      .catch(error => {
        dispatch(teamFetchFailure(error));
        NotificationManager.error('Internal error');
      });
  };
};

export const teamCreateBegin = id => {
  return {
    type: actionTypes.TEAM_CREATE_BEGIN,
    id,
  };
};

export const teamCreateSuccess = team => {
  return {
    type: actionTypes.TEAM_CREATE_SUCCESS,
    team,
  };
};

export const teamCreateFailure = (error, id) => {
  return {
    type: actionTypes.TEAM_CREATE_FAILURE,
    error,
    id,
  };
};

export const teamCreate = (name, emails) => {
  return dispatch => {
    dispatch(formBegin());
    dispatch(teamCreateBegin());

    axios
      .post('/graphql', teamCreateMutation(name, emails))
      .then(res => {
        displayErrors(res);
        const team = res.data.data.createTeam;
        setCookie('selected_team', team.id);
        dispatch(teamCreateSuccess(team));
        dispatch(formSuccess());
        dispatch(teamFetch(team.id));
        dispatch(projectsFetch());
        dispatch(customersFetch());
        dispatch(userTeamsFetch());
        dispatch(push('/projects'));
      })
      .catch(error => {
        dispatch(teamCreateFailure(error));
        dispatch(formError('Formulář se nepodařilo odeslat.'));
      });
  };
};

export const teamUpdateBegin = id => {
  return {
    type: actionTypes.TEAM_UPDATE_BEGIN,
    id,
  };
};

export const teamUpdateSuccess = team => {
  return {
    type: actionTypes.TEAM_UPDATE_SUCCESS,
    team,
  };
};

export const teamUpdateFailure = (error, id) => {
  return {
    type: actionTypes.TEAM_UPDATE_FAILURE,
    error,
    id,
  };
};

export const teamUpdate = (id, payload) => {
  return dispatch => {
    dispatch(formBegin());
    dispatch(teamUpdateBegin(id));

    axios
      .post('/graphql', teamUpdateMutation(id, payload))
      .then(res => {
        displayErrors(res);
        const team = res.data.data.updateTeam;
        dispatch(teamUpdateSuccess(team));
        dispatch(formSuccess());
      })
      .catch(error => {
        dispatch(teamUpdateFailure(error, id));
        dispatch(formError('Formulář se nepodařilo odeslat.'));
      });
  };
};

export const teamFetchData = teamId => {
  axios
    .post('/graphql', teamFetchDataQuery(teamId))
    .then(res => {
      displayErrors(res);
      return res.data.data.teamName;
    })
    .catch(() => {
      NotificationManager.error('Internal error');
    });
};
