import {useEffect, useState} from 'react';
// eslint-disable-next-line import/named
import {memberRoles} from '../consts/consts';

const useMembersState = roles => {
  const [memberRole, setMemberRole] = useState(1);

  // Save selected customer.
  useEffect(() => {
    const role = roles[0].type;
    const stateValue = memberRoles.find(memberState => {
      return memberState.id === role;
    });

    if (stateValue !== undefined) {
      setMemberRole(stateValue);
    }
  }, [memberRoles, roles]);

  return {
    id: memberRole.id,
    value: memberRole.title,
  };
};

export default useMembersState;
