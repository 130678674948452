import React from 'react';
import {NavLink} from 'react-router-dom';
import './AnonymousHeader.scss';

const AnonymousHeader = () => {
  return (
    <div className='anonymous-header'>
      <div className='branding'>
        <a href='https://terik.cz/'>Terik</a>
      </div>
      <div className='links'>
        <NavLink to='/login'>Přihlásit</NavLink>
        <NavLink to='/register'>Registrovat</NavLink>
      </div>
    </div>
  );
};

export default AnonymousHeader;
