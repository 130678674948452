/* eslint-disable react/jsx-props-no-spreading, no-undef */
import {yupResolver} from '@hookform/resolvers/yup';
import parseISO from 'date-fns/parseISO';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import * as yup from 'yup';
import Form from '../../components/Form/Form';
import FormActions from '../../components/FormActions/FormActions';
import FormField from '../../components/FormField/FormField';
import Button from '../../components/UI/Button/Button';
import InputDate from '../../components/UI/InputDate/InputDate';
import {dateToTimestamp} from '../../helpers/utils';
import useReduxForm from '../../hooks/useReduxForm';
import {projectUpdate} from '../../store/actions';

// Constraints.
const constraints = yup.object().shape({});

const ProjectsTermsUpdateForm = ({onSubmit, project}) => {
  const dispatch = useDispatch();
  const {loading, submitErrors} = useReduxForm(onSubmit);

  const {start, deadline} = project;

  // Default values.
  const defaultValues = {
    start: start ? parseISO(start) : null,
    deadline: deadline ? parseISO(deadline) : null,
  };

  const {
    control,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm({
    defaultValues,
    resolver: yupResolver(constraints),
  });

  // Handle submit.
  const handleFormSubmit = data => {
    const {id} = project;

    const payload = {};
    if (data.start) {
      payload.start = dateToTimestamp(data.start);
    }
    if (data.deadline) {
      payload.deadline = dateToTimestamp(data.deadline);
    }

    dispatch(projectUpdate(id, payload));
  };

  // Render component.
  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Form loading={loading} errors={submitErrors}>
        <FormField label='Datum zahájení' errors={errors.start?.message}>
          <Controller
            name='start'
            control={control}
            value={getValues('start')}
            render={({field}) => <InputDate {...field} />}
          />
        </FormField>

        <FormField label='Datum dokončení' errors={errors.deadline?.message}>
          <Controller
            name='deadline'
            control={control}
            value={getValues('deadline')}
            render={({field}) => <InputDate {...field} />}
          />
        </FormField>
        <FormActions>
          <Button value='Uložit změny' type='submit' width='full' />
        </FormActions>
      </Form>
    </form>
  );
};

export default ProjectsTermsUpdateForm;
