/* eslint-disable react/jsx-props-no-spreading, no-undef */
import {yupResolver} from '@hookform/resolvers/yup';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import * as yup from 'yup';
import Form from '../../components/Form/Form';
import FormActions from '../../components/FormActions/FormActions';
import FormField from '../../components/FormField/FormField';
import Button from '../../components/UI/Button/Button';
import InputTypePrice from '../../components/UI/InputTypeText/InputTypePrice';
import SelectMemberRole from '../../components/UI/SelectMemberRole/SelectMemberRole';
import SelectMemberState from '../../components/UI/SelectMemberState/SelectMemberState';
import useReduxForm from '../../hooks/useReduxForm';
import {memberUpdate} from '../../store/actions';

// Constraints.
const constraints = yup.object().shape({});

const TeamMemberUpdateForm = ({onSubmit, member}) => {
  const dispatch = useDispatch();
  const {loading, submitErrors} = useReduxForm(onSubmit);

  const {price, sell_price, roles, state} = member;

  // Default values.
  const defaultValues = {
    state: state || 0,
    role: roles ? roles[0].type : 0,
    buy_price: price || 0,
    sell_price: sell_price || 0,
  };

  const {
    control,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm({
    defaultValues,
    resolver: yupResolver(constraints),
  });

  // Handle submit.
  const handleFormSubmit = data => {
    const {id} = member;

    const payload = {};
    payload.state = data.state || 0;
    payload.role = data.role || 0;
    payload.price = data.buy_price || 0;
    payload.sell_price = data.sell_price || 0;

    dispatch(memberUpdate(id, payload));
  };

  // Render component.
  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Form loading={loading} errors={submitErrors}>
        <FormField label='Stav' errors={errors.state?.message}>
          <Controller
            name='state'
            control={control}
            value={getValues('state')}
            render={({field}) => <SelectMemberState {...field} />}
          />
        </FormField>
        <FormField label='Role' errors={errors.role?.message}>
          <Controller
            name='role'
            control={control}
            value={getValues('role')}
            render={({field}) => <SelectMemberRole {...field} />}
          />
        </FormField>
        <FormField label='Cena nákupu práce za hodinu' errors={errors.buy_price?.message}>
          <Controller
            name='buy_price'
            control={control}
            value={getValues('buy_price')}
            render={({field}) => <InputTypePrice {...field} />}
          />
        </FormField>
        <FormField label='Cena prodeje práce za hodinu' errors={errors.sell_price?.message}>
          <Controller
            name='sell_price'
            control={control}
            value={getValues('sell_price')}
            render={({field}) => <InputTypePrice {...field} />}
          />
        </FormField>
        <FormActions>
          <Button value='Uložit změny' type='submit' width='full' />
        </FormActions>
      </Form>
    </form>
  );
};

export default TeamMemberUpdateForm;
