// eslint-disable react/no-danger
import React, {useState} from 'react';
import './ProjectSpecification.scss';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import ProjectSpecificationUpdateForm from '../../forms/ProjectSpecificationUpdateForm/ProjectSpecificationUpdateForm';
import {IconCollapseClose, IconCollapseOpen, IconInvoice} from '../../helpers/icons';
import {formInit} from '../../store/actions';
import Empty from '../Empty/Empty';
import SectionActions from '../SectionActions/SectionActions';
import Button from '../UI/Button/Button';
import ButtonEdit from '../UI/ButtonEdit/ButtonEdit';
import Modal from '../UI/Modal/Modal';

const ProjectSpecification = () => {
  const [collapsed, setCollapsed] = useState(true);

  const {project} = useSelector(
    state => ({
      project: state.project.project,
    }),
    shallowEqual
  );
  const [isShowingUpdateModal, toggleUpdateModal] = useState(false);
  const dispatch = useDispatch();

  const openUpdateForm = () => {
    dispatch(formInit());
    toggleUpdateModal(true);
  };

  const handleUpdate = () => {
    toggleUpdateModal(false);
  };

  const projectSpecificationEmpty = (
    <Empty title='Specifikace projektu'>
      Zapište si důležité informace na základě zadání klientem či Vaše vlastní poznámky. <br />
      Tyto poznámky budou viditelné i klientům.
    </Empty>
  );

  return (
    <>
      {project.customer_specification !== null && project.customer_specification.trim() !== '' ? (
        <ButtonEdit callback={openUpdateForm} />
      ) : null}
      {project.customer_specification === null || project.customer_specification.trim() === '' ? (
        <>
          {projectSpecificationEmpty}
          <SectionActions>
            <Button
              onClick={openUpdateForm}
              value='Přidat specifikaci'
              icon={<IconInvoice />}
              size='bigger'
            ></Button>
          </SectionActions>
        </>
      ) : (
        <div className={`collapse collapse--${collapsed ? 'closed' : 'open'}`}>
          <div
            className='html-content'
            dangerouslySetInnerHTML={{__html: project.customer_specification}}
          />
          <button
            type='button'
            className='collapse-trigger'
            onClick={() => setCollapsed(!collapsed)}
          >
            {collapsed ? <IconCollapseOpen /> : <IconCollapseClose />}
          </button>
        </div>
      )}
      <Modal
        isShowing={isShowingUpdateModal}
        title='Upravit specifikaci'
        hide={() => toggleUpdateModal(false)}
        type='large'
      >
        <ProjectSpecificationUpdateForm onSubmit={handleUpdate} project={project} />
      </Modal>
    </>
  );
};

export default ProjectSpecification;
