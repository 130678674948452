import * as actionTypes from '../actions/actionTypes';

const initialState = {
  form: {
    loading: false,
    errors: null,
    submitted: false,
  },
};

const formInit = state => {
  return {
    ...state,
    form: {
      ...state.form,
      loading: false,
      errors: null,
      submitted: false,
    },
  };
};

const formBegin = state => {
  return {
    ...state,
    form: {
      ...state.form,
      loading: true,
      errors: null,
      submitted: false,
    },
  };
};

const formSuccess = state => {
  return {
    ...state,
    form: {
      ...state.form,
      loading: false,
      errors: null,
      submitted: true,
    },
  };
};

const formError = (state, action) => {
  return {
    ...state,
    form: {
      ...state.form,
      loading: false,
      errors: action.errors,
      submitted: false,
    },
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FORM_INIT:
      return formInit(state);
    case actionTypes.FORM_BEGIN:
      return formBegin(state);
    case actionTypes.FORM_SUCCESS:
      return formSuccess(state);
    case actionTypes.FORM_ERROR:
      return formError(state, action);
    default:
      return state;
  }
};

export default reducer;
