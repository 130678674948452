import isDate from 'date-fns/isDate';

import {cs} from 'date-fns/locale';
import React, {useEffect, useState} from 'react';
import {DateRangePicker} from 'react-nice-dates';
import './InputDate.scss';

const InputDateRange = ({startDate, endDate, onStartDateSelect, onEndDateSelect}) => {
  const [localStartValue, setLocalStartValue] = useState(null);
  const [localEndValue, setLocalEndValue] = useState(null);

  useEffect(() => {
    if (isDate(startDate)) {
      setLocalStartValue(startDate);
    } else {
      setLocalStartValue(null);
    }

    if (isDate(endDate)) {
      setLocalEndValue(endDate);
    } else {
      setLocalEndValue(null);
    }
  }, [startDate, endDate]);

  const handleStartDateSelection = value => {
    if (isDate(value)) {
      setLocalStartValue(value);
      onStartDateSelect(value);
    }
  };

  const handleEndDateSelection = value => {
    if (isDate(value)) {
      setLocalEndValue(value);
      onEndDateSelect(value);
    }
  };

  return (
    <div className='field_input--wrapper'>
      <DateRangePicker
        startDate={localStartValue}
        endDate={localEndValue}
        onStartDateChange={handleStartDateSelection}
        onEndDateChange={handleEndDateSelection}
        format='dd. MM. yyyy'
        locale={cs}
      >
        {/* eslint-disable react/jsx-props-no-spreading */}
        {/* eslint-disable-next-line no-unused-vars */}
        {({startDateInputProps, endDateInputProps, focus}) => (
          <>
            <div className='field_input_wrapper'>
              <input className='field_input--date' {...startDateInputProps} />
              <span className='field_input--date_divider' />
              <input className='field_input--date' {...endDateInputProps} />
            </div>
          </>
        )}
      </DateRangePicker>
    </div>
  );
};

export default InputDateRange;
