import React from 'react';
import './FormErrors.scss';

const FormErrors = ({errors = null}) => {
  if (errors === null) {
    return null;
  }

  if (typeof errors === 'string') {
    return <div className='form__errors'>{errors}</div>;
  }

  if (typeof errors === 'object') {
    const errorsList = Object.values(errors);
    const errorsMessages = errorsList.map((error, index) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <div className='form__error' key={`form_error_${index}`}>
          {error.message}
        </div>
      );
    });
    return <div className='form__errors'>{errorsMessages}</div>;
  }

  return null;
};

export default FormErrors;
