import React from 'react';
import './Row.scss';
import RowActions from '../RowActions/RowActions';

const Row = ({children, actions = null}) => {
  const rowActions = actions ? <RowActions>{actions}</RowActions> : null;
  return (
    <div className='row'>
      <div className='row__content'>{children}</div>
      {rowActions}
    </div>
  );
};

export default Row;
