import React, {useEffect} from 'react';
import {CSSTransition} from 'react-transition-group';
import AnonymousHeader from '../components/AnonymousHeader/AnonymousHeader';
import Toolbar from '../components/Toolbar/Toolbar';
import '../scss/terik.scss';
import useAuth from '../hooks/useAuth';

const Layout = ({children}) => {
  const {authenticated} = useAuth();

  useEffect(() => {
    const {body} = document;
    body.classList.remove('authenticated', 'anonymous');
    body.classList.add(authenticated ? 'authenticated' : 'anonymous');
  }, [authenticated]);

  return (
    <CSSTransition in timeout={3000} classNames='page' unmountOnExit>
      <>
        {authenticated ? <Toolbar /> : <AnonymousHeader />}
        {children}
      </>
    </CSSTransition>
  );
};

export default Layout;
