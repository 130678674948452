import React from 'react';
import './InputTypeText.scss';

const InputTypeText = ({value, onChange}) => {
  const handleChange = event => {
    onChange(event.target.value);
  };

  const handleUpdate = event => {
    onChange(event.target.value);
  };

  const handleKeyDown = event => {
    const characterCode = event.keyCode;
    if (characterCode === 13 || characterCode === 27) {
      onChange(event.target.value);
    }
  };

  return (
    <input
      value={value}
      type='text'
      onBlur={handleUpdate}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      autoCorrect='off'
      autoCapitalize='off'
      className='field_input--text'
    />
  );
};

export default InputTypeText;
