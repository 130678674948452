import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {IconX} from '../../../helpers/icons';
import useMembers from '../../../hooks/useMembers';
import InputSelect from '../InputSelect/InputSelect';

const SelectMember = ({value, onChange}) => {
  const {member, members, setMemberId} = useMembers(value);

  // Initialize values.
  useEffect(() => {
    setMemberId(value);
  }, [value]);

  // Handle selection.
  const handleSelect = value => {
    const newId = value.value;
    setMemberId(newId);
    onChange(parseInt(newId, 10));
  };

  // Handle selection.
  const handleReset = () => {
    onChange(null);
    setMemberId(null);
  };

  return (
    <div className='field_input--wrapper'>
      <InputSelect
        options={members}
        value={member}
        placeholder={member ? member.label : null}
        onChange={handleSelect}
        classNamePrefix
      />
      {member !== null ? (
        <button type='button' className='button--reset' onClick={handleReset}>
          <IconX />
        </button>
      ) : null}
    </div>
  );
};

export default SelectMember;

SelectMember.defaultProps = {
  value: null,
};

SelectMember.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};
