import axios from 'axios';
import {querify} from '../../helpers/helpers';
import * as actionTypes from './actionTypes';
// eslint-disable-next-line import/no-cycle
import {projectUpdateValues} from './project';

export const formInit = () => {
  return {
    type: actionTypes.FORM_INIT,
  };
};

export const formBegin = () => {
  return {
    type: actionTypes.FORM_BEGIN,
  };
};

export const formSuccess = () => {
  return {
    type: actionTypes.FORM_SUCCESS,
  };
};

export const formError = (message, errors) => {
  return {
    type: actionTypes.FORM_ERROR,
    errors,
    message,
  };
};

const economyCreateMutation = (id, payload) => {
  return {
    query: `
      mutation {
        updateProject(id: ${id}, payload: ${querify(payload)}) {
          id
          economy_turnover_price
          economy_return_price
        }
      }
    `,
  };
};

export const economyCreate = (id, payload) => {
  return dispatch => {
    // Begin form.
    dispatch(formBegin());

    // Make request.
    axios
      .post('/graphql', economyCreateMutation(id, payload))
      .then(res => {
        // If we have errors display them.
        if (res.data.errors) {
          dispatch(formError('Při uložení nastala chyba.', res.data.errors));
        }

        // Finish submitting if we have data.
        if (res.data.data) {
          const values = res.data.data.updateProject;
          // Finish form submitting.
          dispatch(formSuccess());
          // Update project data.
          dispatch(projectUpdateValues(values));
        }
      })
      .catch(error => {
        // If we have errors display them.
        dispatch(formError('Formulář se nepodařilo odeslat.', error));
      });
  };
};
