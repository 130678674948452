import {NotificationManager} from 'react-notifications';
import {getCookie} from '../helpers/utils';

export const estimationSummary = estimation => {
  let internalCostsTime = 0;
  let internalCostsSellPrice = 0;
  let externalCostsPrice = 0;
  let externalCostsSellPrice = 0;
  let costs = 0;
  let sell = 0;

  if (estimation.members) {
    Object.values(estimation.members).forEach(member => {
      internalCostsTime += member.time;
      costs += member.price;
      internalCostsSellPrice += member.sell_price;
      sell += member.sell_price;
    });
  }

  if (estimation.outsources) {
    Object.values(estimation.outsources).forEach(outsource => {
      externalCostsPrice += outsource.price;
      costs += outsource.price;
      externalCostsSellPrice += outsource.sell_price;
      sell += outsource.sell_price;
    });
  }

  return {
    internalCostsTime,
    internalCostsSellPrice,
    externalCostsPrice,
    externalCostsSellPrice,
    costs,
    sell,
  };
};

export const projectTasksPercentage = project => {
  if (project === undefined || project === null) {
    return null;
  }

  const confirmedEstimations = project.estimations.filter(estimation => {
    return estimation.state === 2;
  });

  if (confirmedEstimations.length === 0) {
    return null;
  }

  const confirmedEstimation = confirmedEstimations[0];

  if (confirmedEstimation.members.length <= 0) {
    return null;
  }

  let estimationTimes = 0;
  if (confirmedEstimation.members) {
    Object.values(confirmedEstimation.members).forEach(value => {
      estimationTimes += value.time;
    });
  }

  if (project.times.length <= 0) {
    return null;
  }
  let projectTimes = 0;
  Object.values(project.times).forEach(value => {
    projectTimes += value.time;
  });

  if (projectTimes === 0) {
    return 0;
  }

  return Math.floor((projectTimes / estimationTimes) * 100);
};

export const projectOutsourcesPercentage = project => {
  if (project === undefined || project === null) {
    return null;
  }

  const confirmedEstimations = project.estimations.filter(estimation => {
    return estimation.state === 2;
  });

  if (confirmedEstimations.length === 0) {
    return null;
  }

  const confirmedEstimation = confirmedEstimations[0];

  if (confirmedEstimation.outsources.length <= 0) {
    return null;
  }

  let estimationOutsources = 0;

  Object.values(confirmedEstimation.outsources).forEach(value => {
    estimationOutsources += value.price;
  });

  if (project.outsources.length <= 0) {
    return 0;
  }

  let oustourcesPrices = 0;

  Object.values(project.outsources).forEach(value => {
    oustourcesPrices += value.buy_price;
  });

  if (oustourcesPrices === 0) {
    return 0;
  }

  return Math.floor((oustourcesPrices / estimationOutsources) * 100);
};

export const projectReport = project => {
  if (project === undefined || project === null) {
    return {};
  }

  const confirmedEstimations = project.estimations.filter(estimation => {
    return estimation.state === 2;
  });

  if (confirmedEstimations.length === 0) {
    return {};
  }

  const confirmedEstimation = confirmedEstimations[0];

  // Estimated outsources.
  let estimationOutsources = 0;

  Object.values(confirmedEstimation.outsources).forEach(value => {
    estimationOutsources += value.price;
  });

  // Estimated time.
  let estimationTimes = 0;
  let estimatedTimes = [];

  if (confirmedEstimation.members) {
    estimatedTimes = confirmedEstimation.members;
    Object.values(confirmedEstimation.members).forEach(value => {
      estimationTimes += value.time;
    });
  }

  // Used outsources.
  let oustourcesPrices = 0;

  Object.values(project.outsources).forEach(value => {
    oustourcesPrices += value.buy_price;
  });

  // Used time.
  let projectTimes = 0;
  Object.values(project.times).forEach(value => {
    projectTimes += value.time;
  });

  return {
    outsourceEstimated: estimationOutsources,
    outsourceUsed: oustourcesPrices,
    outsourcePercentage: Math.floor((oustourcesPrices / estimationOutsources) * 100),
    timeEstimated: estimationTimes,
    timeUsed: projectTimes,
    timePercentage: Math.floor((projectTimes / estimationTimes) * 100),
    estimatedTimes,
  };
};

export const displayErrors = res => {
  if (res.data.errors) {
    Object.values(res.data.errors).forEach(error => {
      NotificationManager.error(error.debugMessage || error.message, null, 10000);
    });
  }
};

export const isAuthenticated = () => {
  const accessToken = getCookie('access_token');

  if (!accessToken) {
    return false;
  }

  const expirationDate = new Date(Date.parse(getCookie('expiration_date')));
  // Check validity of expiration date.
  return expirationDate > new Date();
};
