import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../store/actions';
import Form from '../../components/Form/Form';
import FormBase from '../../components/FormBase';
import FormField from '../../components/FormField/FormField';
import Input from '../../components/UI/Input/Input';
import Spinner from '../../components/UI/Spinner/Spinner';

class AnonymousRegisterForm extends FormBase {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.handleLogin = this.handleLogin.bind(this);

    this.state = {
      formConfig: {
        name: {
          elementType: 'input',
          elementLabel: 'Vaše jméno',
          elementConfig: {
            type: 'text',
            placeholder: '',
            autoFocus: true,
          },
          elementValue: '',
          elementValidation: {
            required: true,
            minLength: 3,
          },
          elementShouldValidate: true,
          elementValid: false,
          elementTouched: false,
        },
        email: {
          elementType: 'input',
          elementLabel: 'Emailová adresa (nelze změnit)',
          elementConfig: {
            type: 'email',
            placeholder: '',
            disabled: true,
          },
          elementValue: props.email,
          elementValidation: {
            required: true,
            email: true,
          },
          elementShouldValidate: true,
          elementValid: true,
          elementTouched: true,
        },
        password: {
          elementType: 'input',
          elementLabel: 'Heslo',
          elementConfig: {
            type: 'password',
            placeholder: '',
          },
          elementValue: '',
          elementValidation: {
            required: true,
          },
          elementShouldValidate: true,
          elementValid: false,
          elementTouched: false,
        },
      },
      formIsValid: false,
    };
  }

  handleRegister(event) {
    event.preventDefault();
    const {formConfig} = this.state;
    const name = formConfig.name.elementValue;
    const email = formConfig.email.elementValue;
    const password = formConfig.password.elementValue;
    const {createUser, token: token1} = this.props;
    const token = token1;

    createUser(name, email, password, token);
  }

  handleLogin(event) {
    event.preventDefault();
    const {team, history, match} = this.props;

    if (!team) {
      history.push(`/login/${match.params.token}/${match.params.email}`);
    } else {
      history.push(`/login/${match.params.token}/${match.params.email}/${match.params.team}`);
    }
  }

  renderFormContent() {
    const formElementArray = [];
    // eslint-disable-next-line no-restricted-syntax,guard-for-in
    for (const key in this.state.formConfig) {
      formElementArray.push({
        id: key,
        config: this.state.formConfig[key],
      });
    }

    let primaryButtonLabel = 'Dokončit registraci';
    if (this.props.team) {
      primaryButtonLabel = 'Dokončit registraci';
    }

    return (
      <Form className='form--centered form--bordered form--white'>
        {formElementArray.map(formElement => (
          <FormField label={formElement.config.elementLabel}>
            <Input
              key={formElement.id}
              elementType={formElement.config.elementType}
              elementName={formElement.id}
              elementConfig={formElement.config.elementConfig}
              elementValue={formElement.config.elementValue}
              elementChanged={event => this.inputChangedHandler(event, formElement.id)}
              elementValid={formElement.config.elementValid}
              elementShouldValidate={formElement.config.elementShouldValidate}
              elementTouched={formElement.config.elementTouched}
            />
          </FormField>
        ))}
        <div className='form__actions'>
          <button
            type='button'
            className='btn btn--primary'
            disabled={!this.state.formIsValid}
            onClick={this.handleRegister}
          >
            {primaryButtonLabel}
          </button>
        </div>
        {this.props.loadedTeam ? (
          <div className='instructions'>
            Registrací se stanete součástí teamu {this.props.loadedTeam.name}.
          </div>
        ) : null}
      </Form>
    );
  }

  render() {
    return (
      <form className='register-me-form form'>
        {this.props.loading ? <Spinner /> : this.renderFormContent()}
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createUser: (name, email, password, token) =>
      dispatch(actions.register(name, email, password, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnonymousRegisterForm);
