import {format} from 'date-fns';
import parseISO from 'date-fns/parseISO';
import React from 'react';
import './TeamMembersInvites.scss';
import Cell from '../Cell/Cell';
import CellGroup from '../CellGroup/CellGroup';
import Row from '../Row/Row';
import State from '../State/State';

const TeamMembersInvites = ({invitations}) => {
  if (!invitations) {
    return <p>Zatím nemáte žádné pozvané členy</p>;
  }

  const invitationsContent = invitations.map(invitation => (
    <Row>
      <CellGroup>
        <Cell type='state'>
          <State
            value={invitation.accepted ? 'Přijatá' : 'Čekající'}
            type={invitation.accepted ? 1 : 0}
          />
        </Cell>
        <Cell type='email' size='auto'>
          {invitation.email}
        </Cell>

        <Cell type='datetime' align='right'>
          {invitation.created_at
            ? format(parseISO(invitation.created_at), 'dd. MM. yyyy kk:mm')
            : '---'}
        </Cell>
      </CellGroup>
    </Row>
  ));

  return invitationsContent;
};

export default TeamMembersInvites;
