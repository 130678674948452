import axios from 'axios';
import {NotificationManager} from 'react-notifications';
import {projectsFetchQuery} from '../../api/queries';
import * as actionTypes from './actionTypes';

export const projectsFetchBegin = () => {
  return {
    type: actionTypes.FETCH_PROJECTS_BEGIN,
  };
};

export const projectsFetchSuccess = projects => {
  return {
    type: actionTypes.FETCH_PROJECTS_SUCCESS,
    projects,
  };
};

export const projectsFetchFailure = error => {
  return {
    type: actionTypes.FETCH_PROJECTS_FAILURE,
    error,
  };
};

export const projectsFetch = () => {
  return dispatch => {
    dispatch(projectsFetchBegin());

    axios
      .post('/graphql', projectsFetchQuery())
      .then(res => {
        const {projects: projects1} = res.data.data;
        const projects = projects1;
        dispatch(projectsFetchSuccess(projects));
      })
      .catch(error => {
        dispatch(projectsFetchFailure(error));
        NotificationManager.error('Internal error');
      });
  };
};

export const updateProjectsFilters = (filter, key, value) => {
  return {
    type: actionTypes.UPDATE_PROJECTS_FILTERS,
    filter,
    key,
    value,
  };
};

export const updateProjectsSearchFilters = value => {
  return {
    type: actionTypes.UPDATE_PROJECTS_SEARCH_FILTERS,
    search: value,
  };
};

export const updateProjectsDateFromFilters = value => {
  return {
    type: actionTypes.UPDATE_PROJECTS_DATE_FROM_FILTERS,
    dateFrom: value,
  };
};

export const updateProjectsDateToFilters = value => {
  return {
    type: actionTypes.UPDATE_PROJECTS_DATE_TO_FILTERS,
    dateTo: value,
  };
};

export const updateProjectsDateTypeFilters = value => {
  return {
    type: actionTypes.UPDATE_PROJECTS_DATE_TYPE_FILTERS,
    dateType: value,
  };
};

export const cleanBalance = () => {
  return {
    type: actionTypes.BALANCE_CLEAR,
  };
};

export const updateProjectsList = (id, property, value) => {
  return {
    type: actionTypes.UPDATE_PROJECTS_LIST,
    id,
    property,
    value,
  };
};

export const findProjectById = id => {
  return (dispatch, getState) => {
    const state = getState();
    const projects = state.projects.items;

    if (projects && id) {
      const project = projects.find(projectOption => {
        return projectOption.id === id;
      });

      return project;
    }

    return null;
  };
};
