/* eslint-disable react/jsx-props-no-spreading, no-undef */
import {yupResolver} from '@hookform/resolvers/yup';
import parseISO from 'date-fns/parseISO';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import * as yup from 'yup';
import Form from '../../components/Form/Form';
import FormActions from '../../components/FormActions/FormActions';
import FormField from '../../components/FormField/FormField';
import Button from '../../components/UI/Button/Button';
import InputDate from '../../components/UI/InputDate/InputDate';
import InputTypeHours from '../../components/UI/InputTypeText/InputTypeHours';
import InputTypePrice from '../../components/UI/InputTypeText/InputTypePrice';
import InputTypeText from '../../components/UI/InputTypeText/InputTypeText';
import SelectMember from '../../components/UI/SelectMember/SelectMember';
import {formatDecimalNumber} from '../../helpers/helpers';
import {dateToTimestamp} from '../../helpers/utils';
import useReduxForm from '../../hooks/useReduxForm';
import {timeUpdate} from '../../store/actions';

// Constraints.
const constraints = yup.object().shape({});

const ProjectTimeUpdateForm = ({onSubmit, time}) => {
  const dispatch = useDispatch();
  const {loading, submitErrors} = useReduxForm(onSubmit);

  const {description, member, day, time: timeValue, buy_price, sell_price} = time;

  // Default values.
  const defaultValues = {
    description: description || '',
    member: member.id,
    day: day ? parseISO(day) : null,
    time: timeValue || 0,
    buy_price: buy_price || 0,
    sell_price: sell_price || 0,
  };

  const {
    control,
    handleSubmit,
    getValues,
    watch,
    formState: {errors},
  } = useForm({
    defaultValues,
    resolver: yupResolver(constraints),
  });

  // Handle submit.
  const handleFormSubmit = data => {
    const {id} = time;

    const payload = {};
    payload.description = data.description || '';
    payload.member = data.member || null;
    if (data.day) {
      payload.day = dateToTimestamp(data.day);
    }
    payload.time = data.time || 0;
    payload.buy_price = data.buy_price || 0;
    payload.sell_price = data.sell_price || 0;

    dispatch(timeUpdate(id, payload));
  };

  // Render component.
  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Form loading={loading} errors={submitErrors}>
        <FormField label='Člen' errors={errors.member?.message}>
          <Controller
            name='member'
            control={control}
            value={getValues('member')}
            render={({field}) => <SelectMember {...field} />}
          />
        </FormField>
        <FormField label='Krátký popis práce' errors={errors.description?.message}>
          <Controller
            name='description'
            control={control}
            value={getValues('description')}
            render={({field}) => <InputTypeText {...field} />}
          />
        </FormField>
        <div className='field__group'>
          <FormField label='Den' className='time-day' errors={errors.day?.message}>
            <Controller
              name='day'
              control={control}
              value={getValues('day')}
              render={({field}) => <InputDate {...field} />}
            />
          </FormField>
          <FormField label='Čas' className='time-time' errors={errors.time?.message}>
            <Controller
              name='time'
              control={control}
              value={getValues('time')}
              render={({field}) => <InputTypeHours {...field} />}
            />
          </FormField>
        </div>
        <div className='field__group'>
          <FormField label='Cena nákupu práce za hodinu' errors={errors.buy_price?.message}>
            <Controller
              name='buy_price'
              control={control}
              value={getValues('buy_price')}
              render={({field}) => <InputTypePrice {...field} />}
            />
          </FormField>
          <FormField label='Cena za nákup celkem'>
            {formatDecimalNumber((watch('buy_price') || 0) * (watch('time') / 60))}
          </FormField>
        </div>
        <div className='field__group'>
          <FormField label='Cena prodeje práce za hodinu' errors={errors.sell_price?.message}>
            <Controller
              name='sell_price'
              control={control}
              value={getValues('sell_price')}
              render={({field}) => <InputTypePrice {...field} />}
            />
          </FormField>
          <FormField label='Cena za prodej celkem'>
            {formatDecimalNumber((watch('sell_price') || 0) * (watch('time') / 60))}
          </FormField>
        </div>
        <FormActions>
          <Button value='Uložit změny' type='submit' width='full' />
        </FormActions>
      </Form>
    </form>
  );
};

export default ProjectTimeUpdateForm;
