import React, {useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {projectStates} from '../../consts/consts';
import ProjectUpdateForm from '../../forms/ProjectUpdateForm/ProjectUpdateForm';
import {formatProjectNumber} from '../../helpers/utils';
import {formInit} from '../../store/actions';
import Part from '../Part/Part';
import Parts from '../Parts/Parts';
import ButtonEdit from '../UI/ButtonEdit/ButtonEdit';
import Modal from '../UI/Modal/Modal';

const ProjectHeader = () => {
  // eslint-disable-next-line camelcase
  const {number, created_at, members, customers, project} = useSelector(
    state => ({
      project: state.project.project,
      projectId: state.project.project.id,
      number: state.project.project.project_number,
      created_at: state.project.project.created_at,
      state: state.project.project.state,
      name: state.project.project.name,
      member: state.project.project.member,
      customer_specification: state.project.project.customer_specification,
      customer: state.project.project.customer,
      members: state.team.members,
      customers: state.customers.items,
      updating: state.project.updating,
    }),
    shallowEqual
  );

  const [isShowingUpdateModal, toggleUpdateModal] = useState(false);
  const dispatch = useDispatch();

  const openUpdateForm = () => {
    dispatch(formInit());
    toggleUpdateModal(true);
  };

  const handleUpdate = () => {
    toggleUpdateModal(false);
  };

  const stateLabel = projectStates.find(projectState => {
    return projectState.id === project.state;
  });

  const memberLabel = members.find(member => {
    if (project.member) {
      return member.id === project.member.id;
    }

    return false;
  });

  const customerLabel = customers.find(customer => {
    if (project.customer) {
      return customer.id === project.customer.id;
    }

    return false;
  });

  return (
    <>
      <ButtonEdit callback={openUpdateForm} />
      <Parts>
        <Part
          title='Číslo'
          content={<>{formatProjectNumber(number, created_at)}</>}
          className='project__number'
        />

        <Part title='Stav' content={<>{stateLabel.title}</>} />

        <Part
          title='Zodpovědná osoba'
          content={<>{memberLabel ? memberLabel.user.name : '---'}</>}
        />

        <Part title='Zákazník' content={<>{customerLabel ? customerLabel.name : '---'}</>} />
      </Parts>
      <Modal
        isShowing={isShowingUpdateModal}
        title='Upravit projekt'
        hide={() => toggleUpdateModal(false)}
        type='large'
      >
        <ProjectUpdateForm onSubmit={handleUpdate} project={project} />
      </Modal>
    </>
  );
};

export default ProjectHeader;
