/* eslint-disable react/jsx-props-no-spreading, no-undef */
import {yupResolver} from '@hookform/resolvers/yup';
import add from 'date-fns/add';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import * as yup from 'yup';
import Form from '../../components/Form/Form';
import FormActions from '../../components/FormActions/FormActions';
import FormField from '../../components/FormField/FormField';
import Button from '../../components/UI/Button/Button';
import InputDate from '../../components/UI/InputDate/InputDate';
import InputTypePrice from '../../components/UI/InputTypeText/InputTypePrice';
import InputTypeText from '../../components/UI/InputTypeText/InputTypeText';
import SelectInvoiceState from '../../components/UI/SelectInvoiceState/SelectInvoiceState';
import {dateToTimestamp} from '../../helpers/utils';
import useReduxForm from '../../hooks/useReduxForm';
import {invoiceCreate} from '../../store/actions';

const date = new Date();

// Constraints.
const constraints = yup.object().shape({});

const ProjectInvoiceCreateForm = ({projectId, onSubmit}) => {
  const dispatch = useDispatch();
  const {loading, submitErrors} = useReduxForm(onSubmit);

  // Default values.
  const defaultValues = {
    state: 0,
    number: '',
    amount: 0,
    dateInvoiced: date,
    dateValidity: add(date, {days: 14}),
    datePayment: null,
  };

  const {
    control,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm({
    defaultValues,
    resolver: yupResolver(constraints),
  });

  // Handle submit.
  const handleFormSubmit = data => {
    const payload = {};
    payload.project = projectId;
    payload.state = data.state;
    payload.number = data.number || '';
    payload.amount = data.amount ? parseFloat(data.amount, 10) : 0;
    payload.dateInvoiced = dateToTimestamp(data.dateInvoiced);
    payload.dateValidity = dateToTimestamp(data.dateValidity);
    payload.datePayment = dateToTimestamp(data.datePayment);

    dispatch(
      invoiceCreate(
        payload.project,
        payload.state,
        payload.number,
        payload.amount,
        payload.dateInvoiced,
        payload.dateValidity,
        payload.datePayment
      )
    );
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Form loading={loading} errors={submitErrors}>
        <FormField label='Stav' errors={errors.state?.message}>
          <Controller
            name='state'
            control={control}
            value={getValues('state')}
            render={({field}) => <SelectInvoiceState {...field} />}
          />
        </FormField>
        <FormField label='Číslo fakury' errors={errors.name?.message}>
          <Controller
            name='number'
            control={control}
            value={getValues('number')}
            render={({field}) => <InputTypeText {...field} />}
          />
        </FormField>
        <FormField label='Cena' errors={errors.amount?.message}>
          <Controller
            name='amount'
            control={control}
            value={getValues('amount')}
            render={({field}) => <InputTypePrice {...field} />}
          />
        </FormField>
        <FormField label='Datum vytvoření' errors={errors.dateInvoiced?.message}>
          <Controller
            name='dateInvoiced'
            control={control}
            value={getValues('dateInvoiced')}
            render={({field}) => <InputDate {...field} />}
          />
        </FormField>
        <FormField label='Datum splatnosti' errors={errors.dateValidity?.message}>
          <Controller
            name='dateValidity'
            control={control}
            value={getValues('dateValidity')}
            render={({field}) => <InputDate {...field} />}
          />
        </FormField>
        <FormField label='Datum zaplacení' errors={errors.datePayment?.message}>
          <Controller
            name='datePayment'
            control={control}
            value={getValues('datePayment')}
            render={({field}) => <InputDate {...field} />}
          />
        </FormField>
        <FormActions>
          <Button value='Vytvořit' type='submit' width='full' />
        </FormActions>
      </Form>
    </form>
  );
};

export default ProjectInvoiceCreateForm;
