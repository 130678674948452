import {format} from 'date-fns';
import parseISO from 'date-fns/parseISO';
import React, {useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import ProjectsOfferUpdateForm from '../../forms/ProjectOfferUpdateForm/ProjectOfferUpdateForm';
import {formatRoundNumber} from '../../helpers/helpers';
import {IconOffer} from '../../helpers/icons';
import {formInit} from '../../store/actions';
import Empty from '../Empty/Empty';
import Part from '../Part/Part';
import Parts from '../Parts/Parts';
import SectionActions from '../SectionActions/SectionActions';
import Button from '../UI/Button/Button';
import ButtonEdit from '../UI/ButtonEdit/ButtonEdit';
import Modal from '../UI/Modal/Modal';

const ProjectOffer = () => {
  const {
    project,
    economyPrice,
    economyNumber,
    economyState,
    economyStateDate,
    economyNotes,
  } = useSelector(
    state => ({
      project: state.project.project,
      economyPrice: state.project.project.economy_price,
      economyNumber: state.project.project.economy_number,
      economyState: state.project.project.economy_state,
      economyStateDate: state.project.project.economy_state_date,
      economyNotes: state.project.project.economy_notes,
      plans: state.project.project.economies,
    }),
    shallowEqual
  );
  const [isShowingUpdateModal, toggleUpdateModal] = useState(false);
  const dispatch = useDispatch();

  const openUpdateForm = () => {
    dispatch(formInit());
    toggleUpdateModal(true);
  };

  const handleUpdate = () => {
    toggleUpdateModal(false);
  };

  return (
    <>
      {economyPrice !== 0 ? <ButtonEdit callback={openUpdateForm} /> : null}
      {economyPrice === 0 ? (
        <Empty title='Pokračujte vytvořením nabídky'>
          Vytvořte nabídku pro klienta.
          <br />
          Můžete se rozhodnout jakou cenu nabídnete. Můžete vycházet z ceny rozpočtu nebo si sami
          zvolte kolik za projekt chcete dostat.
          <br />
          Nabídku vytvoříte přes ozubené kolečko.
          <SectionActions>
            <Button
              onClick={openUpdateForm}
              value='Vytvořit nabídku'
              icon={<IconOffer />}
              size='bigger'
            ></Button>
          </SectionActions>
        </Empty>
      ) : (
        <Parts>
          <Part title='Dohodnutá cena' content={<>{formatRoundNumber(economyPrice || 0)}</>} />
          <Part
            title='Stav'
            content={<>{economyState === 0 ? 'Nepotvrzená' : 'Potvrzená klientem'}</>}
          />
          <Part
            title='Datum potvrzení klientem'
            content={economyStateDate ? format(parseISO(economyStateDate), 'dd. MM. yyyy') : '---'}
          />
          <Part title='Číslo nabídky' content={economyNumber || '---'} />
          <Part title='Způsob objednání' content={economyNotes || '---'} />
        </Parts>
      )}
      <Modal
        isShowing={isShowingUpdateModal}
        title='Upravit nabídku'
        hide={() => toggleUpdateModal(false)}
        type='large'
      >
        <ProjectsOfferUpdateForm onSubmit={handleUpdate} project={project} />
      </Modal>
    </>
  );
};

export default ProjectOffer;
