/* CREATE TEAM */
import axios from 'axios';
import {push} from 'connected-react-router';
import {NotificationManager} from 'react-notifications';
import {displayErrors} from '../../api';
import {userSwitchTeamMutation, userTeamsFetchQuery} from '../../api/queries';
import {setCookie} from '../../helpers/utils';
import * as actionTypes from './actionTypes';
import {customersFetch} from './customers';
import {projectsFetch} from './projects';
// eslint-disable-next-line import/no-cycle
import {teamFetch} from './team';

export const userTeamsFetchBegin = () => {
  return {
    type: actionTypes.FETCH_USER_TEAMS_BEGIN,
  };
};

export const userTeamsFetchSuccess = teams => {
  return {
    type: actionTypes.FETCH_USER_TEAMS_SUCCESS,
    teams,
  };
};

export const userTeamsFetchFailure = error => {
  return {
    type: actionTypes.FETCH_USER_TEAMS_FAILURE,
    error,
  };
};

export const userTeamsFetch = () => {
  return dispatch => {
    dispatch(userTeamsFetchBegin());

    axios
      .post('/graphql', userTeamsFetchQuery())
      .then(res => {
        displayErrors(res);
        const {teams} = res.data.data;
        dispatch(userTeamsFetchSuccess(teams));
      })
      .catch(error => {
        dispatch(userTeamsFetchFailure(error));
        NotificationManager.error('Internal error');
      });
  };
};

export const userSwitchTeam = id => {
  return dispatch => {
    axios
      .post('/graphql', userSwitchTeamMutation(id))
      .then(res => {
        displayErrors(res);
        const {switchTeam} = res.data.data;
        setCookie('selected_team', switchTeam.id);
        dispatch(teamFetch(switchTeam.id));
        dispatch(projectsFetch());
        dispatch(customersFetch());
        // dispatch(timeFetch());
        dispatch(userTeamsFetch());
        dispatch(push('/projects'));
        NotificationManager.success('Team přepnut');
      })
      .catch(() => {
        NotificationManager.error('Internal error');
      });
  };
};
