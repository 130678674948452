import * as actionTypes from '../actions/actionTypes';

const initialState = {
  customer: null,
  errors: [],
  loading: false,
};

const fetchCustomerBegin = state => {
  return {
    ...state,
    errors: [],
    loading: true,
  };
};

const fetchCustomerSuccess = (state, action) => {
  return {
    ...state,
    errors: [],
    loading: false,
    customer: action.customer,
  };
};

const customerCleanup = state => {
  return {
    ...state,
    customer: null,
  };
};

const fetchCustomerFailure = state => {
  return {
    ...state,
    errors: [],
    loading: false,
  };
};

const customerUpdateBegin = state => {
  return {
    ...state,
    errors: [],
    loading: true,
  };
};

const customerUpdateSuccess = (state, action) => {
  return {
    ...state,
    errors: [],
    loading: false,
    customer: action.customer,
  };
};

const customerUpdateFailure = (state, action) => {
  return {
    ...state,
    errors: action.errors,
    loading: false,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CUSTOMER_BEGIN:
      return fetchCustomerBegin(state);
    case actionTypes.FETCH_CUSTOMER_SUCCESS:
      return fetchCustomerSuccess(state, action);
    case actionTypes.FETCH_CUSTOMER_FAILURE:
      return fetchCustomerFailure(state);
    case actionTypes.CUSTOMER_UPDATE_BEGIN:
      return customerUpdateBegin(state);
    case actionTypes.CUSTOMER_UPDATE_SUCCESS:
      return customerUpdateSuccess(state, action);
    case actionTypes.CUSTOMER_UPDATE_FAILURE:
      return customerUpdateFailure(state, action);
    case actionTypes.CUSTOMER_CLEANUP:
      return customerCleanup(state);
    default:
      return state;
  }
};

export default reducer;
