export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const REGISTER_BEGIN = 'REGISTER_BEGIN';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const INVITE_ME_BEGIN = 'INVITE_ME_BEGIN';
export const INVITE_ME_SUCCESS = 'INVITE_ME_SUCCESS';
export const INVITE_ME_FAILURE = 'INVITE_ME_FAILURE';

export const ACCOUNT_VERIFY_BEGIN = 'ACCOUNT_VERIFY_BEGIN';
export const ACCOUNT_VERIFY_SUCCESS = 'ACCOUNT_VERIFY_SUCCESS';
export const ACCOUNT_VERIFY_FAILURE = 'ACCOUNT_VERIFY_FAILURE';

export const CUSTOMERS_FETCH_BEGIN = 'CUSTOMERS_FETCH_BEGIN';
export const CUSTOMERS_FETCH_SUCCESS = 'CUSTOMERS_FETCH_SUCCESS';
export const CUSTOMERS_FETCH_FAILURE = 'CUSTOMERS_FETCH_FAILURE';

export const UPDATE_CUSTOMERS_FILTERS = 'UPDATE_CUSTOMERS_FILTERS';
export const UPDATE_CUSTOMERS_SEARCH_FILTERS = 'UPDATE_CUSTOMERS_SEARCH_FILTERS';
export const UPDATE_CUSTOMERS_LIST = 'UPDATE_CUSTOMERS_LIST';

export const FETCH_CUSTOMER_BEGIN = 'FETCH_CUSTOMER_BEGIN';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_FAILURE = 'FETCH_CUSTOMER_FAILURE';

export const CUSTOMER_CLEANUP = 'CUSTOMER_CLEANUP';

export const CUSTOMER_ADD_BEGIN = 'CUSTOMER_ADD_BEGIN';
export const CUSTOMER_ADD_SUCCESS = 'CUSTOMER_ADD_SUCCESS';
export const CUSTOMER_ADD_FAILURE = 'CUSTOMER_ADD_FAILURE';

export const CUSTOMER_UPDATE_BEGIN = 'CUSTOMER_UPDATE_BEGIN';
export const CUSTOMER_UPDATE_SUCCESS = 'CUSTOMER_UPDATE_SUCCESS';
export const CUSTOMER_UPDATE_FAILURE = 'CUSTOMER_UPDATE_FAILURE';

export const FETCH_PROJECTS_BEGIN = 'FETCH_PROJECTS_BEGIN';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE';

export const UPDATE_PROJECTS_FILTERS = 'UPDATE_PROJECTS_FILTERS';
export const UPDATE_PROJECTS_SEARCH_FILTERS = 'UPDATE_PROJECTS_SEARCH_FILTERS';
export const UPDATE_PROJECTS_DATE_FROM_FILTERS = 'UPDATE_PROJECTS_DATE_FROM_FILTERS';
export const UPDATE_PROJECTS_DATE_TO_FILTERS = 'UPDATE_PROJECTS_DATE_TO_FILTERS';
export const UPDATE_PROJECTS_DATE_TYPE_FILTERS = 'UPDATE_PROJECTS_DATE_TYPE_FILTERS';
export const UPDATE_PROJECTS_LIST = 'UPDATE_PROJECTS_LIST';
export const PROJECT_UPDATE_VALUES = 'PROJECT_UPDATE_VALUES';

export const FETCH_PROJECT_BEGIN = 'FETCH_PROJECT_BEGIN';
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS';
export const FETCH_PROJECT_FAILURE = 'FETCH_PROJECT_FAILURE';

export const PROJECT_CLEANUP = 'PROJECT_CLEANUP';

export const PROJECT_ADD_BEGIN = 'PROJECT_ADD_BEGIN';
export const PROJECT_ADD_SUCCESS = 'PROJECT_ADD_SUCCESS';
export const PROJECT_ADD_FAILURE = 'PROJECT_ADD_FAILURE';

export const FETCH_TEAM_BEGIN = 'FETCH_TEAM_BEGIN';
export const FETCH_TEAM_SUCCESS = 'FETCH_TEAM_SUCCESS';
export const FETCH_TEAM_FAILURE = 'FETCH_TEAM_FAILURE';

export const FETCH_USER_TEAMS_BEGIN = 'FETCH_USER_TEAMS_BEGIN';
export const FETCH_USER_TEAMS_SUCCESS = 'FETCH_USER_TEAMS_SUCCESS';
export const FETCH_USER_TEAMS_FAILURE = 'FETCH_USER_TEAMS_FAILURE';

export const TEAM_CREATE_BEGIN = 'TEAM_CREATE_BEGIN';
export const TEAM_CREATE_SUCCESS = 'TEAM_CREATE_SUCCESS';
export const TEAM_CREATE_FAILURE = 'TEAM_CREATE_FAILURE';

export const TEAM_UPDATE_BEGIN = 'TEAM_UPDATE_BEGIN';
export const TEAM_UPDATE_SUCCESS = 'TEAM_UPDATE_SUCCESS';
export const TEAM_UPDATE_FAILURE = 'TEAM_UPDATE_FAILURE';

export const PROJECT_UPDATE_BEGIN = 'PROJECT_UPDATE_BEGIN';
export const PROJECT_UPDATE_SUCCESS = 'PROJECT_UPDATE_SUCCESS';
export const PROJECT_UPDATE_FAILURE = 'PROJECT_UPDATE_FAILURE';

export const INVOICE_ADD_BEGIN = 'INVOICE_ADD_BEGIN';
export const INVOICE_ADD_SUCCESS = 'INVOICE_ADD_SUCCESS';
export const INVOICE_ADD_FAILURE = 'INVOICE_ADD_FAILURE';

export const INVOICE_UPDATE_BEGIN = 'INVOICE_UPDATE_BEGIN';
export const INVOICE_UPDATE_SUCCESS = 'INVOICE_UPDATE_SUCCESS';
export const INVOICE_UPDATE_FAILURE = 'INVOICE_UPDATE_FAILURE';

export const INVOICE_DELETE_BEGIN = 'INVOICE_DELETE_BEGIN';
export const INVOICE_DELETE_SUCCESS = 'INVOICE_DELETE_SUCCESS';
export const INVOICE_DELETE_FAILURE = 'INVOICE_DELETE_FAILURE';

export const INVOICES_FETCH_BEGIN = 'INVOICES_FETCH_BEGIN';
export const INVOICES_FETCH_SUCCESS = 'INVOICES_FETCH_SUCCESS';
export const INVOICES_FETCH_FAILURE = 'INVOICES_FETCH_FAILURE';

export const MEMBER_UPDATE_BEGIN = 'MEMBER_UPDATE_BEGIN';
export const MEMBER_UPDATE_SUCCESS = 'MEMBER_UPDATE_SUCCESS';
export const MEMBER_UPDATE_FAILURE = 'MEMBER_UPDATE_FAILURE';

export const MEMBERS_INVITE_BEGIN = 'MEMBERS_INVITE_BEGIN';
export const MEMBERS_INVITE_SUCCESS = 'MEMBERS_INVITE_SUCCESS';
export const MEMBERS_INVITE_FAILURE = 'MEMBERS_INVITE_FAILURE';

export const ACCEPT_AUTH_INVITATION_BEGIN = 'ACCEPT_AUTH_INVITATION_BEGIN';
export const ACCEPT_AUTH_INVITATION_SUCCESS = 'ACCEPT_AUTH_INVITATION_SUCCESS';
export const ACCEPT_AUTH_INVITATION_FAILURE = 'ACCEPT_AUTH_INVITATION_FAILURE';

export const CLEAR_SELECTED_PROJECT = 'CLEAR_SELECTED_PROJECT';

export const PROJECT_TIME_UPDATE_SUCCESS = 'PROJECT_TIME_UPDATE_SUCCESS';

export const TIME_FETCH_BEGIN = 'TIME_FETCH_BEGIN';
export const TIME_FETCH_SUCCESS = 'TIME_FETCH_SUCCESS';
export const TIME_FETCH_FAILURE = 'TIME_FETCH_FAILURE';

export const TIME_CREATE_BEGIN = 'TIME_CREATE_BEGIN';
export const TIME_CREATE_SUCCESS = 'TIME_CREATE_SUCCESS';
export const TIME_CREATE_FAILURE = 'TIME_CREATE_FAILURE';

export const TIME_UPDATE_BEGIN = 'TIME_UPDATE_BEGIN';
export const TIME_UPDATE_SUCCESS = 'TIME_UPDATE_SUCCESS';
export const TIME_UPDATE_FAILURE = 'TIME_UPDATE_FAILURE';

export const TIME_DELETE_BEGIN = 'TIME_DELETE_BEGIN';
export const TIME_DELETE_SUCCESS = 'TIME_DELETE_SUCCESS';
export const TIME_DELETE_FAILURE = 'TIME_DELETE_FAILURE';

export const UPDATE_TIMES_DATE_FROM_FILTERS = 'UPDATE_TIMES_DATE_FROM_FILTERS';
export const UPDATE_TIMES_DATE_TO_FILTERS = 'UPDATE_TIMES_DATE_TO_FILTERS';

export const ECONOMY_CREATE_BEGIN = 'ECONOMY_CREATE_BEGIN';
export const ECONOMY_CREATE_SUCCESS = 'ECONOMY_CREATE_SUCCESS';
export const ECONOMY_CREATE_FAILURE = 'ECONOMY_CREATE_FAILURE';

export const ECONOMY_UPDATE_BEGIN = 'ECONOMY_UPDATE_BEGIN';
export const ECONOMY_UPDATE_SUCCESS = 'ECONOMY_UPDATE_SUCCESS';
export const ECONOMY_UPDATE_FAILURE = 'ECONOMY_UPDATE_FAILURE';

export const ECONOMY_DELETE_BEGIN = 'ECONOMY_DELETE_BEGIN';
export const ECONOMY_DELETE_SUCCESS = 'ECONOMY_DELETE_SUCCESS';
export const ECONOMY_DELETE_FAILURE = 'ECONOMY_DELETE_FAILURE';

export const BALANCE_FETCH_BEGIN = 'BALANCE_FETCH_BEGIN';
export const BALANCE_FETCH_SUCCESS = 'BALANCE_FETCH_SUCCESS';
export const BALANCE_FETCH_FAILURE = 'BALANCE_FETCH_FAILURE';

export const REALITY_CREATE_BEGIN = 'REALITY_CREATE_BEGIN';
export const REALITY_CREATE_SUCCESS = 'REALITY_CREATE_SUCCESS';
export const REALITY_CREATE_FAILURE = 'REALITY_CREATE_FAILURE';

export const REALITY_UPDATE_BEGIN = 'REALITY_UPDATE_BEGIN';
export const REALITY_UPDATE_SUCCESS = 'REALITY_UPDATE_SUCCESS';
export const REALITY_UPDATE_FAILURE = 'REALITY_UPDATE_FAILURE';

export const REALITY_DELETE_BEGIN = 'REALITY_DELETE_BEGIN';
export const REALITY_DELETE_SUCCESS = 'REALITY_DELETE_SUCCESS';
export const REALITY_DELETE_FAILURE = 'REALITY_DELETE_FAILURE';

export const REALITIES_FETCH_BEGIN = 'REALITIES_FETCH_BEGIN';
export const REALITIES_FETCH_SUCCESS = 'REALITIES_FETCH_SUCCESS';
export const REALITIES_FETCH_FAILURE = 'REALITIES_FETCH_FAILURE';

export const FETCH_BALANCE_BEGIN = 'FETCH_BALANCE_BEGIN';
export const FETCH_BALANCE_SUCCESS = 'FETCH_BALANCE_SUCCESS';
export const FETCH_BALANCE_FAILURE = 'FETCH_BALANCE_FAILURE';
export const UPDATE_BALANCE_DATE_FROM_FILTERS = 'UPDATE_BALANCE_DATE_FROM_FILTERS';
export const UPDATE_BALANCE_DATE_TO_FILTERS = 'UPDATE_BALANCE_DATE_TO_FILTERS';
export const BALANCE_CLEAR = 'BALANCE_CLEAR';

// Forms.
export const FORM_INIT = 'FORM_INIT';
export const FORM_BEGIN = 'FORM_BEGIN';
export const FORM_SUCCESS = 'FORM_SUCCESS';
export const FORM_ERROR = 'FORM_ERROR';

export const UPDATE_REPORTS_DATE_FROM_FILTER = 'UPDATE_REPORTS_DATE_FROM_FILTER';
export const UPDATE_REPORTS_DATE_TO_FILTER = 'UPDATE_REPORTS_DATE_TO_FILTER';
export const UPDATE_REPORTS_CUSTOMER_FILTER = 'UPDATE_REPORTS_CUSTOMER_FILTER';
export const UPDATE_REPORTS_MEMBER_FILTER = 'UPDATE_REPORTS_MEMBER_FILTER';
export const RESET_REPORTS_FILTERS = 'RESET_REPORTS_FILTERS';
