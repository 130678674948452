/* eslint-disable react/jsx-props-no-spreading, no-undef */
import {yupResolver} from '@hookform/resolvers/yup';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import * as yup from 'yup';
import Form from '../../components/Form/Form';
import FormActions from '../../components/FormActions/FormActions';
import FormField from '../../components/FormField/FormField';
import Button from '../../components/UI/Button/Button';
import InputTextEditor from '../../components/UI/InputTexteditor/InputTextEditor';
import useReduxForm from '../../hooks/useReduxForm';
import {projectUpdate} from '../../store/actions';

// Constraints.
const constraints = yup.object().shape({});

const ProjectSpecificationUpdateForm = ({onSubmit, project}) => {
  const dispatch = useDispatch();
  const {loading, submitErrors} = useReduxForm(onSubmit);

  const {customer_specification} = project;

  // Default values.
  const defaultValues = {
    customer_specification,
  };

  const {
    control,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm({
    defaultValues,
    resolver: yupResolver(constraints),
  });

  // Handle submit.
  const handleFormSubmit = data => {
    const {id} = project;

    const payload = {};
    payload.specification = data.customer_specification || '';

    dispatch(projectUpdate(id, payload));
  };

  // Render component.
  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Form loading={loading} errors={submitErrors}>
        <FormField label='Specifikace klienta' errors={errors.customer_specification?.message}>
          <Controller
            name='customer_specification'
            control={control}
            value={getValues('customer_specification')}
            render={({field}) => <InputTextEditor {...field} />}
          />
        </FormField>
        <FormActions>
          <Button value='Uložit změny' type='submit' width='full' />
        </FormActions>
      </Form>
    </form>
  );
};

export default ProjectSpecificationUpdateForm;
