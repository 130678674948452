import React from 'react';
import {Redirect, Route} from 'react-router';
import {isAuthenticated} from '../api/index';
/* eslint-disable react/jsx-props-no-spreading */
const ProtectedRoute = ({children, ...rest}) => {
  return (
    <Route
      {...rest}
      render={({location}) => {
        return isAuthenticated() ? (
          children
        ) : (
          <Redirect to={{pathname: '/forbidden', state: {from: location}}} />
        );
      }}
    />
  );
};

export default ProtectedRoute;
