import React from 'react';
import Page from '../../components/Page/Page';

const VerificationEmailSent = () => {
  return (
    <Page>
      <h1>Potvrďte prosím Váš e-mail.</h1>
      <h2>
        Na zadanou e-mailovou adresu Vám byl zaslán email s instrukcemi pro dokončení registrace.
      </h2>
    </Page>
  );
};

export default VerificationEmailSent;
