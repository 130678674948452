import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import TeamMemberUpdateForm from '../../forms/TeamMemberUpdateForm/TeamMemberUpdateForm';
import {formatDecimalNumber} from '../../helpers/helpers';
import {IconEdit} from '../../helpers/icons';
import useMemberRole from '../../hooks/useMemberRole';
import useMemberState from '../../hooks/useMemberState';
import {formInit} from '../../store/actions';
import Cell from '../Cell/Cell';
import CellGroup from '../CellGroup/CellGroup';
import Row from '../Row/Row';
import './TeamMember.scss';
import State from '../State/State';
import Modal from '../UI/Modal/Modal';
import UserBadge from '../UserBadge/UserBadge';

const TeamMember = ({member}) => {
  const {user, price, sell_price, roles, state} = member;
  const {id: stateId, value: stateValue} = useMemberState(state);
  const {id: roleId, value: roleValue} = useMemberRole(roles);
  const [isShowingUpdateModal, toggleUpdateModal] = useState(false);

  const dispatch = useDispatch();

  const openUpdateForm = () => {
    dispatch(formInit());
    toggleUpdateModal(true);
  };

  const handleUpdate = () => {
    toggleUpdateModal(false);
  };

  const actions = (
    <>
      <button type='button' onClick={openUpdateForm}>
        <IconEdit />
      </button>
    </>
  );

  return (
    <Row actions={actions}>
      <CellGroup>
        <Cell type='role'>
          <State value={stateValue} type={stateId} />
        </Cell>
        <Cell type='member' size='auto'>
          <UserBadge name={user.name || 'Unknown user'} />
        </Cell>
        <Cell type='state'>
          <State value={roleValue} type={roleId} />
        </Cell>
      </CellGroup>
      <CellGroup type='prices'>
        <Cell type='price' align='right'>
          {formatDecimalNumber(price)}
        </Cell>
        <Cell type='price' align='right'>
          {formatDecimalNumber(sell_price)}
        </Cell>
      </CellGroup>
      <Modal
        isShowing={isShowingUpdateModal}
        title='Upravit člena'
        hide={() => toggleUpdateModal(false)}
        type='large'
      >
        <TeamMemberUpdateForm onSubmit={handleUpdate} member={member} />
      </Modal>
    </Row>
  );
};

export default TeamMember;
