import React from 'react';
import FormErrors from '../FormErrors/FormErrors';
import FormLoadings from '../FormLoading/FormLoading';
import './Form.scss';

const Form = ({children, loading = false, errors = null, className = null}) => {
  const classes = ['form'];
  if (className) {
    classes.push(className);
  }

  return (
    <div className={classes.join(' ')}>
      <FormLoadings loading={loading} />
      <FormErrors errors={errors} />
      {children}
    </div>
  );
};

export default Form;
