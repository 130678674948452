import React from 'react';
import Spinner from '../Spinner/Spinner';
import './PagePreloader.scss';

const PagePreloader = () => {
  return (
    <div className='page_preloader'>
      <Spinner type='spinner-padding' />
    </div>
  );
};

export default PagePreloader;
