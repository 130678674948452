import {projectStateFilters} from '../../consts/consts';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  items: [],
  error: null,
  errorBalance: null,
  loading: false,
  balanceLoading: false,
  filters: {
    status: {
      ...projectStateFilters,
    },
    text: '',
    dateFrom: null,
    dateTo: null,
    dateType: 'deadline',
  },
  balance: null,
};

const fetchProjectsBegin = state => {
  return {
    ...state,
    error: null,
    loading: true,
  };
};

const fetchProjectsSuccess = (state, action) => {
  return {
    ...state,
    items: action.projects,
    error: null,
    loading: false,
  };
};

const fetchProjectsFailure = (state, action) => {
  return {
    ...state,
    error: action.error.message,
    loading: false,
  };
};

const fetchProjectBegin = state => {
  return {
    ...state,
  };
};

const fetchProjectSuccess = (state, action) => {
  return {
    ...state,
    project: action.project,
  };
};

const fetchProjectFailure = state => {
  return {
    ...state,
  };
};

const projectAddBegin = state => {
  return {
    ...state,
    error: null,
    loading: false,
  };
};

const projectAddSuccess = (state, action) => {
  const {project} = action;
  project.created = true;
  return {
    ...state,
    items: [project, ...state.items],
    error: null,
    loading: false,
  };
};

const projectAddFailure = (state, action) => {
  return {
    ...state,
    error: action.error.message,
    loading: false,
  };
};

const updateProjectsFilters = (state, action) => {
  const {filter} = action;
  const {key} = action;

  return {
    ...state,
    filters: {
      ...state.filters,
      [filter]: {
        ...state.filters[filter],
        [key]: !state.filters[filter][key],
      },
    },
  };
};

const updateProjectsSearchFilters = (state, action) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      text: action.search,
    },
  };
};

const updateProjectsDateFromFilters = (state, action) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      dateFrom: action.dateFrom,
    },
  };
};

const updateProjectsDateToFilters = (state, action) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      dateTo: action.dateTo,
    },
  };
};

const updateProjectsDateTypeFilters = (state, action) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      dateType: action.dateType,
    },
  };
};

const updateProjectsList = (state, action) => {
  const items = [...state.items];

  const updatedItems = items.map(item => {
    if (Number(item.id) !== Number(action.id)) {
      return item;
    }
    return {
      ...item,
      [action.property]: action.value,
    };
  });

  return {
    ...state,
    items: updatedItems,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROJECTS_BEGIN:
      return fetchProjectsBegin(state);
    case actionTypes.FETCH_PROJECTS_SUCCESS:
      return fetchProjectsSuccess(state, action);
    case actionTypes.FETCH_PROJECTS_FAILURE:
      return fetchProjectsFailure(state, action);
    case actionTypes.FETCH_PROJECT_BEGIN:
      return fetchProjectBegin(state);
    case actionTypes.FETCH_PROJECT_SUCCESS:
      return fetchProjectSuccess(state, action);
    case actionTypes.FETCH_PROJECT_FAILURE:
      return fetchProjectFailure(state);
    case actionTypes.PROJECT_ADD_BEGIN:
      return projectAddBegin(state);
    case actionTypes.PROJECT_ADD_SUCCESS:
      return projectAddSuccess(state, action);
    case actionTypes.PROJECT_ADD_FAILURE:
      return projectAddFailure(state, action);
    case actionTypes.UPDATE_PROJECTS_FILTERS:
      return updateProjectsFilters(state, action);
    case actionTypes.UPDATE_PROJECTS_SEARCH_FILTERS:
      return updateProjectsSearchFilters(state, action);
    case actionTypes.UPDATE_PROJECTS_DATE_FROM_FILTERS:
      return updateProjectsDateFromFilters(state, action);
    case actionTypes.UPDATE_PROJECTS_DATE_TO_FILTERS:
      return updateProjectsDateToFilters(state, action);
    case actionTypes.UPDATE_PROJECTS_DATE_TYPE_FILTERS:
      return updateProjectsDateTypeFilters(state, action);
    case actionTypes.UPDATE_PROJECTS_LIST:
      return updateProjectsList(state, action);
    default:
      return state;
  }
};

export default reducer;
