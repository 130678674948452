import React, {useEffect, useRef, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {useLocation, useRouteMatch} from 'react-router';
import ProjectCreateForm from '../../forms/ProjectCreateForm/ProjectCreateForm';
import ProjectTimeCreateForm from '../../forms/ProjectTimeCreateForm/ProjectTimeCreateForm';
import {IconAddresses, IconPlus, IconProjects, IconTimer} from '../../helpers/icons';
import {formInit} from '../../store/actions';
import * as actions from '../../store/actions';
import CustomerCreateForm from '../../forms/CustomerCreateForm/CustomerCreateForm';
import Modal from '../UI/Modal/Modal';
import './ToolbarPlus.scss';

const ToolbarPlus = ({onCustomerAdd, customers}) => {
  const [plusOpen, setPlusOpen] = useState(false);
  const [isShowingProjectModal, toggleProjectModal] = useState(false);
  const [isShowingAddressModal, toggleAddressModal] = useState(false);
  const [isShowingTimerModal, toggleTimerModal] = useState(false);

  const match = useRouteMatch();
  const location = useLocation();
  const plusRef = useRef(null);
  const dispatch = useDispatch();

  const handleClickOutside = event => {
    if (plusRef && !plusRef.current.contains(event.target)) {
      setPlusOpen(false);
    }
  };

  useEffect(() => {
    if (plusOpen === true) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [plusOpen]);

  let projectId = null;

  // Toto: Fix to use useParams();
  if (match.path === '/projects' && match.isExact === false) {
    const {pathname} = location;
    const parts = pathname.split('/');
    projectId = parseInt(parts[2], 10);
  }

  const handleProjectClick = () => {
    dispatch(formInit());
    setPlusOpen(false);
    toggleProjectModal(true);
  };

  const handleProjectCreate = () => {
    toggleProjectModal(false);
  };

  const handleAddressClick = () => {
    dispatch(formInit());
    setPlusOpen(false);
    toggleAddressModal(true);
  };

  const handleAddressCreate = name => {
    onCustomerAdd(name);
    toggleAddressModal(false);
  };

  const handleTimerClick = () => {
    dispatch(formInit());
    setPlusOpen(false);
    toggleTimerModal(true);
  };

  const handleTimerCreate = () => {
    toggleTimerModal(false);
  };

  const plusClasses = ['plus'];
  if (plusOpen) {
    plusClasses.push('active');
  }

  const plusContainerContent = [];

  plusContainerContent.push(
    <button type='button' onClick={handleProjectClick} key='toolbar-create-project'>
      <IconProjects />
      Projekt
    </button>
  );

  plusContainerContent.push(
    <button type='button' onClick={handleAddressClick} key='toolbar-create-client'>
      <IconAddresses />
      Adresu
    </button>
  );

  plusContainerContent.push(
    <button type='button' onClick={handleTimerClick} key='toolbar-create-time'>
      <IconTimer />
      Práci
    </button>
  );

  const plusContainer = (
    <div className='plus__container' ref={plusRef}>
      {plusContainerContent.map(content => content)}
    </div>
  );

  return (
    <div className='plus__wrapper'>
      <button
        type='button'
        className={plusClasses.join(' ')}
        onClick={() => setPlusOpen(!plusOpen)}
      >
        <IconPlus size={16} />
      </button>
      {plusOpen ? plusContainer : null}
      <Modal
        isShowing={isShowingProjectModal}
        title='Vytvořit projekt'
        hide={() => toggleProjectModal(false)}
      >
        <ProjectCreateForm onSubmit={name => handleProjectCreate(name)} customers={customers} />
      </Modal>
      <Modal
        isShowing={isShowingAddressModal}
        title='Vytvořit adresu'
        hide={() => toggleAddressModal(false)}
      >
        <CustomerCreateForm onSubmit={name => handleAddressCreate(name)} />
      </Modal>
      <Modal
        isShowing={isShowingTimerModal}
        title='Vytvořit časový záznam'
        hide={() => toggleTimerModal(false)}
        type='large'
      >
        <ProjectTimeCreateForm
          onSubmit={values => handleTimerCreate(values)}
          projectId={projectId}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    projects: state.projects,
    customers: state.customers,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onProjectAdd: (name, customerId, redirect, isNew) =>
      dispatch(actions.projectCreate(name, customerId, redirect, isNew)),
    onCustomerAdd: name => dispatch(actions.customerCreate(name)),
    onTimeCreate: (member, description, project, time) =>
      dispatch(actions.timeCreate(member, description, project, time)),
    me: () => dispatch(actions.me()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ToolbarPlus);
