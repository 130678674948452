import {format} from 'date-fns';
import isDate from 'date-fns/isDate';
import isPast from 'date-fns/isPast';
import parseISO from 'date-fns/parseISO';

export const percentage = (full, part) => {
  if (full === 0) {
    return 0;
  }
  if (part > full) {
    return 100;
  }
  return Math.floor((part / full) * 100);
};

export const memberLoggedTimes = (id, times) => {
  if (times === undefined) {
    return [];
  }

  return times.filter(time => {
    return time.member.id === id;
  });
};

export const memberPlans = (id, plans) => {
  return plans.filter(plan => {
    return plan.plan_unit === 'time' && plan.reference_id === id;
  });
};

export const totalPlansTime = plans => {
  let total = 0;

  // Filter time plans.
  const timePlans = plans.filter(plan => {
    return plan.plan_unit === 'time';
  });

  // Add all plan times.
  Object.values(timePlans).forEach(plan => {
    total += plan.plan_quantity;
  });

  return total;
};

/**
 * Count total times time.
 *
 * @param {array} times - Time entries array.
 */
export const totalTimesTime = times => {
  let total = 0;

  Object.values(times).forEach(time => {
    total += time.time;
  });

  return total;
};

export const totalPlanPrices = plans => {
  let buy = 0;
  let sell = 0;

  plans.forEach(plan => {
    let quantity = plan.plan_quantity;

    if (plan.plan_unit === 'time') {
      const planHours = Math.floor(quantity / 60);
      const planMinutes = (quantity % 60) / 60;
      quantity = planHours + planMinutes;
    }

    buy += quantity * plan.plan_buy_price;
    sell += quantity * plan.plan_sell_price;
  });

  const diff = sell - buy;

  return {
    buy,
    sell,
    diff,
  };
};

export const totalCostsPrices = costs => {
  let totalBuy = 0;
  let totalSell = 0;

  costs.forEach(cost => {
    totalBuy += cost.quantity * cost.buy_price;
    totalSell += cost.quantity * cost.sell_price;
  });

  return {
    totalBuy,
    totalSell,
  };
};

export const totalWorkPrices = times => {
  let totalBuy = 0;
  let totalSell = 0;

  times.forEach(time => {
    const hours = time.time / 60;
    totalBuy += hours * time.buy_price;
    totalSell += hours * time.sell_price;
  });

  return {
    totalBuy,
    totalSell,
  };
};

export const totalInvoicesPrices = invoices => {
  let invoiced = 0;
  let notPaid = 0;
  let paid = 0;

  invoices.forEach(invoice => {
    const deadlineAt = invoice.deadline_at ? parseISO(invoice.deadline_at) : null;
    const paidAt = invoice.paid_at ? parseISO(invoice.paid_at) : null;

    invoiced += invoice.price;

    // Po splatnosti.
    if (deadlineAt && isPast(deadlineAt) && !paidAt) {
      notPaid += invoice.price;
    }

    // Zaplaceno.
    if (paidAt) {
      paid += invoice.price;
    }
  });

  return {
    invoiced,
    notPaid,
    paid,
  };
};

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/;SameSite=Strict`;
};

export const getCookie = cname => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const deleteCookie = (cname, cpath, cdomain) => {
  if (getCookie(cname)) {
    document.cookie = `${cname}=${cpath ? `;path=${cpath}` : ''}${
      cdomain ? `;domain=${cdomain}` : ''
    };expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  }
};
export const zeroPad = (num, places) => String(num).padStart(places, '0');

export const formatProjectNumber = (project_number, created) => {
  if (created !== null && created !== undefined) {
    return format(parseISO(created), `yy${zeroPad(project_number, 4)}`);
  }

  return zeroPad(project_number, 4);
};

export const dateToTimestamp = date => {
  if (isDate(date)) {
    return Number(format(date, 't'));
  }
  return null;
};

export const validators = {
  isRequired(value) {
    if (value !== null && value !== undefined && value !== '') {
      return true;
    }
    return false;
  },

  greaterThan(value, limit) {
    if (value > limit) {
      return true;
    }
    return false;
  },

  greaterOrEqualThan(value, limit) {
    if (value >= limit) {
      return true;
    }
    return false;
  },
};

export const objectToArguments = ({a, b}) => a + b;

export const parseNumber = num => {
  if (num === undefined) return undefined;
  if (num.indexOf(',') < 0) return parseFloat(num);
  const numArr = num.split(',');
  return parseFloat(numArr.reduce((a, v, i) => a + (i === numArr.length - 1 ? `.${v}` : v), ''));
};
