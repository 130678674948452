import {customerStateFilters} from '../../consts/consts';
import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utils';

const initialState = {
  items: [],
  error: null,
  loading: false,
  filters: {
    status: {
      ...customerStateFilters,
    },
    text: '',
  },
};

/**
 * Fetching start.
 * @param state
 * @returns {{}}
 */
const fetchBegin = state => {
  return updateObject(state, {error: null, loading: true});
};

/**
 * Fetch success.
 *
 * @param state
 * @param action
 * @returns {{}}
 */
const fetchSuccess = (state, action) => {
  return {
    ...state,
    items: action.customers,
    error: null,
    loading: false,
  };
};

/**
 * Fetch fail.
 *
 * @param state
 * @param action
 * @returns {{}}
 */
const fetchFailure = (state, action) => {
  return updateObject(state, {
    error: action.error.message,
    loading: false,
  });
};

const customerAddBegin = state => {
  return {
    ...state,
    error: null,
    loading: true,
  };
};

const customerAddSuccess = (state, action) => {
  return {
    ...state,
    items: [action.customer, ...state.items],
    error: null,
    loading: false,
  };
};

const customerAddFailure = (state, action) => {
  return {
    ...state,
    error: action.error.message,
    loading: false,
  };
};

const updateCustomersFilters = (state, action) => {
  const {filter} = action;
  const {key} = action;
  return {
    ...state,
    filters: {
      ...state.filters,
      [filter]: {
        ...state.filters[filter],
        [key]: !state.filters[filter][key],
      },
    },
  };
};

const updateCustomersSearchFilters = (state, action) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      text: action.search,
    },
  };
};

const updateCustomersList = (state, action) => {
  if (action.property !== 'name') {
    return {
      ...state,
    };
  }

  const items = [...state.items];

  const updatedItems = items.map(item => {
    if (item.id !== action.id) {
      return item;
    }
    return {
      ...item,
      [action.property]: action.value,
    };
  });

  return {
    ...state,
    items: updatedItems,
  };
};

/**
 * Reducer.
 *
 * @param state
 * @param action
 * @returns {*}
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CUSTOMERS_FETCH_BEGIN:
      return fetchBegin(state);
    case actionTypes.CUSTOMERS_FETCH_SUCCESS:
      return fetchSuccess(state, action);
    case actionTypes.CUSTOMERS_FETCH_FAILURE:
      return fetchFailure(state, action);
    case actionTypes.CUSTOMER_ADD_BEGIN:
      return customerAddBegin(state);
    case actionTypes.CUSTOMER_ADD_SUCCESS:
      return customerAddSuccess(state, action);
    case actionTypes.CUSTOMER_ADD_FAILURE:
      return customerAddFailure(state, action);
    case actionTypes.UPDATE_CUSTOMERS_FILTERS:
      return updateCustomersFilters(state, action);
    case actionTypes.UPDATE_CUSTOMERS_SEARCH_FILTERS:
      return updateCustomersSearchFilters(state, action);
    case actionTypes.UPDATE_CUSTOMERS_LIST:
      return updateCustomersList(state, action);
    default:
      return state;
  }
};

export default reducer;
