/* eslint-disable react/jsx-props-no-spreading, no-undef */
import {yupResolver} from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
import Form from '../../components/Form/Form';
import FormActions from '../../components/FormActions/FormActions';
import FormField from '../../components/FormField/FormField';
import Button from '../../components/UI/Button/Button';
import InputTypePrice from '../../components/UI/InputTypeText/InputTypePrice';
import useReduxForm from '../../hooks/useReduxForm';
import {projectUpdate} from '../../store/actions';

// Constraints.
const constraints = yup.object().shape({});

const ProjectPlanUpdateForm = ({projectId, onSubmit}) => {
  const dispatch = useDispatch();
  const {loading, submitErrors} = useReduxForm(onSubmit);

  const {project} = useSelector(state => ({
    project: state.project.project,
  }));

  const {economy_turnover_price, economy_return_price} = project;

  // Default values.
  const defaultValues = {
    economy_turnover_price,
    economy_return_price,
  };

  const {
    control,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm({
    defaultValues,
    resolver: yupResolver(constraints),
  });

  // Handle submit.
  const handleFormSubmit = data => {
    const payload = {};
    payload.economy_turnover_price = data.economy_turnover_price || 0;
    payload.economy_return_price = data.economy_return_price || 0;

    dispatch(projectUpdate(projectId, payload));
  };

  // Render component.
  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Form loading={loading} errors={submitErrors}>
        <FormField
          label='Obrat zakázky (fakturovaná částka)'
          errors={errors.economy_turnover_price?.message}
        >
          <Controller
            name='economy_turnover_price'
            control={control}
            value={getValues('economy_turnover_price')}
            render={({field}) => <InputTypePrice {...field} />}
          />
        </FormField>
        <FormField label='Výnos zakázky' errors={errors.economy_return_price?.message}>
          <Controller
            name='economy_return_price'
            control={control}
            value={getValues('economy_return_price')}
            render={({field}) => <InputTypePrice {...field} />}
          />
        </FormField>
        <FormField
          label='Náklady na zakázku'
          description='Náklady budou vypočítány z jednotlivých položek rozpočtu.'
          required={null}
        ></FormField>
        <FormActions>
          <Button value='Uložit změny' type='submit' width='full' />
        </FormActions>
      </Form>
    </form>
  );
};

export default ProjectPlanUpdateForm;

ProjectPlanUpdateForm.defaultProps = {
  projectId: null,
};

ProjectPlanUpdateForm.propTypes = {
  projectId: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
};
