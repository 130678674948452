import React, {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import ProjectInvoiceCreateForm from '../../forms/ProjectInvoiceCreateForm/ProjectInvoiceCreateForm';
import {formatDecimalNumber} from '../../helpers/helpers';
import {IconInvoice} from '../../helpers/icons';
import {totalInvoicesPrices} from '../../helpers/utils';
import {formInit} from '../../store/actions';
import Cell from '../Cell/Cell';
import CellGroup from '../CellGroup/CellGroup';
import Empty from '../Empty/Empty';
import List from '../List/List';
import ListSummary from '../ListSummary/ListSummary';
import Invoice from '../InvoiceThumbnail/InvoiceThumbnail';
import SectionActions from '../SectionActions/SectionActions';
import Button from '../UI/Button/Button';
import Modal from '../UI/Modal/Modal';
import Total from '../UI/Total/Total';

const ProjectsInvoices = () => {
  const {invoices, projectId} = useSelector(
    state => ({
      projectId: state.project.project.id,
      economyPrice: state.project.project.economy_price,
      invoices: state.project.project.invoices,
    }),
    shallowEqual
  );

  const [invoicesTotal, setInvoicesTotal] = useState(0);

  useEffect(() => {
    const invoicesPrices = totalInvoicesPrices(invoices);
    setInvoicesTotal(invoicesPrices.invoiced);
  }, [invoices]);

  // Create modal.
  const [isShowingCreateModal, toggleCreateModal] = useState(false);
  const dispatch = useDispatch();

  const handleCreateModalOpen = () => {
    dispatch(formInit());
    toggleCreateModal(true);
  };

  const handleCreateModalClose = () => {
    toggleCreateModal(false);
  };

  const createModal = (
    <Modal
      isShowing={isShowingCreateModal}
      title='Vytvořit fakturu'
      hide={handleCreateModalClose}
      type='large'
    >
      <ProjectInvoiceCreateForm onSubmit={handleCreateModalClose} projectId={projectId} />
    </Modal>
  );

  const projectInvoices = invoices.map(invoice => <Invoice invoice={invoice} />);

  const hasProjectInvoices = projectInvoices.length > 0;

  const projectInvoicesEmpty = (
    <Empty title='Vytvořte faktury pro projekt'>
      Po dokončení projektu nebo i v průběhu můžete zaznamenávat fakturaci k projektu. Budete mít
      přehled kolik vám zbývá vyfakturovat.
      <br />
      Začněte přidáním faktury přes tlačítko +.
    </Empty>
  );

  const projectInvoicesFooter = (
    <ListSummary>
      <CellGroup type='info' />
      <CellGroup type='prices'>
        <Cell type='price' align='right'>
          <Total>{formatDecimalNumber(invoicesTotal)}</Total>
        </Cell>
      </CellGroup>
    </ListSummary>
  );

  return (
    <>
      <List type='row'>
        {hasProjectInvoices ? projectInvoices : projectInvoicesEmpty}
        {hasProjectInvoices ? projectInvoicesFooter : null}
      </List>
      <SectionActions>
        <Button
          onClick={handleCreateModalOpen}
          value='Přidat fakturu'
          icon={<IconInvoice />}
          size='bigger'
        ></Button>
        {createModal}
      </SectionActions>
    </>
  );
};

export default ProjectsInvoices;
