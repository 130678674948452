import format from 'date-fns/format';
import isDate from 'date-fns/isDate';
import parseISO from 'date-fns/parseISO';
import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import Cell from '../../components/Cell/Cell';
import CellGroup from '../../components/CellGroup/CellGroup';
import Empty from '../../components/Empty/Empty';
import Invoice from '../../components/InvoiceThumbnail/InvoiceThumbnail';
import List from '../../components/List/List';
import ListSummary from '../../components/ListSummary/ListSummary';
import Page from '../../components/Page/Page';
import PageTitle from '../../components/PageTitle/PageTitle';
import GroupDay from '../../components/ProjectTimeDay/ProjectTimeDay';
import ReportsFilters from '../../components/ReportsFilters/ReportsFilters';
import ReportsTabs from '../../components/ReportsTabs/ReportsTabs';
import Section from '../../components/Section/Section';
import Spinner from '../../components/UI/Spinner/Spinner';
import Total from '../../components/UI/Total/Total';
import {formatDecimalNumber} from '../../helpers/helpers';
import {dateToTimestamp, totalInvoicesPrices} from '../../helpers/utils';
import {invoicesFetch} from '../../store/actions';

const ReportsInvoices = () => {
  const {invoices, dateFrom, dateTo, customer, loading} = useSelector(
    state => ({
      invoices: state.invoices.invoices,
      loading: state.invoices.loading,
      dateFrom: state.reports.filters.dateFrom,
      dateTo: state.reports.filters.dateTo,
      customer: state.reports.filters.customer,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isDate(dateFrom) && isDate(dateTo)) {
      const customerValue = customer || 0;
      dispatch(invoicesFetch(dateToTimestamp(dateFrom), dateToTimestamp(dateTo), customerValue));
    }
  }, [dispatch, dateFrom, dateTo, customer]);

  // Create groups of times by day.
  const groups = invoices.reduce((groups, invoice) => {
    const date = invoice.invoiced_at.split(' ')[0];
    if (!groups[date]) {
      // eslint-disable-next-line no-param-reassign
      groups[date] = [];
    }
    groups[date].push(invoice);
    return groups;
  }, {});

  const invoicesList = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(groups)) {
    const date = parseISO(key);
    const formatedDate = format(date, 'dd. MM. yyyy');
    invoicesList.push(<GroupDay key={`date-${formatedDate}`} day={formatedDate} />);

    const listItems = Object.values(value).map(invoice => {
      return <Invoice key={`time-${invoice.id}`} invoice={invoice} />;
    });
    invoicesList.push(
      <List type='row' key={`list-${key}`}>
        {listItems}
      </List>
    );
  }

  const invoicesPrices = totalInvoicesPrices(invoices);

  const hasTermsSelected = dateFrom != null && dateTo != null;
  const canDisplayInvoices = invoicesList.length > 0;

  const emptyInvoices = (
    <Empty title='Žádná fakturace'>Ve vybraném termínu nebyly nalezeny žádné faktury.</Empty>
  );

  const emptyTerms = <Empty title='Zvolte termín'>Zvolte termín pro zobrazení faktur.</Empty>;

  const invoicesFooter = (
    <ListSummary>
      <CellGroup type='info' />
      <CellGroup type='prices'>
        <Cell type='price' align='right'>
          <Total>{formatDecimalNumber(invoicesPrices.invoiced)}</Total>
        </Cell>
      </CellGroup>
    </ListSummary>
  );

  return (
    <Page>
      <Section type='no_border'>
        <PageTitle title='Faktury' />
      </Section>
      <Section type='filters'>
        <ReportsFilters withCustomers />
      </Section>
      <Section type='tabs'>
        <ReportsTabs />
      </Section>
      <Section type='no_border'>
        {loading ? <Spinner /> : null}
        {!loading && !hasTermsSelected ? emptyTerms : null}
        {!loading && hasTermsSelected && !canDisplayInvoices ? emptyInvoices : null}
        {!loading && canDisplayInvoices ? invoicesList : null}
        {!loading && canDisplayInvoices ? invoicesFooter : null}
      </Section>
    </Page>
  );
};

export default ReportsInvoices;
