import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {IconX} from '../../../helpers/icons';
import useCustomers from '../../../hooks/useCustomers';
import InputSelect from '../InputSelect/InputSelect';

const SelectCustomer = ({value, onChange}) => {
  const {customer, customers, setCustomerId} = useCustomers(value);

  // Initialize values.
  useEffect(() => {
    setCustomerId(value);
  }, [value]);

  // Handle selection.
  const handleSelect = value => {
    const newId = value.value;
    setCustomerId(newId);
    onChange(parseInt(newId, 10));
  };

  // Handle selection.
  const handleReset = () => {
    onChange(null);
    setCustomerId(null);
  };

  return (
    <div className='field_input--wrapper'>
      <InputSelect
        options={customers}
        value={customer}
        placeholder={customer ? customer.label : null}
        onChange={handleSelect}
        classNamePrefix
      />
      {customer !== null ? (
        <button type='button' className='button--reset' onClick={handleReset}>
          <IconX />
        </button>
      ) : null}
    </div>
  );
};

export default SelectCustomer;

SelectCustomer.defaultProps = {
  value: null,
};

SelectCustomer.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};
