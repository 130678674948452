/* eslint-disable react/jsx-props-no-spreading, no-undef */
import {yupResolver} from '@hookform/resolvers/yup';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import * as yup from 'yup';
import Form from '../../components/Form/Form';
import FormActions from '../../components/FormActions/FormActions';
import FormField from '../../components/FormField/FormField';
import Button from '../../components/UI/Button/Button';
import InputTypeText from '../../components/UI/InputTypeText/InputTypeText';
import useReduxForm from '../../hooks/useReduxForm';
import {customerUpdate} from '../../store/actions';

// Constraints.
const constraints = yup.object().shape({});

const CustomerAddressUpdateForm = ({onSubmit, customer}) => {
  const dispatch = useDispatch();
  const {loading, submitErrors} = useReduxForm(onSubmit);

  const {vat_number, company_number, street, city, post_code, country} = customer;

  // Default values.
  const defaultValues = {
    company_number,
    vat_number,
    street,
    city,
    post_code,
    country,
  };

  const {
    control,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm({
    defaultValues,
    resolver: yupResolver(constraints),
  });

  // Handle submit.
  const handleFormSubmit = data => {
    const {id} = customer;

    const payload = {};
    payload.company_number = data.company_number || '';
    payload.vat_number = data.vat_number || '';
    payload.street = data.street || '';
    payload.city = data.city || '';
    payload.post_code = data.post_code || '';
    payload.country = data.country || '';

    dispatch(customerUpdate(id, payload));
  };

  // Render component.
  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Form loading={loading} errors={submitErrors}>
        <FormField label='IČO' errors={errors.company_number?.message}>
          <Controller
            name='company_number'
            control={control}
            value={getValues('company_number')}
            render={({field}) => <InputTypeText {...field} />}
          />
        </FormField>
        <FormField label='DIČ' errors={errors.vat_number?.message}>
          <Controller
            name='vat_number'
            control={control}
            value={getValues('vat_number')}
            render={({field}) => <InputTypeText {...field} />}
          />
        </FormField>
        <FormField label='Ulice' errors={errors.street?.message}>
          <Controller
            name='street'
            control={control}
            value={getValues('street')}
            render={({field}) => <InputTypeText {...field} />}
          />
        </FormField>
        <FormField label='PSČ' errors={errors.post_code?.message}>
          <Controller
            name='post_code'
            control={control}
            value={getValues('post_code')}
            render={({field}) => <InputTypeText {...field} />}
          />
        </FormField>
        <FormField label='Město' errors={errors.city?.message}>
          <Controller
            name='city'
            control={control}
            value={getValues('city')}
            render={({field}) => <InputTypeText {...field} />}
          />
        </FormField>
        <FormField label='Země' errors={errors.country?.message}>
          <Controller
            name='country'
            control={control}
            value={getValues('country')}
            render={({field}) => <InputTypeText {...field} />}
          />
        </FormField>
        <FormActions>
          <Button value='Uložit změny' type='submit' width='full' />
        </FormActions>
      </Form>
    </form>
  );
};

export default CustomerAddressUpdateForm;
