/* eslint-disable react/jsx-props-no-spreading, no-undef */
import {yupResolver} from '@hookform/resolvers/yup';
import parseISO from 'date-fns/parseISO';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import * as yup from 'yup';
import Form from '../../components/Form/Form';
import FormActions from '../../components/FormActions/FormActions';
import FormField from '../../components/FormField/FormField';
import Button from '../../components/UI/Button/Button';
import InputDate from '../../components/UI/InputDate/InputDate';
import InputTypePrice from '../../components/UI/InputTypeText/InputTypePrice';
import InputTypeText from '../../components/UI/InputTypeText/InputTypeText';
import SelectInvoiceState from '../../components/UI/SelectInvoiceState/SelectInvoiceState';
import {dateToTimestamp} from '../../helpers/utils';
import useReduxForm from '../../hooks/useReduxForm';
import {invoiceUpdate} from '../../store/actions';

// Constraints.
const constraints = yup.object().shape({});

const ProjectInvoiceUpdateForm = ({onSubmit, invoice}) => {
  const dispatch = useDispatch();
  const {loading, submitErrors} = useReduxForm(onSubmit);

  const {state, number, invoiced_at, deadline_at, paid_at, price} = invoice;

  // Default values.
  const defaultValues = {
    state,
    number,
    price,
    invoiced_at: invoiced_at ? parseISO(invoiced_at) : null,
    deadline_at: deadline_at ? parseISO(deadline_at) : null,
    paid_at: paid_at ? parseISO(paid_at) : null,
  };

  const {
    control,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm({
    defaultValues,
    resolver: yupResolver(constraints),
  });

  // Handle submit.
  const handleFormSubmit = data => {
    const {id} = invoice;
    const payload = {};
    payload.state = data.state || 0;
    payload.number = data.number || '';
    payload.project = data.project ? data.project.id : 0;
    if (data.invoiced_at) {
      payload.invoiced_at = dateToTimestamp(data.invoiced_at);
    }
    if (data.deadline_at) {
      payload.deadline_at = dateToTimestamp(data.deadline_at);
    }
    if (data.paid_at) {
      payload.paid_at = dateToTimestamp(data.paid_at);
    }
    payload.price = data.price ? parseFloat(data.price) : 0;

    dispatch(invoiceUpdate(id, payload));
  };

  // Render component.
  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Form loading={loading} errors={submitErrors}>
        <FormField label='Stav' errors={errors.state?.message}>
          <Controller
            name='state'
            control={control}
            value={getValues('state')}
            render={({field}) => <SelectInvoiceState {...field} />}
          />
        </FormField>
        <FormField label='Číslo fakury' errors={errors.number?.message}>
          <Controller
            name='number'
            control={control}
            value={getValues('number')}
            render={({field}) => <InputTypeText {...field} />}
          />
        </FormField>
        <FormField label='Cena' errors={errors.price?.message}>
          <Controller
            name='price'
            control={control}
            value={getValues('price')}
            render={({field}) => <InputTypePrice {...field} />}
          />
        </FormField>
        <FormField label='Datum vytvoření' errors={errors.invoiced_at?.message}>
          <Controller
            name='invoiced_at'
            control={control}
            value={getValues('invoiced_at')}
            render={({field}) => <InputDate {...field} />}
          />
        </FormField>
        <FormField label='Datum splatnosti' errors={errors.deadline_at?.message}>
          <Controller
            name='deadline_at'
            control={control}
            value={getValues('deadline_at')}
            render={({field}) => <InputDate {...field} />}
          />
        </FormField>
        <FormField label='Datum zaplacení' errors={errors.paid_at?.message}>
          <Controller
            name='paid_at'
            control={control}
            value={getValues('paid_at')}
            render={({field}) => <InputDate {...field} />}
          />
        </FormField>
        <FormActions>
          <Button value='Uložit změny' type='submit' width='full' />
        </FormActions>
      </Form>
    </form>
  );
};

export default ProjectInvoiceUpdateForm;
