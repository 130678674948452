import React from 'react';
import {NavLink} from 'react-router-dom';
import Page from '../../components/Page/Page';
import PageTitle from '../../components/PageTitle/PageTitle';
import Section from '../../components/Section/Section';

const AnonymousForbidden = () => {
  return (
    <Page>
      <Section>
        <PageTitle title='Stránka zakázána' />
      </Section>
      <Section>
        <h2>Stránka na kterou chcete je zakázána..</h2>
        <p className='links'>
          <NavLink to='/'>Zpět na titulní stránku.</NavLink>
        </p>
      </Section>
    </Page>
  );
};

export default AnonymousForbidden;
