import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useHistory, useRouteMatch} from 'react-router';
import Page from '../../components/Page/Page';
import Section from '../../components/Section/Section';
import SectionActions from '../../components/SectionActions/SectionActions';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import Button from '../../components/UI/Button/Button';
import ButtonBack from '../../components/UI/ButtonBack/ButtonBack';
import PagePreloader from '../../components/UI/PagePreloader/PagePreloader';
import useMediaQuery from '../../hooks/useMediaQuery';
import {projectArchive, projectCleanup, projectFetch} from '../../store/actions';
import PageTitle from '../../components/PageTitle/PageTitle';
import ProjectDates from '../../components/ProjectTerms/ProjectTerms';
import ProjectEconomies from '../../components/ProjectBudget/ProjectBudget';
import ProjectHeader from '../../components/ProjectHeader/ProjectHeader';
import ProjectInvoices from '../../components/ProjectInvoices/ProjectInvoices';
import ProjectOffer from '../../components/ProjectOffer/ProjectOffer';
import ProjectRealities from '../../components/ProjectPurchase/ProjectPurchase';
import ProjectSpecification from '../../components/ProjectSpecification/ProjectSpecification';
import ProjectTimes from '../../components/ProjectWork/ProjectWork';
import Spinner from '../../components/UI/Spinner/Spinner';

const Project = () => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  const {project, updating} = useSelector(
    state => ({
      loading: state.project.loading,
      updating: state.project.updating,
      project: state.project.project,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    const {uuid} = match.params;
    if (uuid) {
      dispatch(projectFetch(uuid));
    }

    return () => {
      dispatch(projectCleanup());
    };
  }, [match, dispatch]);

  const backToProjects = () => {
    history.push(`/projects`);
  };

  if (project === null) {
    return <PagePreloader />;
  }

  const handleArchiveSubmit = () => {
    const payload = {archived: 1};
    dispatch(projectArchive(project.id, payload));
  };

  const aside = (
    <>
      <Section>
        <ProjectHeader />
      </Section>
      <Section>
        <SectionTitle title='Termíny' />
        <ProjectDates />
      </Section>
      <Section>
        <SectionTitle title='Nabídka' />
        <ProjectOffer />
      </Section>
    </>
  );

  return (
    <Page aside={isDesktop ? aside : null}>
      {updating === true ? <Spinner type='spinner-padding white' /> : null}
      <Section>
        <ButtonBack value='Zpět na projekty' onClick={backToProjects} />
        <PageTitle title={project.name} />
      </Section>
      {!isDesktop ? (
        <Section>
          <ProjectHeader />
        </Section>
      ) : null}
      <Section>
        <SectionTitle title='Specifikace' />
        <ProjectSpecification />
      </Section>
      <Section>
        <SectionTitle title='Rozpočet' />
        <ProjectEconomies />
      </Section>
      {!isDesktop ? (
        <Section>
          <SectionTitle title='Nabídka' />
          <ProjectOffer />
        </Section>
      ) : null}
      {!isDesktop ? (
        <Section>
          <SectionTitle title='Termíny' />
          <ProjectDates />
        </Section>
      ) : null}
      <Section>
        <SectionTitle title='Nákupy' />
        <ProjectRealities />
      </Section>
      <Section>
        <SectionTitle title='Práce' />
        <ProjectTimes />
      </Section>
      <Section>
        <SectionTitle title='Faktury' />
        <ProjectInvoices />
      </Section>
      <Section type='no_border'>
        <SectionActions>
          <Button onClick={handleArchiveSubmit} value='Archivovat projekt'></Button>
        </SectionActions>
      </Section>
    </Page>
  );
};

export default Project;
