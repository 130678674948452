import React from 'react';
import './Button.scss';

const Button = ({
  value,
  icon = null,
  onClick,
  type = 'button',
  size = 'default',
  width = 'normal',
}) => {
  return (
    <button
      className={`button button--${size} button--${width}`}
      type={type === 'button' ? 'button' : 'submit'}
      onClick={onClick}
    >
      {icon} {value}
    </button>
  );
};

export default Button;
