import React from 'react';
import Spinner from '../UI/Spinner/Spinner';
import './FormLoading.scss';

const FormLoading = ({loading = false}) => {
  if (loading) {
    return (
      <div className='form__loading'>
        <Spinner />
      </div>
    );
  }

  return null;
};

export default FormLoading;
