import {format} from 'date-fns';
import parseISO from 'date-fns/parseISO';
import React, {useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import ProjectsTermsUpdateForm from '../../forms/ProjectTermsUpdateForm/ProjectsTermsUpdateForm';
import {IconTerms} from '../../helpers/icons';
import {formInit} from '../../store/actions';
import Empty from '../Empty/Empty';
import Part from '../Part/Part';
import Parts from '../Parts/Parts';
import SectionActions from '../SectionActions/SectionActions';
import Button from '../UI/Button/Button';
import ButtonEdit from '../UI/ButtonEdit/ButtonEdit';
import Modal from '../UI/Modal/Modal';

const ProjectTerms = () => {
  const {project, startDate, endDate, createdDate} = useSelector(
    state => ({
      project: state.project.project,
      state: state.project.project.state,
      startDate: state.project.project.start,
      endDate: state.project.project.deadline,
      createdDate: state.project.project.created_at,
    }),
    shallowEqual
  );
  const [isShowingUpdateModal, toggleUpdateModal] = useState(false);
  const dispatch = useDispatch();

  const openUpdateForm = () => {
    dispatch(formInit());
    toggleUpdateModal(true);
  };

  const handleUpdate = () => {
    toggleUpdateModal(false);
  };

  return (
    <>
      {startDate !== null || endDate !== null ? (
        <ButtonEdit callback={startDate === null && endDate === null ? null : openUpdateForm} />
      ) : null}
      {startDate === null && endDate === null ? (
        <Empty title='Naplánujte termíny projektu'>
          Po odsouhlasení nabídky pokračujte přidáním datum začátku a odevzdání projektu podle
          vašich volných kapacit.
          <br />
          Termíny zadáte přes ozubené kolečko.
          <SectionActions>
            <Button
              onClick={openUpdateForm}
              value='Nastavit termíny'
              icon={<IconTerms />}
              size='bigger'
            ></Button>
          </SectionActions>
        </Empty>
      ) : (
        <Parts>
          <Part
            title='Začátek projektu'
            content={startDate ? format(parseISO(startDate), 'dd. MM. yyyy') : '---'}
            className='part--project--start'
          />

          <Part
            title='Konec projektu'
            content={endDate ? format(parseISO(endDate), 'dd. MM. yyyy') : '---'}
            className='part--project--deadline'
          />

          <Part
            title='Vytvoření projektu'
            content={createdDate ? format(parseISO(createdDate), 'dd. MM. yyyy hh:mm') : '---'}
            className='part--project--created'
          />
        </Parts>
      )}
      <Modal
        isShowing={isShowingUpdateModal}
        title='Upravit termíny'
        hide={() => toggleUpdateModal(false)}
        type='large'
      >
        <ProjectsTermsUpdateForm onSubmit={handleUpdate} project={project} />
      </Modal>
    </>
  );
};

export default ProjectTerms;
