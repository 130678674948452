import React from 'react';
import {IconArrowLeft} from '../../../helpers/icons';
import './ButtonBack.scss';

const ButtonBack = ({value, onClick}) => {
  return (
    <button className='button--back' type='button' onClick={onClick}>
      <IconArrowLeft size={16} /> {value}
    </button>
  );
};

export default ButtonBack;
