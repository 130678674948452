export const projectStates = [
  {
    id: 0,
    title: 'Založený',
    style: 'state--draft',
    roles: [1, 2, 3],
  },
  {
    id: 1,
    title: 'Rozpracovaný',
    style: 'state--in-progress',
    roles: [1, 2, 3],
  },
  {
    id: 2,
    title: 'Dokončený',
    style: 'state--delivered',
    roles: [1, 2, 3],
  },
  {
    id: 3,
    title: 'Vyfakturovaný',
    style: 'state--invoiced',
    roles: [2, 3],
  },
  {
    id: 4,
    title: 'Zaplacený',
    style: 'state--paid',
    roles: [2, 3],
  },
  {
    id: 5,
    title: 'Zrušený',
    style: 'state--internal',
    roles: [2, 3],
  },
];

export const projectStateFilters = {
  0: true,
  1: true,
  2: false,
  3: false,
  4: false,
  5: false,
};

export const taskStates = [
  {
    id: 0,
    title: 'Založený',
    style: 'state--draft',
  },
  {
    id: 1,
    title: 'Rozpracovaný',
    style: 'state--in-progress',
  },
  {
    id: 2,
    title: 'Dokončený',
    style: 'state--finished',
  },
];

export const outsourceStates = [
  {
    id: 0,
    title: 'Naplánovaný',
    style: 'state--draft',
  },
  {
    id: 1,
    title: 'Dokončený',
    style: 'state--in-progress',
  },
];

export const invoiceStates = [
  {
    id: 0,
    name: 'Vystavená',
  },
  {
    id: 1,
    name: 'Po splatnosti',
  },
  {
    id: 2,
    name: 'Zaplacená',
  },
  {
    id: 3,
    name: 'Stornovaná',
  },
];

export const estimationStates = [
  {
    id: 0,
    title: 'Rozpracovaný',
    style: 'state--draft',
  },
  {
    id: 1,
    title: 'Odeslaný',
    style: 'state--sent',
  },
  {
    id: 2,
    title: 'Schválený',
    style: 'state--selected',
  },
];

export const offerStates = [
  {
    id: 0,
    name: 'Nepotvrzená',
  },
  {
    id: 1,
    name: 'Odeslaná',
  },
  {
    id: 2,
    name: 'Schválená',
  },
];

export const customersStates = [
  {
    id: 0,
    title: 'Archived',
    style: 'state--draft',
  },
  {
    id: 1,
    title: 'Active',
    style: 'state--selected',
  },
];

export const customerStateFilters = {
  0: true,
  1: true,
};

export const EMPTY = '—';

export const memberRoles = [
  {
    id: 0,
    title: 'Anonymní',
    selectable: false,
  },
  {
    id: 1,
    title: 'Člen',
    selectable: true,
  },
  {
    id: 2,
    title: 'Manažer',
    selectable: true,
  },
  {
    id: 3,
    title: 'Účetní',
    selectable: true,
  },
  {
    id: 4,
    title: 'Vlastník',
    selectable: false,
  },
];

export const memberStates = [
  {
    id: 0,
    title: 'Neaktivní',
  },
  {
    id: 1,
    title: 'Aktivní',
  },
];

export const planUnits = [
  {id: 'items', name: 'ks'},
  {id: 'time', name: 'hod'},
];

export const offerStateOptions = [
  {id: 0, name: 'Nepotvrzená'},
  {id: 1, name: 'Potvrzená klientem'},
];

export const databaseDateFormat = 'yyyy-MM-dd hh:mm:ss';
