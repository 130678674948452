import React from 'react';
import Page from '../../components/Page/Page';
import CreateTeamForm from '../../forms/TeamCreateForm/TeamCreateForm';

const TeamCreate = () => {
  return (
    <Page>
      <h1>Založit pracovní prostor</h1>
      <h2>
        Team je skupina lidí ve vaší firmě, kteří pracují na projektech společně. Vysvětlení co je
        team/firma/pracovní prostor.
      </h2>
      <CreateTeamForm />
    </Page>
  );
};

export default TeamCreate;
