import React from 'react';
import {useHistory} from 'react-router-dom';
import './CustomerThumbnail.scss';

const CustomerThumbnail = ({customer}) => {
  const history = useHistory();
  // eslint-disable-next-line camelcase
  const {uuid, name, email, phone_number} = customer;

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      history.push(`/address-book/${uuid}`);
    }
  };

  const handleClick = () => {
    history.push(`/address-book/${uuid}`);
  };

  return (
    <div
      className='customer__thumbnail'
      role='link'
      onClick={handleClick}
      onKeyPress={handleKeyPress}
      tabIndex='0'
    >
      <div className='customer__thumbnail__name'>{name}</div>
      <div className='customer__thumbnail__bottom'>
        <div className='customer__thumbnail__email'>{email || '---'}</div>
        {/* eslint-disable-next-line camelcase */}
        <div className='customer__thumbnail__phone'>{phone_number || '---'}</div>
      </div>
    </div>
  );
};

export default CustomerThumbnail;
