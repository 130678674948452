import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {invoiceStates} from '../../../consts/consts';
import InputSelect from '../InputSelect/InputSelect';

const SelectInvoiceState = ({value, onChange}) => {
  const [stateId, setStateId] = useState(null);
  const [state, setState] = useState(null);
  const [states, setStates] = useState([]);

  // Save stateId.
  useEffect(() => {
    setStateId(value);
  }, [value]);

  // Save customer options.
  useEffect(() => {
    const statesOptions = invoiceStates.map(stateOption => {
      return {
        label: stateOption.name,
        value: stateOption.id,
      };
    });

    setStates(statesOptions);
  }, []);

  // Save selected state.
  useEffect(() => {
    if (stateId !== null && states.length > 0) {
      const stateValue = states.find(stateOption => {
        return stateOption.value === stateId;
      });

      if (stateValue !== undefined) {
        setState(stateValue);
      }
    }
  }, [stateId, states]);

  // Handle selection.
  const handleSelect = value => {
    const newStateId = value.value;
    setStateId(newStateId);
    onChange(newStateId);
  };

  // TODO: Clearing value.
  // TODO: Validation.
  return (
    <InputSelect
      options={states}
      value={state}
      placeholder={state ? state.label : null}
      onChange={handleSelect}
      classNamePrefix
    />
  );
};

export default SelectInvoiceState;

SelectInvoiceState.defaultProps = {
  value: null,
};

SelectInvoiceState.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};
