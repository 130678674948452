import React from 'react';
import './Tabs.scss';
import {useRouteMatch} from 'react-router';
import {NavLink} from 'react-router-dom';

const Tabs = ({tabs = []}) => {
  const route = useRouteMatch();
  const links = tabs.map(tab => {
    const classes = ['tabs_tab'];
    if (route.path === tab.link) {
      classes.push('tabs_tab--active');
    }

    return (
      <NavLink to={tab.link} key={`tab-${tab.link}`} className={classes.join(' ')}>
        {tab.text}
      </NavLink>
    );
  });
  return <nav className='tabs'>{links}</nav>;
};

export default Tabs;
