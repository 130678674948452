import * as actionTypes from '../actions/actionTypes';

const initialState = {
  realities: [],
  error: null,
  loading: false,
  filters: {
    dateFrom: null,
    dateTo: null,
  },
};

/* FETCH */
const realitiesFetchBegin = state => {
  return {
    ...state,
    error: null,
    loading: true,
  };
};

const realitiesFetchSuccess = (state, action) => {
  return {
    ...state,
    realities: action.purchases,
    error: null,
    loading: false,
  };
};

const realitiesFetchFailure = (state, action) => {
  return {
    ...state,
    error: action.error.message,
    loading: false,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REALITIES_FETCH_BEGIN:
      return realitiesFetchBegin(state);
    case actionTypes.REALITIES_FETCH_SUCCESS:
      return realitiesFetchSuccess(state, action);
    case actionTypes.REALITIES_FETCH_FAILURE:
      return realitiesFetchFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
