import * as actionTypes from './actionTypes';

export const reportsDateFromFilter = value => {
  return {
    type: actionTypes.UPDATE_REPORTS_DATE_FROM_FILTER,
    dateFrom: value,
  };
};

export const reportsDateToFilter = value => {
  return {
    type: actionTypes.UPDATE_REPORTS_DATE_TO_FILTER,
    dateTo: value,
  };
};

export const reportsCustomerFilter = value => {
  return {
    type: actionTypes.UPDATE_REPORTS_CUSTOMER_FILTER,
    customer: value,
  };
};

export const reportsMemberFilter = value => {
  return {
    type: actionTypes.UPDATE_REPORTS_MEMBER_FILTER,
    member: value,
  };
};

export const resetReportsFilters = () => {
  return {
    type: actionTypes.RESET_REPORTS_FILTERS,
  };
};
