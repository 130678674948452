import {NotificationManager} from 'react-notifications';
import {push} from 'connected-react-router';
import axios from 'axios';
import * as actionTypes from './actionTypes';
import {customersFetch, projectsFetch, teamFetch} from './index';
import {displayErrors} from '../../api/index';
import {
  userInviteMeQuery,
  userLoginQuery,
  userRegisterQuery,
  userVerifyQuery,
} from '../../api/queries';
import {userTeamsFetch} from './user';
import {setCookie, getCookie} from '../../helpers/utils';

/**
 * Authentication start.
 *
 * @returns {{type: string}}
 */
export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

/**
 * Authentication success.
 *
 * @param auth
 * @param user
 * @returns {{auth: *, type: string, user: *}}
 */
export const authSuccess = (auth, user) => {
  let expirationDate = new Date(new Date().getTime() + auth.expires_in);
  if (auth.expiration_date) {
    expirationDate = auth.expiration_date;
  }
  // Save to persistent local storage
  // localStorage.setItem('access_token', auth.access_token);
  setCookie('access_token', auth.access_token, 1);
  // localStorage.setItem('expires_in', auth.expires_in);
  setCookie('expires_in', auth.expires_in, 1);
  // localStorage.setItem('expiration_date', expirationDate);
  setCookie('expiration_date', expirationDate, 1);
  // localStorage.setItem('refresh_token', auth.refresh_token);
  setCookie('refresh_token', auth.refresh_token, 1);
  // localStorage.setItem('token_type', auth.token_type);
  setCookie('token_type', auth.token_type, 1);
  // localStorage.setItem('email', user.email);
  setCookie('email', user.email);
  // localStorage.setItem('uid', user.id);
  setCookie('uid', user.id);
  if (user.selectedTeam) {
    // localStorage.setItem('selected_team', user.selectedTeam.id);
    setCookie('selected_team', user.selectedTeam.id);
  } else {
    // localStorage.setItem('selected_team', null);
    setCookie('selected_team', null);
  }

  return {
    type: actionTypes.AUTH_SUCCESS,
    auth,
    user,
  };
};

/**
 * Authentication fails.
 *
 * @param error
 * @returns {{type: string, error: *}}
 */
export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    error,
  };
};

/**
 * Authenticate.
 *
 * @param email
 * @param password
 * @param token
 * @returns {Function}
 */
export const auth = (email, password, token) => {
  return dispatch => {
    dispatch(authStart());
    const {clientId, clientSecret} = window;

    axios
      .post('/graphql', userLoginQuery(clientId, clientSecret, email, password, token))
      .then(res => {
        displayErrors(res);
        const {user, auth_token} = res.data.data.login;
        const auth = auth_token;

        if (!user) {
          NotificationManager.error(
            'Uživatele se nepodařilo ověřit. Nesprávný e-mail nebo heslo.',
            'Chyba při přihlášení.'
          );
          dispatch(
            authFail({
              message: '',
            })
          );
          return;
        }

        dispatch(authSuccess(auth, user));
        dispatch(teamFetch(user.selectedTeam.id));
        dispatch(projectsFetch());
        dispatch(customersFetch());
        dispatch(userTeamsFetch());
        dispatch(push('/dashboard'));
      })
      .catch(error => {
        dispatch(authFail(error));
      });
  };
};

export const registerBegin = () => {
  return {
    type: actionTypes.REGISTER_BEGIN,
  };
};

export const registerSuccess = (registerSuccessAuth, registerSuccessUser) => {
  let expirationDate = new Date(new Date().getTime() + registerSuccessAuth.expires_in);
  if (registerSuccessAuth.expiration_date) {
    expirationDate = registerSuccessAuth.expiration_date;
  }

  if (registerSuccessUser.email_verified_at !== null) {
    // localStorage.setItem('access_token', registerSuccessAuth.access_token);
    setCookie('access_token', registerSuccessAuth.access_token);
    // localStorage.setItem('expires_in', registerSuccessAuth.expires_in);
    setCookie('expires_in', registerSuccessAuth.expires_in);
    // localStorage.setItem('expiration_date', expirationDate);
    setCookie('expiration_date', expirationDate);
    // localStorage.setItem('refresh_token', registerSuccessAuth.refresh_token);
    setCookie('refresh_token', registerSuccessAuth.refresh_token);
    // localStorage.setItem('token_type', registerSuccessAuth.token_type);
    setCookie('token_type', registerSuccessAuth.token_type);
    // localStorage.setItem('email', registerSuccessUser.email);
    setCookie('email', registerSuccessUser.email);
    // localStorage.setItem('uid', registerSuccessUser.id);
    setCookie('uid', registerSuccessUser.id);
    if (registerSuccessUser.selectedTeam) {
      // localStorage.setItem('selected_team', registerSuccessUser.selectedTeam.id);
      setCookie('selected_team', registerSuccessUser.selectedTeam.id);
    } else {
      // localStorage.setItem('selected_team', null);
      setCookie('selected_team', null);
    }
  }

  return {
    type: actionTypes.REGISTER_SUCCESS,
    auth: registerSuccessAuth,
    user: registerSuccessUser,
  };
};

export const registerFailure = error => {
  return {
    type: actionTypes.REGISTER_FAILURE,
    error,
  };
};

export const register = (name, email, password, token) => {
  return dispatch => {
    dispatch(registerBegin());
    const {clientId, clientSecret} = window;

    axios
      .post('/graphql', userRegisterQuery(clientId, clientSecret, name, email, password, token))
      .then(res => {
        displayErrors(res);
        const registeredAuth = res.data.data.createUser.auth_token;
        const registeredUser = res.data.data.createUser.user;
        NotificationManager.success('Uživatel zaregistrován');

        dispatch(registerSuccess(registeredAuth, registeredUser));

        // User isn't verified.
        if (registeredUser.email_registered_at === null) {
          dispatch(push('/logout'));
          return;
        }
        if (registeredUser.selectedTeam !== null) {
          dispatch(teamFetch(registeredUser.selectedTeam.id));
          dispatch(projectsFetch());
          dispatch(customersFetch());
          // dispatch(timeFetch());
          dispatch(userTeamsFetch());
          dispatch(push('/projects'));
        } else {
          dispatch(push('/create-team'));
        }
      })
      .catch(error => {
        dispatch(registerFailure(error));
      });
  };
};

/**
 * Check authentication state for automatically login.
 *
 * @returns {Function}
 */
export const authCheckState = () => {
  return dispatch => {
    // User is logged out
    // const accessToken = localStorage.getItem('access_token');
    const accessToken = getCookie('access_token');
    if (!accessToken) {
      // eslint-disable-next-line no-use-before-define
      dispatch(logout());
      dispatch(push('/login'));
      return;
    }

    // User is logged in
    // const expirationDate = new Date(Date.parse(localStorage.getItem('expiration_date')));
    const expirationDate = new Date(Date.parse(getCookie('expiration_date')));
    // Check validity of expiration date.
    if (expirationDate <= new Date()) {
      // eslint-disable-next-line no-use-before-define
      dispatch(logout());
      dispatch(push('/login'));
      return;
    }

    const auth = {
      // access_token: localStorage.getItem('access_token'),
      // expires_in: localStorage.getItem('expires_in'),
      // expiration_date: localStorage.getItem('expiration_date'),
      // refresh_token: localStorage.getItem('refresh_token'),
      // token_type: localStorage.getItem('token_type'),
      access_token: getCookie('access_token'),
      expires_in: getCookie('expires_in'),
      expiration_date: getCookie('expiration_date'),
      refresh_token: getCookie('refresh_token'),
      token_type: getCookie('token_type'),
    };

    const user = {
      // email: localStorage.getItem('email'),
      // id: localStorage.getItem('uid'),
      // selectedTeam: {id: localStorage.getItem('selected_team')},
      email: getCookie('email'),
      id: getCookie('uid'),
      selectedTeam: {id: getCookie('selected_team')},
    };

    dispatch(authSuccess(auth, user));
    dispatch(teamFetch(user.selectedTeam.id));
    dispatch(projectsFetch());
    dispatch(customersFetch());
    // dispatch(timeFetch());
    dispatch(userTeamsFetch());
  };
};

/**
 * Logout.
 *
 * @returns {{type: string}}
 */
export const logout = () => {
  // Delete all cookies.
  const cookies = document.cookie.split(';');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < cookies.length; i++) {
    const spcook = cookies[i].split('=');
    document.cookie = `${spcook[0]}=;expires=Thu, 21 Sep 1979 00:00:01 UTC;`;
  }

  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

/**
 * Set redirection path.
 *
 * @param path
 * @returns {{path: *, type: string}}
 */
export const setAuthRedirectPath = path => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path,
  };
};

export const accountVerifyBegin = () => {
  return {
    type: actionTypes.ACCOUNT_VERIFY_BEGIN,
  };
};

export const accountVerifySuccess = () => {
  return {
    type: actionTypes.ACCOUNT_VERIFY_SUCCESS,
  };
};

export const accountVerifyFail = error => {
  return {
    type: actionTypes.ACCOUNT_VERIFY_FAILURE,
    error,
  };
};

export const accountVerify = (email, password, expires, signature) => {
  return dispatch => {
    dispatch(accountVerifyBegin());
    const {clientId, clientSecret} = window;

    axios
      .post(
        '/graphql',
        userVerifyQuery(clientId, clientSecret, email, password, expires, signature)
      )
      .then(res => {
        displayErrors(res);
        const {user, auth_token} = res.data.data.accountVerify;
        NotificationManager.success('Účet ověřen');
        dispatch(accountVerifySuccess());
        dispatch(authSuccess(auth_token, user));
        dispatch(teamFetch(user.selectedTeam.id));
        dispatch(projectsFetch());
        dispatch(customersFetch());
      })
      .catch(error => {
        dispatch(accountVerifyFail(error));
      });
  };
};

/* INVITE ME */
export const inviteMeBegin = () => {
  return {
    type: actionTypes.INVITE_ME_BEGIN,
  };
};

export const inviteMeSuccess = invitation => {
  return {
    type: actionTypes.INVITE_ME_SUCCESS,
    invitation,
  };
};

export const inviteMeFail = error => {
  return {
    type: actionTypes.INVITE_ME_FAILURE,
    error,
  };
};

export const inviteMe = email => {
  return dispatch => {
    dispatch(inviteMeBegin());
    const {clientId, clientSecret} = window;

    axios
      .post('/graphql', userInviteMeQuery(clientId, clientSecret, email))
      .then(res => {
        displayErrors(res);
        const invitation = res.data.data.inviteMe;
        dispatch(inviteMeSuccess(invitation));
        dispatch(push('/verification-email-sent'));
      })
      .catch(error => {
        dispatch(inviteMeFail(error));
      });
  };
};

export const me = () => {
  return (dispatch, getState) => {
    const {uid} = getState().auth;
    const {members} = getState().team;

    if (members && uid) {
      const filteredMember = members.find(member => {
        return member.user.id === uid;
      });

      return filteredMember;
    }
    return null;
  };
};

export const myRoles = () => {
  return dispatch => {
    const myself = dispatch(me());
    if (!myself) {
      return 0;
    }

    if (myself.roles.length === 0) {
      return 0;
    }

    return myself.roles[0].type;
  };
};

export const owner = member => {
  return dispatch => {
    const myself = dispatch(me());
    if (!member) {
      return false;
    }

    return myself.id === member.id;
  };
};

export const checkPermision = () => {
  return () => {
    return true;

    /* const role = dispatch(myRoles());
    const activePermision = privileges.filter(privilege => {
      return privilege.id === permision;
    });

    if (activePermision.length === 0) {
      return false;
    }

    const permisionRoles = activePermision[0].roles;

    return permisionRoles.indexOf(role) >= 0; */
  };
};

export const checkStatePermision = () => {
  return dispatch => {
    dispatch(myRoles());
    return true;
    // return roles.indexOf(role) >= 0;
  };
};
