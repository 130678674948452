import axios from 'axios';
import {push} from 'connected-react-router';
import {displayErrors} from '../../api/index';
import {
  customerCreateMutation,
  customerFetchQuery,
  customerUpdateMutation,
} from '../../api/queries';
import * as actionTypes from './actionTypes';
import {formBegin, formError, formSuccess} from './forms';

export const customerFetchBegin = () => {
  return {
    type: actionTypes.FETCH_CUSTOMER_BEGIN,
  };
};

export const customerFetchSuccess = customer => {
  return {
    type: actionTypes.FETCH_CUSTOMER_SUCCESS,
    customer,
  };
};

export const customerFetchFailure = error => {
  return {
    type: actionTypes.FETCH_CUSTOMER_FAILURE,
    error,
  };
};

export const customerFetch = id => {
  return dispatch => {
    dispatch(customerFetchBegin());

    axios
      .post('/graphql', customerFetchQuery(id))
      .then(res => {
        displayErrors(res);
        const {customer} = res.data.data;
        dispatch(customerFetchSuccess(customer));
        dispatch(formSuccess());
      })
      .catch(error => {
        dispatch(customerFetchFailure(error));
        dispatch(formError('Formulář se nepodařilo odeslat.'));
      });
  };
};

export const customerCleanup = () => {
  return {
    type: actionTypes.CUSTOMER_CLEANUP,
  };
};

export const customerCreateBegin = () => {
  return {
    type: actionTypes.CUSTOMER_ADD_BEGIN,
  };
};

export const customerCreateSuccess = customer => {
  return {
    type: actionTypes.CUSTOMER_ADD_SUCCESS,
    customer,
  };
};

export const customerCreateFailure = error => {
  return {
    type: actionTypes.CUSTOMER_ADD_FAILURE,
    error,
  };
};

export const customerCreate = name => {
  return dispatch => {
    dispatch(formBegin());
    dispatch(customerCreateBegin());

    axios
      .post('/graphql', customerCreateMutation(name))
      .then(res => {
        const customer = res.data.data.createCustomer;
        dispatch(customerCreateSuccess(customer));
        dispatch(formSuccess());
        dispatch(push(`/customers/${customer.uuid}`));
      })
      .catch(error => {
        dispatch(customerCreateFailure(error));
        dispatch(formError('Formulář se nepodařilo odeslat.'));
      });
  };
};

export const customerUpdateBegin = id => {
  return {
    type: actionTypes.CUSTOMER_UPDATE_BEGIN,
    id,
  };
};

export const customerUpdateSuccess = customer => {
  return {
    type: actionTypes.CUSTOMER_UPDATE_SUCCESS,
    customer,
  };
};

export const customerUpdateFailure = (errors, id) => {
  return {
    type: actionTypes.CUSTOMER_UPDATE_FAILURE,
    errors,
    id,
  };
};

export const customerUpdate = (id, payload) => {
  return dispatch => {
    dispatch(formBegin());
    dispatch(customerUpdateBegin());
    axios
      .post('/graphql', customerUpdateMutation(id, payload))
      .then(res => {
        displayErrors(res);
        dispatch(customerUpdateSuccess(res.data.data.updateCustomer));
        dispatch(formSuccess());
      })
      .catch(error => {
        dispatch(customerUpdateFailure(error));
        dispatch(formError('Formulář se nepodařilo odeslat.'));
      });
  };
};
