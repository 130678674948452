import React from 'react';
import Select, {components} from 'react-select';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    maxWidth: '100%',
    width: '100%',
    backgroundColor: '#fff',
    border: state.isFocused ? '1px solid #6699cc;' : '1px solid #d9e6f2',
    boxShadow: state.isFocused ? '0 0 8px 0 rgba(0, 188, 139, 0.1)' : 'none',
    borderRadius: '0.5rem',
    overflow: 'hidden',
  }),
  singleValue: provided => ({
    ...provided,
    fontSize: '0.75rem',
  }),
  input: provided => ({
    ...provided,
    margin: 0,
    padding: 0,
    lineHeight: '38px',
    height: '38px',
    fontSize: '0.75rem',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '38px',
    padding: '0 0.75rem',
    margin: 0,
    overflow: 'hidden',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    padding: 0,
    margin: 0,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: 0,
    margin: 0,
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '0.75rem',
    // eslint-disable-next-line no-nested-ternary
    color: state.isFocused ? '#0f2757' : state.isSelected ? '#fff' : '#0f2757',
    // eslint-disable-next-line no-nested-ternary
    backgroundColor: state.isFocused ? '#f1f7fb' : state.isSelected ? '#0f2757' : 'transparent',
    padding: '0.5rem 1rem',
  }),
  menu: provided => ({
    ...provided,
    zIndex: 9999999,
  }),
  placeholder: provided => ({
    ...provided,
    fontSize: '0.75rem',
    color: '#9baab3',
  }),
};

const baseUnit = 1;

const customTheme = theme => ({
  ...theme,
  borderRadius: '0.5rem',
  spacing: {
    baseUnit: 1,
    controlHeight: 24,
    menuGutter: baseUnit,
  },
  colors: {
    ...theme.colors,
    primary: '#0a5fe3',
  },
});

const DropdownIndicator = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'>
        <path d='M0 0h24v24H0z' />
        <path
          stroke='#C4C4C4'
          strokeLinejoin='round'
          d='M9 12.923L12.077 16l3.077-3.077M15.154 10.077L12.077 7 9 10.077'
        />
      </svg>
    </components.DropdownIndicator>
  );
};

const InputSelect = props => {
  const {value, readOnly} = props;
  if (readOnly && value) {
    return <div className='input-read-only'>{value.label}</div>;
  }

  if (readOnly && !value) {
    return <div className='input-read-only'>---</div>;
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Select {...props} styles={customStyles} theme={customTheme} components={{DropdownIndicator}} />
  );
};

export default InputSelect;
