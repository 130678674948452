import React, {Component} from 'react';

class FormBase extends Component {
  // eslint-disable-next-line react/sort-comp
  inputChangedHandler(event, inputId) {
    const updatedFormConfig = {
      // eslint-disable-next-line react/destructuring-assignment,react/no-access-state-in-setstate
      ...this.state.formConfig,
    };
    const updatedFormElement = {
      ...updatedFormConfig[inputId],
    };
    // Set element value
    updatedFormElement.elementValue = event.target.value;
    // Check element validity
    if (updatedFormElement.elementShouldValidate) {
      updatedFormElement.elementValid = FormBase.inputCheckValidity(
        event.target.value,
        updatedFormElement.elementValidation
      );
    }
    // Set element as touched
    updatedFormElement.elementTouched = true;
    // Update element
    updatedFormConfig[inputId] = updatedFormElement;

    // Set if is form valid
    let formIsValid = true;
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in updatedFormConfig) {
      formIsValid = updatedFormConfig[key].elementValid && formIsValid;
    }

    // Save state
    this.setState({formConfig: updatedFormConfig, formIsValid});
  }

  static inputCheckValidity(value, rules) {
    if (rules === undefined) {
      return true;
    }

    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== '' && isValid;
    }
    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }
    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid;
    }
    if (rules.email) {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      isValid = emailRegex.test(value) && isValid;
    }

    return isValid;
  }

  render() {
    return <div>NULL</div>;
  }
}

export default FormBase;
