import React from 'react';
import {connect} from 'react-redux';
import Page from '../../components/Page/Page';
import TeamMembersInviteForm from '../../forms/TeamMembersInviteForm/TeamMembersInviteForm';
import * as actions from '../../store/actions';
import PageTitle from '../../components/PageTitle/PageTitle';
import Section from '../../components/Section/Section';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import TeamMembers from '../../components/TeamMembers/TeamMembers';
import TeamMembersInvites from '../../components/TeamMembersInvites/TeamMembersInvites';

const Team = ({team}) => {
  const {id, members, invitations} = team;

  return (
    <Page>
      <Section>
        <PageTitle title='Team' />
      </Section>
      <Section>
        <SectionTitle title='Členové' />
        <TeamMembers members={members} />
      </Section>
      <Section type='no_border'>
        <SectionTitle title='Pozvánky' />
        <TeamMembersInviteForm teamId={id} />
        <TeamMembersInvites invitations={invitations} />
      </Section>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    team: state.team,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTeamUpdate: (id, payload) => dispatch(actions.teamUpdate(id, payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Team);
