import React from 'react';
import ReactDOM from 'react-dom';
import {IconClose} from '../../../helpers/icons';
import './Modal.scss';

const Modal = ({isShowing, hide, children, title = null, type = 'normal'}) =>
  isShowing
    ? ReactDOM.createPortal(
        <>
          <div className='modal-overlay' />
          <div className='modal-wrapper' aria-modal aria-hidden tabIndex={-1} role='dialog'>
            <div className={`modal modal--${type}`}>
              <div className='modal-header'>
                {title ? <div className='modal-header-title'>{title}</div> : null}
                <button
                  type='button'
                  className='modal-close-button'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={hide}
                >
                  <IconClose size={20} />
                </button>
              </div>
              <div className='modal-content'>{children}</div>
            </div>
          </div>
        </>,
        document.body
      )
    : null;

export default Modal;
