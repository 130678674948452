import React, {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import ProjectPlanCreateForm from '../../forms/ProjectPlanCreateForm/ProjectPlanCreateForm';
import ProjectPlanCreateItemItemForm from '../../forms/ProjectPlanCreateItemItemForm/ProjectPlanCreateItemItemForm';
import ProjectPlanCreateItemWorkForm from '../../forms/ProjectPlanCreateItemWorkForm/ProjectPlanCreateItemWorkForm';
import ProjectPlanUpdateForm from '../../forms/ProjectPlanUpdateForm/ProjectPlanUpdateForm';
import {formatDecimalNumber, formatRoundNumber} from '../../helpers/helpers';
import {IconBuy, IconEconomy, IconWork} from '../../helpers/icons';
import {totalPlanPrices} from '../../helpers/utils';
import {formInit} from '../../store/actions';
import Cell from '../Cell/Cell';
import CellGroup from '../CellGroup/CellGroup';
import Empty from '../Empty/Empty';
import List from '../List/List';
import ListSummary from '../ListSummary/ListSummary';
import Part from '../Part/Part';
import Parts from '../Parts/Parts';
import ProjectEconomy from '../BudgetThumbnail/BudgetThumbnail';
import SectionActions from '../SectionActions/SectionActions';
import Button from '../UI/Button/Button';
import ButtonEdit from '../UI/ButtonEdit/ButtonEdit';
import Modal from '../UI/Modal/Modal';
import Total from '../UI/Total/Total';

const ProjectBudget = () => {
  const {plans, projectId, economyTurnoverPrice, economyReturnPrice} = useSelector(
    state => ({
      projectId: state.project.project.id,
      economyTurnoverPrice: state.project.project.economy_turnover_price,
      economyReturnPrice: state.project.project.economy_return_price,
      plans: state.project.project.economies,
    }),
    shallowEqual
  );

  const [planTotalBuy, setPlanTotalBuy] = useState(0);
  const [planTotalSell, setPlanTotalSell] = useState(0);

  useEffect(() => {
    const planPrices = totalPlanPrices(plans);

    setPlanTotalBuy(planPrices.buy);
    setPlanTotalSell(planPrices.sell);
  }, [plans]);

  useDispatch();

  // Create modal.
  const [isShowingCreateModal, toggleCreateModal] = useState(false);
  const [isShowingUpdateModal, toggleUpdateModal] = useState(false);
  const [isShowingCreateItemWorkModal, toggleCreateItemWorkModal] = useState(false);
  const [isShowingCreateItemItemModal, toggleCreateItemItemModal] = useState(false);
  const dispatch = useDispatch();

  const handleCreateModalOpen = () => {
    dispatch(formInit());
    toggleCreateModal(true);
  };

  const handleCreateModalClose = () => {
    toggleCreateModal(false);
  };

  const handleUpdateModalOpen = () => {
    dispatch(formInit());
    toggleUpdateModal(true);
  };

  const handleUpdateModalClose = () => {
    toggleUpdateModal(false);
  };

  const handleCreateItemWorkModalOpen = () => {
    dispatch(formInit());
    toggleCreateItemWorkModal(true);
  };

  const handleCreateItemWorkModalClose = () => {
    toggleCreateItemWorkModal(false);
  };

  const handleCreateItemItemModalOpen = () => {
    dispatch(formInit());
    toggleCreateItemItemModal(true);
  };

  const handleCreateItemItemModalClose = () => {
    toggleCreateItemItemModal(false);
  };

  const createModal = (
    <Modal
      isShowing={isShowingCreateModal}
      title='Vytvořit rozpočet'
      hide={handleCreateModalClose}
      type='normal'
    >
      <ProjectPlanCreateForm onSubmit={handleCreateModalClose} projectId={projectId} />
    </Modal>
  );

  const updateModal = (
    <Modal
      isShowing={isShowingUpdateModal}
      title='Upravit rozpočet'
      hide={handleUpdateModalClose}
      type='normal'
    >
      <ProjectPlanUpdateForm onSubmit={handleUpdateModalClose} projectId={projectId} />
    </Modal>
  );

  const createItemWorkModal = (
    <Modal
      isShowing={isShowingCreateItemWorkModal}
      title='Přidat práci do rozpočtu'
      hide={handleCreateItemWorkModalClose}
      type='normal'
    >
      <ProjectPlanCreateItemWorkForm
        onSubmit={handleCreateItemWorkModalClose}
        projectId={projectId}
      />
    </Modal>
  );

  const createItemItemModal = (
    <Modal
      isShowing={isShowingCreateItemItemModal}
      title='Přidat nákup do rozpočtu'
      hide={handleCreateItemItemModalClose}
      type='normal'
    >
      <ProjectPlanCreateItemItemForm
        onSubmit={handleCreateItemItemModalClose}
        projectId={projectId}
      />
    </Modal>
  );

  const projectEconomies = plans.map(plan => {
    return <ProjectEconomy key={`project-plan-${plan.id}`} plan={plan} />;
  });

  const hasProjectPlans = projectEconomies.length > 0;
  const isPlanned = economyTurnoverPrice > 0;

  const projectPlansEmpty = (
    <Empty title='Prvním krokem k úspěšnému nacenění projektu je naplánování rozpočtu'>
      Zadejte jednotlivé položky nákladů.
      <br />
      Rozpočet je důležitý pro uvědomnění si co ke svému projektu budete potřebovat, co musíte
      nakoupit jací lidé na projektu budou pracovat a kolik hodin odpracují.
      <br />
      Začněte přidáním položek přes tlačítko +.
    </Empty>
  );

  const projectEconomiesFooter = (
    <ListSummary>
      <CellGroup type='info' />
      <CellGroup type='prices'>
        <Cell type='price' align='right'>
          <Total>{formatDecimalNumber(planTotalBuy)}</Total>
        </Cell>
        <Cell type='price' align='right'>
          <Total>{formatDecimalNumber(planTotalSell)}</Total>
        </Cell>
      </CellGroup>
    </ListSummary>
  );

  return (
    <>
      {isPlanned ? <ButtonEdit callback={handleUpdateModalOpen} /> : null}
      {isPlanned ? (
        <Parts>
          <Part
            title='Naplánovaný obrat'
            content={<>{formatRoundNumber(economyTurnoverPrice)}</>}
          />

          <Part title='Naplánovaný výnos' content={<>{formatRoundNumber(economyReturnPrice)}</>} />
        </Parts>
      ) : null}
      <List type='row'>
        {hasProjectPlans ? projectEconomies : projectPlansEmpty}
        {hasProjectPlans ? projectEconomiesFooter : null}
      </List>
      {isPlanned ? (
        <SectionActions>
          <Button
            onClick={handleCreateItemWorkModalOpen}
            value='Naplánovat práci'
            icon={<IconWork />}
            size='bigger'
          ></Button>
          <Button
            onClick={handleCreateItemItemModalOpen}
            value='Naplánovat nákup'
            icon={<IconBuy />}
            size='bigger'
          ></Button>
        </SectionActions>
      ) : (
        <SectionActions>
          <Button
            onClick={handleCreateModalOpen}
            value='Přidat rozpočet'
            icon={<IconEconomy />}
            size='bigger'
          ></Button>
        </SectionActions>
      )}
      {createModal}
      {updateModal}
      {createItemWorkModal}
      {createItemItemModal}
    </>
  );
};

export default ProjectBudget;
