import React from 'react';
import './Page.scss';

const Page = ({children, aside = null, type = 'default'}) => {
  const asideContent = (
    <aside className='page--aside'>
      <div className='page--aside-content'>{aside}</div>
    </aside>
  );

  return (
    <>
      <main className={`page page--${type}${aside ? ' page--with-aside' : ''}`}>
        <section className='page--main'>{children}</section>
        {aside ? asideContent : null}
      </main>
    </>
  );
};

export default Page;
