import React, {useEffect, useState} from 'react';
import {formatPriceNumber} from '../../../helpers/helpers';
import './InputTypeText.scss';

const InputTypePrice = ({value = 0, errors = null, onChange, readOnly}) => {
  const [originalValue, setOriginalValue] = useState(0);
  const [localValue, setLocalValue] = useState(0);
  const [formattedLocalValue, setFormattedLocalValue] = useState(formatPriceNumber(0));
  const [formatted, setFormatted] = useState(true);

  useEffect(() => {
    setOriginalValue(value);
    setLocalValue(value);
    setFormattedLocalValue(formatPriceNumber(value));
  }, [value]);

  /* useEffect(() => {
    if (focused === true) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [focused]); */

  const handleChange = event => {
    const newValue = event.target.value;
    setLocalValue(newValue);
  };

  const handleFocus = event => {
    event.target.focus();
    event.target.select();
    event.target.setSelectionRange(0, 9999);
    setFormatted(false);
  };

  const handleBlur = () => {
    try {
      let valueToSave = localValue;
      valueToSave = typeof valueToSave === 'string' ? valueToSave.replace(/,/g, '.') : valueToSave;
      valueToSave = parseFloat(valueToSave);
      onChange(valueToSave);
    } catch (e) {
      // Fail silently.
    }
    setFormatted(true);
  };

  const handleKeyDown = event => {
    const characterCode = event.keyCode;
    // Update value if enter or tab is pressed.
    if (characterCode === 9 || characterCode === 13) {
      let valueToSave = localValue;
      valueToSave = typeof valueToSave === 'string' ? valueToSave.replace(/,/g, '.') : valueToSave;
      valueToSave = parseFloat(valueToSave);
      onChange(valueToSave);
    }

    // Return to original value if esc is pressed.
    if (characterCode === 27) {
      setLocalValue(originalValue);
      setFormattedLocalValue(formatPriceNumber(originalValue));
    }
  };

  if (readOnly) {
    return (
      <div className='input-read-only'>
        {formattedLocalValue}
        <span className='field_input__suffix'> Kč</span>
      </div>
    );
  }

  return (
    <div className='field_input_wrapper'>
      <input
        value={formatted ? formattedLocalValue : localValue}
        type='text'
        onClick={handleFocus}
        onFocus={handleFocus}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        autoCorrect='off'
        autoCapitalize='off'
        className={`field_input--price align--right ${errors ? 'field_input__error' : null}`}
      />
      <span className='field_input_suffix'>Kč</span>
    </div>
  );
};

export default InputTypePrice;
