import React from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {updateCustomersSearchFilters} from '../../store/actions';
import InputTypeText from '../UI/InputTypeText/InputTypeText';

const CustomersFilters = () => {
  const {searchText} = useSelector(
    state => ({
      searchText: state.customers.filters.text,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const handleChange = value => {
    dispatch(updateCustomersSearchFilters(value));
  };
  return (
    <div className='filters'>
      <div className='filters__section filters__section--fulltext'>
        <div className='search'>
          <InputTypeText onChange={handleChange} value={searchText} />
        </div>
      </div>
    </div>
  );
};

export default CustomersFilters;
