import React from 'react';
import {Redirect, Route, Switch, useLocation} from 'react-router';
import AnonymousRegisterForm from '../../forms/AnonymousRegisterForm/AnonymousRegisterForm';
import ProtectedRoute from '../../hoc/ProtectedRoute';
import Layout from '../../layouts/Layout';
import AnonymousForbidden from '../AnonymousForbidden/AnonymousForbidden';
import AnonymousLogin from '../AnonymousLogin/AnonymousLogin';
import Customer from '../Customer/Customer';
import Customers from '../Customers/Customers';
import Dashboard from '../Dashboard/Dashboard';
import Index from '../Index/Index';

import Project from '../Project/Project';
import Projects from '../Projects/Projects';
import Reports from '../Reports/Reports';
import ReportsCosts from '../ReportsCosts/ReportsCosts';
import ReportsInvoices from '../ReportsInvoices/ReportsInvoices';
import ReportsWork from '../ReportsWork/ReportsWork';
import Team from '../Team/Team';
import TeamCreate from '../TeamCreate/TeamCreate';
import User from '../User/User';
import UserAcceptInvitation from '../UserAcceptInvitation/UserAcceptInvitation';
import Logout from '../UserLogout/UserLogout';
import VerificationEmailSent from '../VerificationEmailSent/VerificationEmailSent';

const Routes = () => {
  const location = useLocation();

  return (
    <Switch location={location}>
      <Layout>
        <Route path='/' exact>
          <Index />
        </Route>
        <Route path='/register/:token/:email/:team?'>
          <AnonymousRegisterForm />
        </Route>
        <Route path='/verification-email-sent' exact>
          <VerificationEmailSent />
        </Route>
        <Route path='/create-team' exact>
          <TeamCreate />
        </Route>
        <Route path='/login/:token?/:email?/:team?'>
          <AnonymousLogin />
        </Route>
        <Route path='/forbidden' exact>
          <AnonymousForbidden />
        </Route>

        <ProtectedRoute path='/dashboard' exact>
          <Dashboard />
        </ProtectedRoute>
        <ProtectedRoute path='/projects' exact>
          <Projects />
        </ProtectedRoute>
        <Route path='/projects/:uuid'>
          <Project />
        </Route>
        <ProtectedRoute path='/address-book' exact>
          <Customers />
        </ProtectedRoute>
        <ProtectedRoute path='/address-book/:uuid'>
          <Customer />
        </ProtectedRoute>
        <ProtectedRoute path='/team' exact>
          <Team />
        </ProtectedRoute>
        <ProtectedRoute path='/user'>
          <User />
        </ProtectedRoute>
        <ProtectedRoute path='/reports' exact>
          <Reports />
        </ProtectedRoute>
        <ProtectedRoute path='/reports/costs'>
          <ReportsCosts />
        </ProtectedRoute>
        <ProtectedRoute path='/reports/work'>
          <ReportsWork />
        </ProtectedRoute>
        <ProtectedRoute path='/reports/invoices'>
          <ReportsInvoices />
        </ProtectedRoute>
        <ProtectedRoute path='/logout' exact>
          <Logout />
        </ProtectedRoute>
        <ProtectedRoute path='/invitation' exact>
          <UserAcceptInvitation />
        </ProtectedRoute>

        <Redirect to='/' />
      </Layout>
    </Switch>
  );
};

export default Routes;
