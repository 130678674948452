import axios from 'axios';
import {NotificationManager} from 'react-notifications';
import {displayErrors} from '../../api';
import {
  invoiceCreateMutation,
  invoiceDeleteMutation,
  invoicesFetchQuery,
  invoiceUpdateMutation,
} from '../../api/queries';

import * as actionTypes from './actionTypes';
import {formBegin, formError, formSuccess} from './forms';

export const invoiceCreateBegin = () => {
  return {
    type: actionTypes.INVOICE_ADD_BEGIN,
  };
};

export const invoiceCreateSuccess = invoice => {
  return {
    type: actionTypes.INVOICE_ADD_SUCCESS,
    invoice,
  };
};

export const invoiceCreateFailure = error => {
  return {
    type: actionTypes.INVOICE_ADD_FAILURE,
    error,
  };
};

export const invoiceCreate = (
  project,
  state,
  number,
  amount,
  dateInvoiced,
  dateValidity,
  datePayment
) => {
  return dispatch => {
    dispatch(formBegin());
    dispatch(invoiceCreateBegin());
    axios
      .post(
        '/graphql',
        invoiceCreateMutation(
          project,
          state,
          number,
          amount,
          dateInvoiced,
          dateValidity,
          datePayment
        )
      )
      .then(res => {
        displayErrors(res);
        const invoice = res.data.data.createInvoice;
        dispatch(invoiceCreateSuccess(invoice));
        dispatch(formSuccess());
      })
      .catch(error => {
        dispatch(invoiceCreateFailure(error));
        dispatch(formError('Formulář se nepodařilo odeslat.'));
      });
  };
};

export const invoiceUpdateBegin = id => {
  return {
    type: actionTypes.INVOICE_UPDATE_BEGIN,
    id,
  };
};

export const invoiceUpdateSuccess = (id, invoice) => {
  return {
    type: actionTypes.INVOICE_UPDATE_SUCCESS,
    id,
    invoice,
  };
};

export const invoiceUpdateFailure = (error, id) => {
  return {
    type: actionTypes.INVOICE_UPDATE_FAILURE,
    error,
    id,
  };
};

export const invoiceUpdate = (id, payload) => {
  return dispatch => {
    dispatch(formBegin());
    dispatch(invoiceUpdateBegin(id));

    axios
      .post('/graphql', invoiceUpdateMutation(id, payload))
      .then(res => {
        displayErrors(res);
        const invoice = res.data.data.updateInvoice;
        dispatch(invoiceUpdateSuccess(id, invoice));
        dispatch(formSuccess());
      })
      .catch(error => {
        dispatch(invoiceUpdateFailure(error, id));
        dispatch(formError('Formulář se nepodařilo odeslat.'));
      });
  };
};

export const invoiceDeleteBegin = id => {
  return {
    type: actionTypes.INVOICE_DELETE_BEGIN,
    id,
  };
};

export const invoiceDeleteSuccess = (id, invoice) => {
  return {
    type: actionTypes.INVOICE_DELETE_SUCCESS,
    id,
    invoice,
  };
};

export const invoiceDeleteFailure = (error, id) => {
  return {
    type: actionTypes.INVOICE_DELETE_FAILURE,
    error,
    id,
  };
};

export const invoiceDelete = id => {
  return dispatch => {
    dispatch(formBegin());
    dispatch(invoiceDeleteBegin(id));

    axios
      .post('/graphql', invoiceDeleteMutation(id))
      .then(res => {
        displayErrors(res);
        dispatch(invoiceDeleteSuccess(id));
        dispatch(formSuccess());
      })
      .catch(error => {
        dispatch(invoiceDeleteFailure(error, id));
        dispatch(formError('Formulář se nepodařilo odeslat.'));
      });
  };
};

/**
 * Invoices fetch.
 */
export const invoicesFetchBegin = id => {
  return {
    type: actionTypes.INVOICES_FETCH_BEGIN,
    id,
  };
};

export const invoicesFetchSuccess = invoices => {
  return {
    type: actionTypes.INVOICES_FETCH_SUCCESS,
    invoices,
  };
};

export const invoicesFetchFailure = (error, id) => {
  return {
    type: actionTypes.INVOICES_FETCH_FAILURE,
    error,
    id,
  };
};

export const invoicesFetch = (from, to, customer) => {
  return dispatch => {
    dispatch(invoicesFetchBegin());

    axios
      .post('/graphql', invoicesFetchQuery(from, to, customer))
      .then(res => {
        displayErrors(res);
        const {invoices} = res.data.data;
        dispatch(invoicesFetchSuccess(invoices));
      })
      .catch(error => {
        dispatch(invoicesFetchFailure(error));
        NotificationManager.error('Internal error');
      });
  };
};
