import React from 'react';

export const IconEdit = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-pencil'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='#1E1D2B'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4' />
    <line x1='13.5' y1='6.5' x2='17.5' y2='10.5' />
  </svg>
);

export const IconSave = ({size = 12, color = 'currentColor', stroke = 2}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    stroke={color}
    strokeWidth={stroke}
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <polyline points='20 6 9 17 4 12'></polyline>
  </svg>
);

export const IconLock = ({size = 12, color = 'currentColor', stroke = 2}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    stroke={color}
    strokeWidth={stroke}
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <rect x='3' y='11' width='18' height='11' rx='2' ry='2'></rect>
    <path d='M7 11V7a5 5 0 0 1 10 0v4'></path>
  </svg>
);

export const IconUnlock = ({size = 12, color = 'currentColor', stroke = 2}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    stroke={color}
    strokeWidth={stroke}
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <rect x='3' y='11' width='18' height='11' rx='2' ry='2'></rect>
    <path d='M7 11V7a5 5 0 0 1 9.9-1'></path>
  </svg>
);

export const IconArrowLeft = ({size = 12, color = 'currentColor', stroke = 2}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    stroke={color}
    strokeWidth={stroke}
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path d='M19 12H6M12 5l-7 7 7 7' />
  </svg>
);

export const IconCreate = ({size = 12, color = 'currentColor', stroke = 2}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    stroke={color}
    strokeWidth={stroke}
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <line x1='12' y1='5' x2='12' y2='19'></line>
    <line x1='5' y1='12' x2='19' y2='12'></line>
  </svg>
);

export const IconDelete = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-trash'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='#1E1D2B'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <line x1='4' y1='7' x2='20' y2='7' />
    <line x1='10' y1='11' x2='10' y2='17' />
    <line x1='14' y1='11' x2='14' y2='17' />
    <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
    <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
  </svg>
);

export const IconChevronUp = ({size = 12, color = 'currentColor', stroke = 2}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    stroke={color}
    strokeWidth={stroke}
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path d='M18 15l-6-6-6 6' />
  </svg>
);

export const IconChevronDown = ({size = 12, color = 'currentColor', stroke = 2}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    stroke={color}
    strokeWidth={stroke}
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path d='M6 9l6 6 6-6' />
  </svg>
);

export const IconTrash = ({size = 12, color = 'currentColor', stroke = 2}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    stroke={color}
    strokeWidth={stroke}
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <polyline points='3 6 5 6 21 6'></polyline>
    <path d='M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2'></path>
    <line x1='10' y1='11' x2='10' y2='17'></line>
    <line x1='14' y1='11' x2='14' y2='17'></line>
  </svg>
);

export const IconDots = ({size = 12, color = 'currentColor', stroke = 2}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    stroke={color}
    strokeWidth={stroke}
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <circle cx='12' cy='12' r='1'></circle>
    <circle cx='12' cy='5' r='1'></circle>
    <circle cx='12' cy='19' r='1'></circle>
  </svg>
);

export const IconTimer = ({size = 12, color = 'currentColor', stroke = 2}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    stroke={color}
    strokeWidth={stroke}
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <circle cx='12' cy='12' r='10'></circle>
    <polyline points='12 6 12 12 16 14'></polyline>
  </svg>
);

export const IconApp = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' strokeWidth='0'>
    <path
      d='M11.9995 1.26002C9.66866 -0.363949 6.47913 -0.469098 4.00812 1.2425C4.00228 1.2425 4.00228 1.24834 3.99644 1.24834L5.34586 3.18776L5.3517 3.18192C5.81903 2.86647 6.31557 2.65617 6.82963 2.52182V8.89503C6.82963 9.53761 7.35538 10.0634 7.99796 10.0634C8.64054 10.0634 9.16628 9.53761 9.16628 8.89503V2.52766C9.6745 2.66201 10.1652 2.87231 10.6092 3.17024L11.9995 1.26002Z'
      fill='#00D618'
    />
    <path
      d='M13.752 4.87599C13.4716 4.47292 13.1503 4.11658 12.8056 3.78945L11.3627 5.69382C11.5205 5.85739 11.6723 6.02679 11.8067 6.21957C13.2613 8.32255 12.7414 11.2142 10.6384 12.6687C8.53539 14.1233 5.64378 13.6034 4.18922 11.5004C2.90406 9.66029 3.16109 7.18928 4.67991 5.6354L3.24287 3.73687C0.72513 6.06768 0.234434 9.95237 2.24395 12.8557C4.44625 16.0335 8.80994 16.8221 11.9878 14.6198C15.1656 12.4175 15.9543 8.05384 13.752 4.87599Z'
      fill='#29ABE2'
    />
  </svg>
);

export const IconProjects = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-layout-list'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.2'
    stroke='#1E1D2B'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <rect x='4' y='4' width='16' height='6' rx='2' />
    <rect x='4' y='14' width='16' height='6' rx='2' />
  </svg>
);

export const IconAddresses = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-users'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.2'
    stroke='#1E1D2B'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <circle cx='9' cy='7' r='4' />
    <path d='M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2' />
    <path d='M16 3.13a4 4 0 0 1 0 7.75' />
    <path d='M21 21v-2a4 4 0 0 0 -3 -3.85' />
  </svg>
);

export const IconTeam = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-tools'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.2'
    stroke='#1E1D2B'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path d='M3 21h4l13 -13a1.5 1.5 0 0 0 -4 -4l-13 13v4' />
    <line x1='14.5' y1='5.5' x2='18.5' y2='9.5' />
    <polyline points='12 8 7 3 3 7 8 12' />
    <line x1='7' y1='8' x2='5.5' y2='9.5' />
    <polyline points='16 12 21 17 17 21 12 16' />
    <line x1='16' y1='17' x2='14.5' y2='18.5' />
  </svg>
);

export const IconUser = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-user'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.2'
    stroke='#1E1D2B'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <circle cx='12' cy='7' r='4' />
    <path d='M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2' />
  </svg>
);

export const IconUserLogout = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-logout'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.2'
    stroke='#1E1D2B'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path d='M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2' />
    <path d='M7 12h14l-3 -3m0 6l3 -3' />
  </svg>
);

export const IconPlus = ({size = 12, color = 'currentColor', stroke = 2}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    stroke={color}
    strokeWidth={stroke}
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <line x1='12' y1='5' x2='12' y2='19'></line>
    <line x1='5' y1='12' x2='19' y2='12'></line>
  </svg>
);

export const IconClose = ({size = 12, color = 'currentColor', stroke = 2}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    stroke={color}
    strokeWidth={stroke}
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <line x1='18' y1='6' x2='6' y2='18'></line>
    <line x1='6' y1='6' x2='18' y2='18'></line>
  </svg>
);

export const IconPlan = ({size = 12, color = 'currentColor', stroke = 2}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    stroke={color}
    strokeWidth={stroke}
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path d='M12 2a10 10 0 1 0 10 10H12V2zM21.18 8.02c-1-2.3-2.85-4.17-5.16-5.18' />
  </svg>
);

export const IconCosts = ({size = 12, color = 'currentColor', stroke = 2}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    stroke={color}
    strokeWidth={stroke}
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <circle cx='10' cy='20.5' r='1' />
    <circle cx='18' cy='20.5' r='1' />
    <path d='M2.5 2.5h3l2.7 12.4a2 2 0 0 0 2 1.6h7.7a2 2 0 0 0 2-1.6l1.6-8.4H7.1' />
  </svg>
);
export const IconNotes = ({size = 12, color = 'currentColor', stroke = 2}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    stroke={color}
    strokeWidth={stroke}
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <circle cx='12' cy='12' r='4'></circle>
    <path d='M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94'></path>
  </svg>
);

export const IconDates = ({size = 12, color = 'currentColor', stroke = 2}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    stroke={color}
    strokeWidth={stroke}
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <rect x='3' y='4' width='18' height='18' rx='2' ry='2'></rect>
    <line x1='16' y1='2' x2='16' y2='6'></line>
    <line x1='8' y1='2' x2='8' y2='6'></line>
    <line x1='3' y1='10' x2='21' y2='10'></line>
  </svg>
);

export const IconSettings = ({size = 12, color = 'currentColor', stroke = 2}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    stroke={color}
    strokeWidth={stroke}
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <circle cx='12' cy='12' r='3'></circle>
    <path d='M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z'></path>
  </svg>
);

export const IconTerik = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-focus'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.2'
    stroke='#1E1D2B'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <circle cx='12' cy='12' r='.5' fill='currentColor' />
    <circle cx='12' cy='12' r='9' />
  </svg>
);

export const IconBalance = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-chart-donut'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.2'
    stroke='#1E1D2B'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path d='M10 3.2a9 9 0 1 0 10.8 10.8a1 1 0 0 0 -1 -1h-3.8a4.1 4.1 0 1 1 -5 -5v-4a0.9 .9 0 0 0 -1 -.8' />
    <path d='M15 3.5a9 9 0 0 1 5.5 5.5h-4.5a9 9 0 0 0 -1 -1v-4.5' />
  </svg>
);

export const IconConfirmed = ({size = 12, color = 'currentColor'}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    stroke={color}
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <polyline points='20 6 9 17 4 12'></polyline>
  </svg>
);

export const IconX = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-x'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.2'
    stroke='#1E1D2B'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <line x1='18' y1='6' x2='6' y2='18' />
    <line x1='6' y1='6' x2='18' y2='18' />
  </svg>
);

export const IconWork = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-alarm'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='#1E1D2B'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <circle cx='12' cy='13' r='7' />
    <polyline points='12 10 12 13 14 13' />
    <line x1='7' y1='4' x2='4.25' y2='6' />
    <line x1='17' y1='4' x2='19.75' y2='6' />
  </svg>
);

export const IconInvoice = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-file-invoice'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='#1E1D2B'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path d='M14 3v4a1 1 0 0 0 1 1h4' />
    <path d='M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z' />
    <line x1='9' y1='7' x2='10' y2='7' />
    <line x1='9' y1='13' x2='15' y2='13' />
    <line x1='13' y1='17' x2='15' y2='17' />
  </svg>
);

export const IconBuy = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-shopping-cart-plus'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='#1E1D2B'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <circle cx='6' cy='19' r='2' />
    <circle cx='17' cy='19' r='2' />
    <path d='M17 17h-11v-14h-2' />
    <path d='M6 5l6.005 .429m7.138 6.573l-.143 .998h-13' />
    <path d='M15 6h6m-3 -3v6' />
  </svg>
);

export const IconEconomy = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-bulb'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='#1E1D2B'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path d='M3 12h1m8 -9v1m8 8h1m-15.4 -6.4l.7 .7m12.1 -.7l-.7 .7' />
    <path d='M9 16a5 5 0 1 1 6 0a3.5 3.5 0 0 0 -1 3a2 2 0 0 1 -4 0a3.5 3.5 0 0 0 -1 -3' />
    <line x1='9.7' y1='17' x2='14.3' y2='17' />
  </svg>
);

export const IconCollapseOpen = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-arrow-bar-down'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='#1E1D2B'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <line x1='12' y1='20' x2='12' y2='10' />
    <line x1='12' y1='20' x2='16' y2='16' />
    <line x1='12' y1='20' x2='8' y2='16' />
    <line x1='4' y1='4' x2='20' y2='4' />
  </svg>
);

export const IconCollapseClose = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-arrow-bar-up'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='#1E1D2B'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <line x1='12' y1='4' x2='12' y2='14' />
    <line x1='12' y1='4' x2='16' y2='8' />
    <line x1='12' y1='4' x2='8' y2='8' />
    <line x1='4' y1='20' x2='20' y2='20' />
  </svg>
);

export const IconTerms = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-calendar-plus'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='#1E1D2B'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <rect x='4' y='5' width='16' height='16' rx='2' />
    <line x1='16' y1='3' x2='16' y2='7' />
    <line x1='8' y1='3' x2='8' y2='7' />
    <line x1='4' y1='11' x2='20' y2='11' />
    <line x1='10' y1='16' x2='14' y2='16' />
    <line x1='12' y1='14' x2='12' y2='18' />
  </svg>
);

export const IconOffer = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-chart-candle'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='#1E1D2B'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <rect x='4' y='6' width='4' height='5' rx='1' />
    <line x1='6' y1='4' x2='6' y2='6' />
    <line x1='6' y1='11' x2='6' y2='20' />
    <rect x='10' y='14' width='4' height='5' rx='1' />
    <line x1='12' y1='4' x2='12' y2='14' />
    <line x1='12' y1='19' x2='12' y2='20' />
    <rect x='16' y='5' width='4' height='6' rx='1' />
    <line x1='18' y1='4' x2='18' y2='5' />
    <line x1='18' y1='11' x2='18' y2='20' />
  </svg>
);
