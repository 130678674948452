import React, {useState} from 'react';
import Form from '../../components/Form/Form';
import FormActions from '../../components/FormActions/FormActions';
import FormField from '../../components/FormField/FormField';
import Button from '../../components/UI/Button/Button';
import InputTypeText from '../../components/UI/InputTypeText/InputTypeText';

const CustomerCreateForm = ({onSubmit}) => {
  const [name, setName] = useState('');

  const handleNameChange = value => {
    setName(value);
  };

  const handleSubmit = () => {
    onSubmit(name);
  };

  return (
    <Form>
      <FormField label='Jméno klienta'>
        <InputTypeText value={name} onChange={handleNameChange} focused />
      </FormField>
      <FormActions>
        <Button value='Vytvořit' onClick={handleSubmit} width='full' />
      </FormActions>
    </Form>
  );
};

export default CustomerCreateForm;
