/* eslint-disable react/jsx-props-no-spreading, no-undef */
import {yupResolver} from '@hookform/resolvers/yup';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import * as yup from 'yup';
import Form from '../../components/Form/Form';
import FormActions from '../../components/FormActions/FormActions';
import FormField from '../../components/FormField/FormField';
import Button from '../../components/UI/Button/Button';
import InputTypeText from '../../components/UI/InputTypeText/InputTypeText';
import useReduxForm from '../../hooks/useReduxForm';
import {customerUpdate} from '../../store/actions';

// Constraints.
const constraints = yup.object().shape({});

const CustomerPersonUpdateForm = ({onSubmit, customer}) => {
  const dispatch = useDispatch();
  const {loading, submitErrors} = useReduxForm(onSubmit);

  const {contact_person, phone_number, email} = customer;

  // Default values.
  const defaultValues = {
    contact_person,
    phone_number,
    email,
  };

  const {
    control,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm({
    defaultValues,
    resolver: yupResolver(constraints),
  });

  // Handle submit.
  const handleFormSubmit = data => {
    const {id} = customer;

    const payload = {};
    payload.contact_person = data.contact_person || '';
    payload.phone_number = data.phone_number || '';
    payload.email = data.email || '';

    dispatch(customerUpdate(id, payload));
  };

  // Render component.
  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Form loading={loading} errors={submitErrors}>
        <FormField label='Jméno' errors={errors.contact_person?.message}>
          <Controller
            name='contact_person'
            control={control}
            value={getValues('contact_person')}
            render={({field}) => <InputTypeText {...field} />}
          />
        </FormField>
        <FormField label='Telefon' errors={errors.phone_number?.message}>
          <Controller
            name='phone_number'
            control={control}
            value={getValues('phone_number')}
            render={({field}) => <InputTypeText {...field} />}
          />
        </FormField>
        <FormField label='Email' errors={errors.email?.message}>
          <Controller
            name='email'
            control={control}
            value={getValues('email')}
            render={({field}) => <InputTypeText {...field} />}
          />
        </FormField>
        <FormActions>
          <Button value='Uložit změny' type='submit' width='full' />
        </FormActions>
      </Form>
    </form>
  );
};

export default CustomerPersonUpdateForm;
