import {useEffect, useState} from 'react';
import {projectStates} from '../consts/consts';

const useProjectStates = id => {
  const [stateId, setStateId] = useState(null);
  const [state, setState] = useState(null);
  const [states, setStates] = useState([]);

  // Save stateId.
  useEffect(() => {
    setStateId(id);
  }, [id]);

  // Save state options.
  useEffect(() => {
    const statesOptions = projectStates.map(stateOption => {
      return {
        label: stateOption.title,
        value: stateOption.id,
      };
    });

    setStates(statesOptions);
  }, [projectStates]);

  // Save selected state.
  useEffect(() => {
    if (stateId !== null && states.length > 0) {
      const stateValue = states.find(stateOption => {
        return stateOption.value === stateId;
      });

      if (stateValue !== undefined) {
        setState(stateValue);
      }
    }
  }, [stateId, states]);

  return {
    state,
    states,
    setStateId,
  };
};

export default useProjectStates;
