import axios from 'axios';
import {displayErrors} from '../../api/index';
import {economyCreateQuery, economyDeleteMutation, economyUpdateMutation} from '../../api/queries';
import * as actionTypes from './actionTypes';
import {formBegin, formError, formSuccess} from './forms';

// Create Economy.
export const economyCreateBegin = () => {
  return {
    type: actionTypes.ECONOMY_CREATE_BEGIN,
  };
};

export const economyCreateSuccess = economy => {
  return {
    type: actionTypes.ECONOMY_CREATE_SUCCESS,
    economy,
  };
};

export const economyCreateFailure = error => {
  return {
    type: actionTypes.ECONOMY_CREATE_FAILURE,
    error,
  };
};

export const economyCreate = (project, referenceId, name, quantity, unit, buyPrice, sellPrice) => {
  return dispatch => {
    dispatch(formBegin());
    dispatch(economyCreateBegin());
    axios
      .post(
        '/graphql',
        economyCreateQuery(project, referenceId, name, quantity, unit, buyPrice, sellPrice)
      )
      .then(res => {
        displayErrors(res);
        const economy = res.data.data.economyCreate;
        dispatch(economyCreateSuccess(economy));
        dispatch(formSuccess());
      })
      .catch(error => {
        dispatch(economyCreateFailure(error));
        dispatch(formError('Formulář se nepodařilo odeslat.'));
      });
  };
};

export const economyUpdateBegin = id => {
  return {
    type: actionTypes.ECONOMY_UPDATE_BEGIN,
    id,
  };
};

export const economyUpdateSuccess = (id, economy) => {
  return {
    type: actionTypes.ECONOMY_UPDATE_SUCCESS,
    id,
    economy,
  };
};

export const economyUpdateFailure = (error, id) => {
  return {
    type: actionTypes.ECONOMY_UPDATE_FAILURE,
    error,
    id,
  };
};

export const economyUpdate = (id, payload) => {
  return dispatch => {
    dispatch(formBegin());
    dispatch(economyUpdateBegin(id));

    axios
      .post('/graphql', economyUpdateMutation(id, payload))
      .then(res => {
        displayErrors(res);
        const economy = res.data.data.economyUpdate;
        dispatch(economyUpdateSuccess(id, economy));
        dispatch(formSuccess());
      })
      .catch(error => {
        dispatch(economyUpdateFailure(error, id));
        dispatch(formError('Formulář se nepodařilo odeslat.'));
      });
  };
};

export const economyDeleteBegin = id => {
  return {
    type: actionTypes.ECONOMY_DELETE_BEGIN,
    id,
  };
};

export const economyDeleteSuccess = (id, payload) => {
  return {
    type: actionTypes.ECONOMY_DELETE_SUCCESS,
    id,
    payload,
  };
};

export const economyDeleteFailure = (error, id) => {
  return {
    type: actionTypes.ECONOMY_DELETE_FAILURE,
    error,
    id,
  };
};

export const economyDelete = id => {
  return dispatch => {
    dispatch(formBegin());
    dispatch(economyDeleteBegin(id));

    axios
      .post('/graphql', economyDeleteMutation(id))
      .then(res => {
        displayErrors(res);
        const economy = res.data.data.economyDelete;
        dispatch(economyDeleteSuccess(id, economy));
        dispatch(formSuccess());
      })
      .catch(error => {
        dispatch(economyDeleteFailure(error, id));
        dispatch(formError('Formulář se nepodařilo odeslat.'));
      });
  };
};
