import format from 'date-fns/format';
import isDate from 'date-fns/isDate';
import parseISO from 'date-fns/parseISO';
import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import Cell from '../../components/Cell/Cell';
import CellGroup from '../../components/CellGroup/CellGroup';
import Empty from '../../components/Empty/Empty';
import List from '../../components/List/List';
import ListSummary from '../../components/ListSummary/ListSummary';
import Page from '../../components/Page/Page';
import PageTitle from '../../components/PageTitle/PageTitle';
import GroupDay from '../../components/ProjectTimeDay/ProjectTimeDay';
import ReportsFilters from '../../components/ReportsFilters/ReportsFilters';
import ReportsTabs from '../../components/ReportsTabs/ReportsTabs';
import Section from '../../components/Section/Section';
import Spinner from '../../components/UI/Spinner/Spinner';
import Total from '../../components/UI/Total/Total';
import WorkThumbnail from '../../components/WorkThumbnail/WorkThumbnail';
import {formatDecimalNumber, formattedTime} from '../../helpers/helpers';
import {dateToTimestamp, totalTimesTime, totalWorkPrices} from '../../helpers/utils';
import {timeFetch} from '../../store/actions';

const ReportsWork = () => {
  const {works, dateFrom, dateTo, customer, member, loading} = useSelector(
    state => ({
      works: state.times.times,
      loading: state.times.loading,
      dateFrom: state.reports.filters.dateFrom,
      dateTo: state.reports.filters.dateTo,
      customer: state.reports.filters.customer,
      member: state.reports.filters.member,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isDate(dateFrom) && isDate(dateTo)) {
      const memberValue = member || 0;
      const customerValue = customer || 0;
      dispatch(
        timeFetch(dateToTimestamp(dateFrom), dateToTimestamp(dateTo), memberValue, customerValue)
      );
    }
  }, [dateFrom, dateTo, member, customer]);

  // Create groups of times by day.
  const groups = works.reduce((groups, time) => {
    const date = time.day.split(' ')[0];
    if (!groups[date]) {
      // eslint-disable-next-line no-param-reassign
      groups[date] = [];
    }
    groups[date].push(time);
    return groups;
  }, {});

  const worksTimes = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(groups)) {
    const date = parseISO(key);
    const formatedDate = format(date, 'dd. MM. yyyy');
    worksTimes.push(<GroupDay key={`date-${formatedDate}`} day={formatedDate} />);

    const listItems = Object.values(value).map(time => {
      return <WorkThumbnail key={`time-${time.id}`} time={time} />;
    });

    const totalLoggedTime = totalTimesTime(value);
    const workPrices = totalWorkPrices(value);

    worksTimes.push(
      <List type='row' key={`list-${key}`}>
        {listItems}
        <ListSummary type='small'>
          <CellGroup type='info' />
          <CellGroup type='quantity'>
            <Cell type='day' align='right'>
              <Total>{formattedTime(totalLoggedTime)}</Total>
            </Cell>
          </CellGroup>
          <CellGroup type='prices'>
            <Cell type='price' align='right'>
              <Total>{formatDecimalNumber(workPrices.totalBuy)}</Total>
            </Cell>
            <Cell type='price' align='right'>
              <Total>{formatDecimalNumber(workPrices.totalSell)}</Total>
            </Cell>
          </CellGroup>
        </ListSummary>
      </List>
    );
  }

  const totalLoggedTime = totalTimesTime(works);
  const workPrices = totalWorkPrices(works);

  const hasTermsSelected = dateFrom != null && dateTo != null;
  const canDisplayWorks = worksTimes.length > 0;

  const emptyWorks = (
    <Empty title='Žádná práce'>Ve vybraném termínu nebyla nalezeny žádná práce.</Empty>
  );

  const emptyTerms = <Empty title='Zvolte termín'>Zvolte termín pro zobrazení práce.</Empty>;

  const projectWorksFooter = (
    <ListSummary>
      <CellGroup type='info' />
      <CellGroup type='quantity'>
        <Cell type='day' align='right'>
          <Total>{formattedTime(totalLoggedTime)}</Total>
        </Cell>
      </CellGroup>
      <CellGroup type='prices'>
        <Cell type='price' align='right'>
          <Total>{formatDecimalNumber(workPrices.totalBuy)}</Total>
        </Cell>
        <Cell type='price' align='right'>
          <Total>{formatDecimalNumber(workPrices.totalSell)}</Total>
        </Cell>
      </CellGroup>
    </ListSummary>
  );

  return (
    <Page>
      <Section type='no_border'>
        <PageTitle title='Práce' />
      </Section>
      <Section type='filters'>
        <ReportsFilters withCustomers withMembers />
      </Section>
      <Section type='tabs'>
        <ReportsTabs />
      </Section>
      <Section type='no_border'>
        {loading ? <Spinner /> : null}
        {!loading && !hasTermsSelected ? emptyTerms : null}
        {!loading && hasTermsSelected && !canDisplayWorks ? emptyWorks : null}
        {!loading && canDisplayWorks ? worksTimes : null}
        {!loading && canDisplayWorks ? projectWorksFooter : null}
      </Section>
    </Page>
  );
};

export default ReportsWork;
