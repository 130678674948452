import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {formatProjectNumber} from '../../../helpers/utils';
import InputSelect from '../InputSelect/InputSelect';

const SelectProject = ({value, onChange}) => {
  const [projectId, setProjectId] = useState(null);
  const [project, setProject] = useState(null);
  const [projects, setProjects] = useState([]);

  const {storedProjects} = useSelector(
    state => ({
      storedProjects: state.projects.items,
    }),
    shallowEqual
  );

  // Save projectId.
  useEffect(() => {
    setProjectId(value);
  }, [value]);

  useEffect(() => {
    const filteredProjects = storedProjects;

    /* if (!fullList) {
      filteredProjects = storedProjects.filter(projectOption => {
        return projectOption.state === 0 || projectOption.state === 1;
      });
    } */

    const projectsOptions = filteredProjects.map(projectOption => {
      const {name} = projectOption;
      const project_number = formatProjectNumber(
        projectOption.project_number,
        projectOption.created_at
      );

      return {
        label: `${project_number} - ${name}`,
        value: projectOption.id,
      };
    });

    setProjects(projectsOptions);
  }, [storedProjects]);

  // Save selected project.
  useEffect(() => {
    if (value !== null && projects.length > 0) {
      const projectValue = projects.find(projectOption => {
        return projectOption.value === value;
      });

      if (projectValue !== undefined) {
        setProject(projectValue);
      }
    }
  }, [projectId, value, projects]);

  // Handle selection.
  const handleSelect = value => {
    const newProjectId = value.value;
    setProjectId(newProjectId);
    onChange(newProjectId);
  };

  // TODO: Clearing value.
  // TODO: Validation.
  return (
    <InputSelect
      options={projects}
      value={project}
      placeholder={project ? project.label : null}
      onChange={handleSelect}
      classNamePrefix
    />
  );
};

export default SelectProject;

SelectProject.defaultProps = {
  value: null,
};

SelectProject.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};
