import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../store/actions';
import InputText from '../../components/UI/InputText/InputText';
import Spinner from '../../components/UI/Spinner/Spinner';

class TeamCreateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      members: [],
      errors: null,
      formIsValid: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
  }

  handleChange(value) {
    this.validate(value);
    this.setState({name: value});
  }

  handleSubmit(event) {
    event.preventDefault();
    const emails = '';
    const {formIsValid, name} = this.state;
    if (formIsValid) {
      const {onCreateTeam} = this.props;
      onCreateTeam(name, emails);
    }
  }

  validate(value) {
    this.setState({formIsValid: false});
    const valid = true;
    if (valid) {
      this.setState({formIsValid: true});
      this.setState({name: value});
    } else {
      this.setState({formIsValid: false});
      this.setState({name: ''});
    }
  }

  renderFormContent() {
    const {errors, formIsValid} = this.state;
    const errorMessage = errors ? <p className='errors'>{errors}</p> : null;
    return (
      <>
        <div className='form-content'>
          {errorMessage}
          <div className='field-name'>
            <span className='label'>Název pracovního prostoru</span>
            <InputText
              onBlur={this.validate}
              onChange={this.handleChange}
              placeholder='Marketingové oddělení, Firma s.r.o., ....'
              index={1}
            />
          </div>
        </div>
        <div className='actions'>
          <button type='submit' className='btn btn--primary' disabled={!formIsValid}>
            Založit
          </button>
        </div>
        <div className='instructions'>Sestavit team bude možnost po založení.</div>
      </>
    );
  }

  render() {
    const {loading} = this.props;
    return (
      <form className='form create-team-form' onSubmit={this.handleSubmit}>
        {loading ? <Spinner /> : this.renderFormContent()}
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.team.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCreateTeam: (name, emails) => dispatch(actions.teamCreate(name, emails)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamCreateForm);
