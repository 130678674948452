import React from 'react';
import './Empty.scss';

const Empty = ({title, children}) => {
  return (
    <div className='empty'>
      <h3 className='empty__title'>{title}</h3>
      <div className='empty__description'>{children}</div>
    </div>
  );
};

export default Empty;
