import React from 'react';

const Input = props => {
  let inputElement;
  let validationError = null;

  const inputClasses = ['custom-input'];
  const {elementShouldValidate, elementTouched, elementValid} = props;
  if (elementShouldValidate && elementTouched && !elementValid) {
    inputClasses.push('invalid');
    validationError = <p className='form-field-error'>Please enter a valid value!</p>;
  }

  /* eslint-disable react/jsx-props-no-spreading */
  switch (props.elementType) {
    case 'input':
      inputElement = (
        <input
          className={inputClasses.join(' ')}
          {...props.elementConfig}
          name={props.elementName}
          value={props.elementValue}
          onChange={props.elementChanged}
        />
      );
      break;
    case 'textarea':
      inputElement = (
        <textarea
          className={inputClasses.join(' ')}
          {...props.elementConfig}
          name={props.elementName}
          onChange={props.elementChanged}
          value={props.elementValue}
        ></textarea>
      );
      break;
    case 'select':
      inputElement = (
        <select
          className={inputClasses.join(' ')}
          value={props.elementValue}
          name={props.elementName}
          onChange={props.elementChanged}
        >
          {props.elementConfig.options.map(option => (
            <option key={option.value} value={option.value}>
              {option.displayValue}
            </option>
          ))}
        </select>
      );
      break;
    default:
      inputElement = null;
  }

  const {elementName, elementLabel} = props;
  return (
    <div className={`form-field form-field-${elementName}`}>
      <label htmlFor={elementName}>{elementLabel}</label>
      {inputElement}
      {validationError}
    </div>
  );
};

export default Input;
