import React from 'react';
import DashboardLinks from '../../components/DashboardLinks/DashboardLinks';
import Page from '../../components/Page/Page';
import PageTitle from '../../components/PageTitle/PageTitle';
import Section from '../../components/Section/Section';

const Dashboard = () => {
  return (
    <Page>
      <Section>
        <PageTitle title='Dashboard' />
      </Section>
      <Section type='no_border'>
        <DashboardLinks />
      </Section>
    </Page>
  );
};

export default Dashboard;
