/* eslint-disable react/jsx-props-no-spreading, no-undef */
import {yupResolver} from '@hookform/resolvers/yup';
import parseISO from 'date-fns/parseISO';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import * as yup from 'yup';
import Form from '../../components/Form/Form';
import FormActions from '../../components/FormActions/FormActions';
import FormField from '../../components/FormField/FormField';
import Button from '../../components/UI/Button/Button';
import InputDate from '../../components/UI/InputDate/InputDate';
import InputTypePrice from '../../components/UI/InputTypeText/InputTypePrice';
import InputTypeText from '../../components/UI/InputTypeText/InputTypeText';
import SelectCustomer from '../../components/UI/SelectCustomer/SelectCustomer';
import {formatDecimalNumber} from '../../helpers/helpers';
import {dateToTimestamp} from '../../helpers/utils';
import useReduxForm from '../../hooks/useReduxForm';
import {realityUpdate} from '../../store/actions';

// Constraints.
const constraints = yup.object().shape({
  reference_id: yup.number().positive().integer().required('Je nutné vybrat dodavatele.'),
});

const ProjectCostsItemItemUpdateForm = ({cost, onSubmit}) => {
  const dispatch = useDispatch();
  const {loading, submitErrors} = useReduxForm(onSubmit);
  const {reference_id, name, quantity, unit, buy_price, sell_price, bought_at} = cost;

  // Default values.
  const defaultValues = {
    reference_id,
    name,
    quantity,
    unit,
    buy_price,
    sell_price,
    bought_at: bought_at ? parseISO(bought_at) : null,
  };

  const {
    control,
    handleSubmit,
    getValues,
    watch,
    formState: {errors},
  } = useForm({
    defaultValues,
    resolver: yupResolver(constraints),
  });

  // Handle submit.
  const handleFormSubmit = data => {
    const {id} = cost;

    const payload = {};
    payload.reference_id = data.reference_id || null;
    payload.name = data.name || '';
    payload.quantity = parseFloat(data.quantity) || 0;
    payload.unit = data.unit || 'items';
    payload.buy_price = data.buy_price ? parseFloat(data.buy_price) : 0;
    payload.sell_price = data.sell_price ? parseFloat(data.sell_price) : 0;
    if (data.bought_at) {
      payload.bought_at = dateToTimestamp(data.bought_at);
    }
    console.log(payload);
    dispatch(realityUpdate(id, payload));
  };

  // Render component.
  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Form loading={loading} errors={submitErrors}>
        <FormField label='Dodavatel' errors={errors.reference_id?.message}>
          <Controller
            name='reference_id'
            control={control}
            value={getValues('reference_id')}
            render={({field}) => <SelectCustomer {...field} />}
          />
        </FormField>
        <FormField label='Krátký popis' errors={errors.name?.message}>
          <Controller
            name='name'
            control={control}
            value={getValues('name')}
            render={({field}) => <InputTypeText {...field} />}
          />
        </FormField>
        <FormField label='Kusů' errors={errors.quantity?.message}>
          <Controller
            name='quantity'
            control={control}
            value={getValues('quantity')}
            render={({field}) => <InputTypeText {...field} />}
          />
        </FormField>
        <div className='field__group'>
          <FormField label='Cena nákupu za jednotku' errors={errors.buy_price?.message}>
            <Controller
              name='buy_price'
              control={control}
              value={getValues('buy_price')}
              render={({field}) => <InputTypePrice {...field} />}
            />
          </FormField>
          <FormField label='Cena nákupu celkem'>
            {formatDecimalNumber((watch('buy_price') || 0) * watch('quantity'))}
          </FormField>
        </div>
        <div className='field__group'>
          <FormField label='Cena prodeje za jednotku' errors={errors.sell_price?.message}>
            <Controller
              name='sell_price'
              control={control}
              value={getValues('sell_price')}
              render={({field}) => <InputTypePrice {...field} />}
            />
          </FormField>
          <FormField label='Cena prodeje celkem'>
            {formatDecimalNumber((watch('sell_price') || 0) * watch('quantity'))}
          </FormField>
        </div>
        <FormField label='Datum nákupu' className='time-day' errors={errors.bought_at?.message}>
          <Controller
            name='bought_at'
            control={control}
            value={getValues('bought_at')}
            render={({field}) => <InputDate {...field} />}
          />
        </FormField>
        <FormActions>
          <Button value='Uložit změny' type='submit' width='full' />
        </FormActions>
      </Form>
    </form>
  );
};

export default ProjectCostsItemItemUpdateForm;
