import React from 'react';
import './UserBadge.scss';

const UserBadge = ({name}) => {
  const parts = name.split(' ');

  let badge = '';

  if (parts.length > 1) {
    parts.forEach(part => {
      badge += part.substr(0, 1);
    });
  } else {
    badge = name.substr(0, 2);
  }

  badge = badge.toUpperCase();

  return (
    <>
      <div className='user--badge'>{badge}</div> <div className='user--name'>{name}</div>
    </>
  );
};

export default UserBadge;
