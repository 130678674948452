import React, {useEffect, useState} from 'react';
import './InputTypeText.scss';

const InputTypeHours = ({value, onChange, readOnly = false}) => {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    setHours(Math.floor(value / 60));
    setMinutes(value % 60);
  }, [value]);

  if (readOnly) {
    return (
      <div className='input-read-only'>{`${`0${hours}`.slice(-2)}:${`0${minutes}`.slice(-2)}`}</div>
    );
  }

  const toMinutes = () => {
    return Number(hours * 60 + minutes);
  };

  const handleHoursChange = event => {
    const value = Number(event.target.value);
    setHours(value);
  };

  const handleHoursFocus = event => {
    event.target.select();
    event.target.setSelectionRange(0, 9999);
  };

  const handleHoursBlur = () => {
    onChange(toMinutes());
  };

  const handleHoursKeyDown = event => {
    const characterCode = event.keyCode;

    // Tab or Enter.
    if (characterCode === 13 || characterCode === 27) {
      if (value !== toMinutes()) {
        onChange(toMinutes());
      }
    }

    // Arrow Up.
    if (characterCode === 38) {
      setHours(hours + 1);
    }

    // Arrow Down.
    if (characterCode === 40) {
      if (hours > 0) {
        setHours(hours - 1);
      }
    }
  };

  const handleMinutesChange = event => {
    const value = Number(event.target.value);
    setMinutes(value);
  };

  const handleMinutesFocus = event => {
    event.target.select();
    event.target.setSelectionRange(0, 9999);
  };

  const handleMinutesBlur = () => {
    if (value !== toMinutes()) {
      onChange(toMinutes());
    }
  };

  const handleMinutesKeyDown = event => {
    const characterCode = event.keyCode;

    // Tab or Enter.
    if (characterCode === 13 || characterCode === 27) {
      if (value !== toMinutes()) {
        onChange(toMinutes());
      }
    }

    // Arrow Up.
    if (characterCode === 38) {
      if (minutes < 59) {
        setMinutes(minutes + 1);
      }
    }

    // Arrow Up + Shift.
    if (characterCode === 38 && event.shiftKey) {
      if (minutes < 50) {
        setMinutes(minutes + 10);
      }
    }

    // Arrow Down.
    if (characterCode === 40) {
      const updatedMinutes = minutes - 1;
      if (updatedMinutes < 0) {
        setMinutes(0);
      } else {
        setMinutes(updatedMinutes);
      }
    }

    // Arrow Down + Shift
    if (characterCode === 40 && event.shiftKey) {
      const updatedMinutes = minutes - 10;
      if (updatedMinutes < 0) {
        setMinutes(0);
      } else {
        setMinutes(updatedMinutes);
      }
    }
  };

  const hoursValue = `0${hours}`.slice(-2);
  const minutesValue = `0${minutes}`.slice(-2);

  return (
    <div className='field_input_wrapper'>
      <input
        value={hoursValue}
        type='text'
        onFocus={handleHoursFocus}
        onChange={handleHoursChange}
        onBlur={handleHoursBlur}
        onKeyDown={handleHoursKeyDown}
        autoCorrect='off'
        autoCapitalize='off'
        className='field_input field_input--hours align--right'
      />
      <span>:</span>
      <input
        value={minutesValue}
        type='text'
        onFocus={handleMinutesFocus}
        onChange={handleMinutesChange}
        onBlur={handleMinutesBlur}
        onKeyDown={handleMinutesKeyDown}
        autoCorrect='off'
        autoCapitalize='off'
        className='field_input field_input--minutes align--right'
      />
      <span className='field_input_suffix'>hh:mm</span>
    </div>
  );
};

export default InputTypeHours;
