/* eslint-disable react/jsx-props-no-spreading, no-undef */
import {yupResolver} from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import * as yup from 'yup';
import Form from '../../components/Form/Form';
import FormField from '../../components/FormField/FormField';
import Button from '../../components/UI/Button/Button';
import InputDate from '../../components/UI/InputDate/InputDate';
import InputTypeHours from '../../components/UI/InputTypeText/InputTypeHours';
import InputTypePrice from '../../components/UI/InputTypeText/InputTypePrice';
import InputTypeText from '../../components/UI/InputTypeText/InputTypeText';
import SelectMember from '../../components/UI/SelectMember/SelectMember';
import SelectProject from '../../components/UI/SelectProject/SelectProject';
import {formatDecimalNumber} from '../../helpers/helpers';
import {dateToTimestamp} from '../../helpers/utils';
import useReduxForm from '../../hooks/useReduxForm';
import {me, timeCreate} from '../../store/actions/index';

// Constraints.
const constraints = yup.object().shape({});

const ProjectTimeCreateForm = ({projectId, onSubmit}) => {
  const dispatch = useDispatch();
  const {loading, submitErrors} = useReduxForm(onSubmit);

  const date = new Date();
  const activeUser = dispatch(me());
  const {id, price, sell_price} = activeUser;

  // Default values.
  const defaultValues = {
    member: id,
    description: '',
    project: projectId || null,
    time: 0,
    day: date,
    buyPrice: price,
    sellPrice: sell_price,
  };

  const {
    control,
    handleSubmit,
    getValues,
    watch,
    formState: {errors},
  } = useForm({
    defaultValues,
    resolver: yupResolver(constraints),
  });

  // Handle submit.
  const handleFormSubmit = data => {
    const payload = {};
    payload.member = data.member || null;
    payload.description = data.description || '';
    payload.project = projectId;
    payload.time = data.time || 0;
    payload.day = dateToTimestamp(data.day);
    payload.buyPrice = data.buyPrice || 0;
    payload.sellPrice = data.sellPrice || 0;

    dispatch(
      timeCreate(
        payload.member,
        payload.description,
        payload.project,
        payload.time,
        payload.day,
        payload.buyPrice,
        payload.sellPrice
      )
    );
  };

  // Render component.
  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Form loading={loading} errors={submitErrors}>
        <FormField label='Projekt' errors={errors.project?.message}>
          <Controller
            name='project'
            control={control}
            value={getValues('project')}
            render={({field}) => <SelectProject {...field} />}
          />
        </FormField>
        <FormField label='Člen' errors={errors.member?.message}>
          <Controller
            name='member'
            control={control}
            value={getValues('member')}
            render={({field}) => <SelectMember {...field} />}
          />
        </FormField>
        <FormField label='Krátký popis práce' errors={errors.description?.message}>
          <Controller
            name='description'
            control={control}
            value={getValues('description')}
            render={({field}) => <InputTypeText {...field} />}
          />
        </FormField>
        <div className='field__group'>
          <FormField label='Den' className='time-day' errors={errors.day?.message}>
            <Controller
              name='day'
              control={control}
              value={getValues('day')}
              render={({field}) => <InputDate {...field} />}
            />
          </FormField>
          <FormField label='Čas' className='time-time' errors={errors.time?.message}>
            <Controller
              name='time'
              control={control}
              value={getValues('time')}
              render={({field}) => <InputTypeHours {...field} />}
            />
          </FormField>
        </div>
        <div className='field__group'>
          <FormField label='Cena nákupu práce za hodinu' errors={errors.buyPrice?.message}>
            <Controller
              name='buyPrice'
              control={control}
              value={getValues('buyPrice')}
              render={({field}) => <InputTypePrice {...field} />}
            />
          </FormField>
          <FormField label='Cena za nákup celkem'>
            {formatDecimalNumber((watch('buyPrice') || 0) * (watch('time') / 60))}
          </FormField>
        </div>
        <div className='field__group'>
          <FormField label='Cena prodeje práce za hodinu' errors={errors.sellPrice?.message}>
            <Controller
              name='sellPrice'
              control={control}
              value={getValues('sellPrice')}
              render={({field}) => <InputTypePrice {...field} />}
            />
          </FormField>
          <FormField label='Cena za prodej celkem'>
            {formatDecimalNumber((watch('sellPrice') || 0) * (watch('time') / 60))}
          </FormField>
        </div>
        <div className='form__actions'>
          <Button value='Vytvořit' type='submit' width='full' />
        </div>
      </Form>
    </form>
  );
};

export default ProjectTimeCreateForm;

ProjectTimeCreateForm.defaultProps = {
  projectId: null,
};

ProjectTimeCreateForm.propTypes = {
  projectId: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
};
