import {useEffect, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';

const useAuth = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [me, setMe] = useState(null);
  const [roles, setRoles] = useState([]);

  const {access_token, uid, members} = useSelector(
    state => ({
      access_token: state.auth.access_token,
      uid: state.auth.uid,
      members: state.team.members,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (access_token === null) {
      setAuthenticated(false);
    } else {
      setAuthenticated(true);
    }
  }, [access_token]);

  useEffect(() => {
    const activeMember = members.find(member => {
      return member.user.id === uid;
    });

    if (activeMember === undefined) {
      setMe(null);
      setRoles([]);
    } else {
      setMe(activeMember);
      setRoles(activeMember.roles);
    }
  }, [uid, members]);

  return {
    authenticated,
    me,
    roles,
  };
};

export default useAuth;
