/* eslint-disable react/jsx-props-no-spreading, no-undef */
import {yupResolver} from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
import Form from '../../components/Form/Form';
import FormActions from '../../components/FormActions/FormActions';
import FormField from '../../components/FormField/FormField';
import Button from '../../components/UI/Button/Button';
import InputTypeText from '../../components/UI/InputTypeText/InputTypeText';
import SelectCustomer from '../../components/UI/SelectCustomer/SelectCustomer';
import SelectMember from '../../components/UI/SelectMember/SelectMember';
import SelectProjectState from '../../components/UI/SelectProjectState/SelectProjectState';
import useReduxForm from '../../hooks/useReduxForm';
import {projectUpdate} from '../../store/actions';

// Constraints.
const constraints = yup.object().shape({});

const ProjectUpdateForm = ({onSubmit}) => {
  const dispatch = useDispatch();
  const {loading, submitErrors} = useReduxForm(onSubmit);

  const {projectId, project} = useSelector(state => ({
    projectId: state.project.project.id,
    project: state.project.project,
  }));

  const {name, state, member, customer} = project;

  // Default values.
  const defaultValues = {
    name: name || '',
    state: state || 0,
    member: member.id || null,
    customer: customer ? customer.id : null,
  };

  const {
    control,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm({
    defaultValues,
    resolver: yupResolver(constraints),
  });

  // Handle submit.
  const handleFormSubmit = data => {
    const payload = {};
    payload.name = data.name || '';
    payload.state = data.state || 0;
    payload.member = data.member || null;
    payload.customer = data.customer || null;

    dispatch(projectUpdate(projectId, payload));
  };

  // Render form.
  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Form loading={loading} errors={submitErrors}>
        <FormField label='Název projektu' errors={errors.name?.message}>
          <Controller
            name='name'
            control={control}
            value={getValues('name')}
            render={({field}) => <InputTypeText {...field} />}
          />
        </FormField>
        <FormField label='Stav' errors={errors.state?.message}>
          <Controller
            name='state'
            control={control}
            value={getValues('state')}
            render={({field}) => <SelectProjectState {...field} />}
          />
        </FormField>
        <FormField label='Zodpovědná osoba' errors={errors.member?.message}>
          <Controller
            name='member'
            control={control}
            value={getValues('member')}
            render={({field}) => <SelectMember {...field} />}
          />
        </FormField>
        <FormField label='Zákazník' errors={errors.customer?.message}>
          <Controller
            name='customer'
            control={control}
            value={getValues('customer')}
            render={({field}) => <SelectCustomer {...field} />}
          />
        </FormField>
        <FormActions>
          <Button value='Uložit změny' type='submit' width='full' />
        </FormActions>
      </Form>
    </form>
  );
};

export default ProjectUpdateForm;

ProjectUpdateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
