import React from 'react';
import List from '../List/List';
import ProjectsThumbnail from '../ProjectThumbnail/ProjectThumbnail';

const CustomerProjects = ({projects, goToProject}) => {
  const projectsEntries = Object.values(projects);
  const projectsList = projectsEntries.map(project => {
    return <ProjectsThumbnail project={project} goToProject={goToProject} key={project.id} />;
  });

  return <List>{projectsList}</List>;
};

export default CustomerProjects;
