import axios from 'axios';
import {push} from 'connected-react-router';
import {format} from 'date-fns';
import {NotificationManager} from 'react-notifications';
import {displayErrors} from '../../api/index';
import {projectCreateMutation, projectFetchQuery, projectUpdateMutation} from '../../api/queries';
import {databaseDateFormat} from '../../consts/consts';
import * as actionTypes from './actionTypes';
// eslint-disable-next-line import/no-cycle
import {formInit, formBegin, formError, formSuccess} from './forms';
import {updateProjectsList} from './projects';

export const projectFetchBegin = () => {
  return {
    type: actionTypes.FETCH_PROJECT_BEGIN,
  };
};

export const projectUpdateValues = values => {
  return {
    type: actionTypes.PROJECT_UPDATE_VALUES,
    values,
  };
};

export const projectFetchSuccess = project => {
  return {
    type: actionTypes.FETCH_PROJECT_SUCCESS,
    project,
  };
};

export const projectFetchFailure = error => {
  return {
    type: actionTypes.FETCH_PROJECT_FAILURE,
    error,
  };
};

export const projectCleanup = () => {
  return {
    type: actionTypes.PROJECT_CLEANUP,
  };
};

export const projectFetch = uuid => {
  return dispatch => {
    dispatch(projectFetchBegin());

    axios
      .post('/graphql', projectFetchQuery(uuid))
      .then(res => {
        displayErrors(res);
        const {project} = res.data.data;
        dispatch(projectFetchSuccess(project));
      })
      .catch(error => {
        dispatch(projectFetchFailure(error));
        NotificationManager.error('Internal error');
      });
  };
};

export const projectCreateBegin = () => {
  return {
    type: actionTypes.PROJECT_ADD_BEGIN,
  };
};

export const projectCreateSuccess = project => {
  return {
    type: actionTypes.PROJECT_ADD_SUCCESS,
    project,
  };
};

export const projectCreateFailure = error => {
  return {
    type: actionTypes.PROJECT_ADD_FAILURE,
    error,
  };
};

export const projectCreate = (name, clientId, redirect = true, isNew = true) => {
  return dispatch => {
    dispatch(formBegin());
    dispatch(projectCreateBegin());

    axios
      .post('/graphql', projectCreateMutation(name, clientId))
      .then(res => {
        displayErrors(res);
        const project = res.data.data.createProject;
        dispatch(projectCreateSuccess(project));
        dispatch(formSuccess());
        dispatch(formInit());

        if (redirect) {
          dispatch(push(`/projects/${project.uuid}${isNew ? '?new' : ''}`));
        } else {
          // NotificationManager.success('Nový projekt vytvořen');
        }
      })
      .catch(error => {
        dispatch(projectCreateFailure(error));
        dispatch(formError('Formulář se nepodařilo odeslat.'));
        // NotificationManager.error('Internal error');
      });
  };
};

export const projectUpdateBegin = id => {
  return {
    type: actionTypes.PROJECT_UPDATE_BEGIN,
    id,
  };
};

export const projectUpdateSuccess = project => {
  return {
    type: actionTypes.PROJECT_UPDATE_SUCCESS,
    project,
  };
};

export const projectUpdateFailure = (error, id) => {
  return {
    type: actionTypes.PROJECT_UPDATE_FAILURE,
    error,
    id,
  };
};

export const projectUpdate = (id, payload) => {
  return dispatch => {
    dispatch(formBegin());
    dispatch(projectUpdateBegin(id));

    axios
      .post('/graphql', projectUpdateMutation(id, payload))
      .then(res => {
        displayErrors(res);
        const project = res.data.data.updateProject;
        dispatch(projectUpdateSuccess(project));
        dispatch(formSuccess());
        if (payload.start !== undefined) {
          dispatch(
            updateProjectsList(
              id,
              'start',
              format(new Date(payload.start * 1000), databaseDateFormat)
            )
          );
        }

        if (payload.deadline !== undefined) {
          dispatch(
            updateProjectsList(
              id,
              'deadline',
              format(new Date(payload.deadline * 1000), databaseDateFormat)
            )
          );
        }

        if (payload.state !== undefined) {
          dispatch(updateProjectsList(id, 'state', payload.state));
        }

        if (payload.name !== undefined) {
          dispatch(updateProjectsList(id, 'name', payload.name));
        }

        // TODO: Customer change.
        // NotificationManager.success(`Nastevení projektu bylo upraveno`);
      })
      .catch(error => {
        dispatch(projectUpdateFailure(error, id));
        dispatch(formError('Formulář se nepodařilo odeslat.'));
        // NotificationManager.error('Internal error');
      });
  };
};

export const projectArchive = (id, payload) => {
  return dispatch => {
    dispatch(formBegin());
    dispatch(projectUpdateBegin(id));

    axios
      .post('/graphql', projectUpdateMutation(id, payload))
      .then(res => {
        displayErrors(res);
        const project = res.data.data.updateProject;
        dispatch(projectUpdateSuccess(project));
        dispatch(updateProjectsList(id, 'archived', 1));

        NotificationManager.success('Projekt byl archivován');
      })
      .catch(error => {
        dispatch(projectUpdateFailure(error, id));
        NotificationManager.error('Internal error');
      });
  };
};

export const clearSelectedProject = () => {
  return {
    type: actionTypes.CLEAR_SELECTED_PROJECT,
  };
};
