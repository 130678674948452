import {useEffect, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';

const useMembers = id => {
  const [memberId, setMemberId] = useState(null);
  const [member, setMember] = useState(null);
  const [members, setMembers] = useState([]);

  const {storedMembers} = useSelector(
    state => ({
      storedMembers: state.team.members,
    }),
    shallowEqual
  );

  // Save customerId.
  useEffect(() => {
    setMemberId(id);
  }, [id]);

  // Save customer options.
  useEffect(() => {
    const membersOptions = storedMembers.map(membersOption => {
      return {
        label: membersOption.user.name,
        value: membersOption.id,
      };
    });

    setMembers(membersOptions);
  }, [storedMembers]);

  // Save selected customer.
  useEffect(() => {
    if (memberId !== null && members.length > 0) {
      const memberValue = members.find(memberOption => {
        return memberOption.value === memberId;
      });

      if (memberValue !== undefined) {
        setMember(memberValue);
      }
    }

    if (memberId === null) {
      setMember(null);
    }
  }, [memberId, members]);

  return {
    member,
    members,
    setMemberId,
  };
};

export default useMembers;
