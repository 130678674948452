/* eslint-disable react/jsx-props-no-spreading, no-undef */
import {yupResolver} from '@hookform/resolvers/yup';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import * as yup from 'yup';
import Form from '../../components/Form/Form';
import FormField from '../../components/FormField/FormField';
import Button from '../../components/UI/Button/Button';
import InputTypePassword from '../../components/UI/InputTypeText/InputTypePassword';
import InputTypeText from '../../components/UI/InputTypeText/InputTypeText';
import Spinner from '../../components/UI/Spinner/Spinner';
import useReduxForm from '../../hooks/useReduxForm';
import {auth} from '../../store/actions';

// Constraints.
const constraints = yup.object().shape({
  email: yup.string().required('Je nutné zadat název projektu.'),
});

const AnonymousLoginForm = ({token, email}) => {
  const dispatch = useDispatch();
  const {loading, submitErrors} = useReduxForm(() => {});

  // Default values.
  const defaultValues = {
    email: email || '',
    password: null,
  };

  const {
    control,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm({
    defaultValues,
    resolver: yupResolver(constraints),
  });

  // Handle submit.
  const handleFormSubmit = data => {
    const payload = {};
    payload.email = data.email || '';
    payload.password = data.password || '';
    payload.token = token || '';

    dispatch(auth(payload.email, payload.password, payload.token));
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Form loading={loading} errors={submitErrors} className='form--thin--centered'>
        <FormField label='E-mail' errors={errors.email?.message} required>
          <Controller
            name='email'
            control={control}
            value={getValues('email')}
            render={({field}) => <InputTypeText {...field} />}
          />
        </FormField>
        <FormField label='Klient' errors={errors.password?.message} required>
          <Controller
            name='password'
            control={control}
            value={getValues('password')}
            render={({field}) => <InputTypePassword {...field} />}
          />
        </FormField>
        <div className='form__actions'>
          <Button value='Přihlásit' type='submit' width='full' size='bigger'></Button>
        </div>

        <div className='form__instructions'>Přihlášením souhlasíte s použitím cookies.</div>
      </Form>
    </form>
  );
};

export default AnonymousLoginForm;
