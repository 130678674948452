import format from 'date-fns/format';
import isDate from 'date-fns/isDate';
import parseISO from 'date-fns/parseISO';
import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import Cell from '../../components/Cell/Cell';
import CellGroup from '../../components/CellGroup/CellGroup';
import Empty from '../../components/Empty/Empty';
import List from '../../components/List/List';
import ListSummary from '../../components/ListSummary/ListSummary';
import Page from '../../components/Page/Page';
import PageTitle from '../../components/PageTitle/PageTitle';
import GroupDay from '../../components/ProjectTimeDay/ProjectTimeDay';
import ProjectReality from '../../components/PurchaseThumbnail/PurchaseThumbnail';
import ReportsFilters from '../../components/ReportsFilters/ReportsFilters';
import ReportsTabs from '../../components/ReportsTabs/ReportsTabs';
import Section from '../../components/Section/Section';
import Spinner from '../../components/UI/Spinner/Spinner';
import Total from '../../components/UI/Total/Total';
import {formatDecimalNumber} from '../../helpers/helpers';
import {dateToTimestamp, totalCostsPrices} from '../../helpers/utils';
import {realitiesFetch} from '../../store/actions';

const ReportsCosts = () => {
  const {realities, dateFrom, dateTo, customer, loading} = useSelector(
    state => ({
      realities: state.realities.realities,
      loading: state.realities.loading,
      dateFrom: state.reports.filters.dateFrom,
      dateTo: state.reports.filters.dateTo,
      customer: state.reports.filters.customer,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isDate(dateFrom) && isDate(dateTo)) {
      const customerValue = customer || 0;
      dispatch(realitiesFetch(dateToTimestamp(dateFrom), dateToTimestamp(dateTo), customerValue));
    }
  }, [dispatch, dateFrom, dateTo, customer]);

  // Create groups of times by day.
  const groups = realities.reduce((groups, reality) => {
    const date = reality.bought_at.split(' ')[0];
    if (!groups[date]) {
      // eslint-disable-next-line no-param-reassign
      groups[date] = [];
    }
    groups[date].push(reality);
    return groups;
  }, {});

  const projectCosts = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(groups)) {
    const date = parseISO(key);
    const formatedDate = format(date, 'dd. MM. yyyy');
    projectCosts.push(<GroupDay key={`date-${formatedDate}`} day={formatedDate} />);

    const listItems = Object.values(value).map(reality => {
      return <ProjectReality key={`project-reality-${reality.id}`} reality={reality} />;
    });

    projectCosts.push(
      <List type='row' key={`list-${key}`}>
        {listItems}
      </List>
    );
  }

  const costsPrices = totalCostsPrices(realities);

  const hasTermsSelected = dateFrom != null && dateTo != null;
  const canDisplayCosts = projectCosts.length > 0;

  const emptyCosts = (
    <Empty title='Žádné nákupy'>Ve vybraném termínu nebyli nalezeny žádné nákupy.</Empty>
  );

  const emptyTerms = <Empty title='Zvolte termín'>Zvolte termín pro zobrazení nákupů.</Empty>;

  const projectCostsFooter = (
    <ListSummary>
      <CellGroup type='info' />
      <CellGroup type='prices'>
        <Cell type='price' align='right'>
          <Total>{formatDecimalNumber(costsPrices.totalBuy)}</Total>
        </Cell>
        <Cell type='price' align='right'>
          <Total>{formatDecimalNumber(costsPrices.totalSell)}</Total>
        </Cell>
      </CellGroup>
    </ListSummary>
  );

  return (
    <Page>
      <Section type='no_border'>
        <PageTitle title='Nákupy' />
      </Section>
      <Section type='filters'>
        <ReportsFilters withCustomers />
      </Section>
      <Section type='tabs'>
        <ReportsTabs />
      </Section>
      <Section type='no_border'>
        {loading ? <Spinner /> : null}
        {!loading && !hasTermsSelected ? emptyTerms : null}
        {!loading && hasTermsSelected && !canDisplayCosts ? emptyCosts : null}
        {!loading && canDisplayCosts ? projectCosts : null}
        {!loading && canDisplayCosts ? projectCostsFooter : null}
      </Section>
    </Page>
  );
};

export default ReportsCosts;
