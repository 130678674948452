import axios from 'axios';
import {NotificationManager} from 'react-notifications';
import {displayErrors} from '../../api/index';
import {
  realitiesFetchQuery,
  realityCreateMutation,
  realityDeleteMutation,
  realityUpdateMutation,
} from '../../api/queries';
import * as actionTypes from './actionTypes';
import {formBegin, formError, formSuccess} from './forms';

export const realityCreateBegin = () => {
  return {
    type: actionTypes.REALITY_CREATE_BEGIN,
  };
};

export const realityCreateSuccess = reality => {
  return {
    type: actionTypes.REALITY_CREATE_SUCCESS,
    reality,
  };
};

export const realityCreateFailure = error => {
  return {
    type: actionTypes.REALITY_CREATE_FAILURE,
    error,
  };
};

export const realityCreate = (
  project,
  plan,
  referenceId,
  description,
  quantity,
  unit,
  buyPrice,
  sellPrice,
  day
) => {
  return dispatch => {
    dispatch(formBegin());
    dispatch(realityCreateBegin());

    axios
      .post(
        '/graphql',
        realityCreateMutation(
          project,
          plan,
          referenceId,
          description,
          quantity,
          unit,
          buyPrice,
          sellPrice,
          day
        )
      )
      .then(res => {
        displayErrors(res);
        const reality = res.data.data.realityCreate;
        dispatch(realityCreateSuccess(reality));
        dispatch(formSuccess());
      })
      .catch(error => {
        dispatch(realityCreateFailure(error));
        dispatch(formError('Formulář se nepodařilo odeslat.'));
      });
  };
};

export const realityUpdateBegin = id => {
  return {
    type: actionTypes.REALITY_UPDATE_BEGIN,
    id,
  };
};

export const realityUpdateSuccess = reality => {
  return {
    type: actionTypes.REALITY_UPDATE_SUCCESS,
    reality,
  };
};

export const realityUpdateFailure = (error, id) => {
  return {
    type: actionTypes.REALITY_UPDATE_FAILURE,
    error,
    id,
  };
};

export const realityUpdate = (id, payload) => {
  return dispatch => {
    dispatch(formBegin());
    dispatch(realityUpdateBegin(id));

    axios
      .post('/graphql', realityUpdateMutation(id, payload))
      .then(res => {
        displayErrors(res);
        const reality = res.data.data.realityUpdate;
        dispatch(realityUpdateSuccess(reality));
        dispatch(formSuccess());
      })
      .catch(error => {
        dispatch(realityUpdateFailure(error, id));
        dispatch(formError('Formulář se nepodařilo odeslat.'));
      });
  };
};

export const realityDeleteBegin = id => {
  return {
    type: actionTypes.REALITY_DELETE_BEGIN,
    id,
  };
};

export const realityDeleteSuccess = (id, reality) => {
  return {
    type: actionTypes.REALITY_DELETE_SUCCESS,
    id,
    reality,
  };
};

export const realityDeleteFailure = (error, id) => {
  return {
    type: actionTypes.REALITY_DELETE_FAILURE,
    error,
    id,
  };
};

export const realityDelete = id => {
  return dispatch => {
    dispatch(formBegin());
    dispatch(realityDeleteBegin(id));
    axios
      .post('/graphql', realityDeleteMutation(id))
      .then(res => {
        displayErrors(res);
        dispatch(realityDeleteSuccess(id));
        dispatch(formSuccess());
      })
      .catch(error => {
        dispatch(realityDeleteFailure(error, id));
        dispatch(formError('Formulář se nepodařilo odeslat.'));
      });
  };
};

/**
 * Invoices fetch.
 */
export const realitiesFetchBegin = id => {
  return {
    type: actionTypes.REALITIES_FETCH_BEGIN,
    id,
  };
};

export const realitiesFetchSuccess = purchases => {
  return {
    type: actionTypes.REALITIES_FETCH_SUCCESS,
    purchases,
  };
};

export const realitiesFetchFailure = (error, id) => {
  return {
    type: actionTypes.REALITIES_FETCH_FAILURE,
    error,
    id,
  };
};

export const realitiesFetch = (from, to, customer) => {
  return dispatch => {
    dispatch(realitiesFetchBegin());

    axios
      .post('/graphql', realitiesFetchQuery(from, to, customer))
      .then(res => {
        displayErrors(res);
        const {purchases} = res.data.data;
        dispatch(realitiesFetchSuccess(purchases));
      })
      .catch(error => {
        dispatch(realitiesFetchFailure(error));
        NotificationManager.error('Internal error');
      });
  };
};
