import * as actionTypes from '../actions/actionTypes';

const initialState = {
  project: null,
  created: false,
  error: null,
  loading: false,
  updating: false,
  addTaskLoading: false,
  addOutsourceLoading: false,
  addEstimationLoading: false,
  addInvoiceLoading: false,
  addEconomyLoading: false,
  updateTaskLoading: [],
  updateOutsourceLoading: [],
  updateEstimationLoading: [],
  updateProjectLoading: [],
  updateEconomyLoading: [],
};

const fetchProjectBegin = state => {
  return {
    ...state,
    error: null,
    loading: true,
  };
};

const fetchProjectSuccess = (state, action) => {
  return {
    ...state,
    error: null,
    loading: false,
    project: action.project,
  };
};

const fetchProjectFailure = state => {
  return {
    ...state,
    error: null,
    loading: false,
  };
};

const projectCleanup = state => {
  return {
    ...state,
    project: null,
  };
};

// Universal project update.
const projectUpdateValues = (state, action) => {
  const {values} = action;
  const {id} = values;

  // Check if we are updating identical project.
  if (state.project.id !== id) {
    return {...state};
  }

  // We don't need update project id.
  delete values.id;

  // Update values on project which are in action.values
  // except id which is deleted above.
  return {
    ...state,
    project: {
      ...state.project,
      ...values,
    },
  };
};

const projectUpdateBegin = (state, action) => {
  return {
    ...state,
    error: null,
    updating: true,
    updateProjectLoading: state.updateProjectLoading.concat(action.id),
  };
};

const projectUpdateSuccess = (state, action) => {
  const updateProjectProgress = [...state.updateProjectLoading];
  const updateProjectLoading = updateProjectProgress.filter(state => {
    return state !== action.project.id;
  });

  return {
    ...state,
    error: null,
    updating: false,
    project: action.project,
    updateProjectLoading,
  };
};

const projectUpdateFailure = (state, action) => {
  const updateProjectProgress = state.updateProjectLoading;
  const updateProjectLoading = updateProjectProgress.filter(state => {
    return state !== action.id;
  });

  return {
    ...state,
    error: action.error.message,
    updating: false,
    updateProjectLoading,
  };
};

/* INVOICE ADD */
const invoiceAddBegin = state => {
  return {
    ...state,
    error: null,
    updating: true,
    addInvoiceLoading: true,
  };
};

const invoiceAddSuccess = (state, action) => {
  const {invoice} = action;
  invoice.active = true;
  return {
    ...state,
    error: null,
    updating: false,
    project: {
      ...state.project,
      invoices: [invoice, ...state.project.invoices],
    },
    addInvoiceLoading: false,
  };
};

const invoiceAddFailure = (state, action) => {
  return {
    ...state,
    error: action.error.message,
    updating: false,
    addInvoiceLoading: false,
  };
};

/* ESTIMATION UPDATE */
const invoiceUpdateBegin = state => {
  return {
    ...state,
    error: null,
    updating: true,
  };
};

// eslint-disable-next-line consistent-return
const invoiceUpdateSuccess = (state, action) => {
  let invoices = [];
  if (state.project) {
    invoices = [...state.project.invoices];
  }
  if (state.customer) {
    invoices = [...state.customer.invoices];
  }

  const updatedInvoices = invoices.map(invoice => {
    if (invoice.id !== action.invoice.id) {
      return invoice;
    }
    return {
      ...invoice,
      ...action.invoice,
    };
  });

  if (state.project) {
    return {
      ...state,
      error: null,
      updating: false,
      project: {
        ...state.project,
        invoices: updatedInvoices,
      },
    };
  }
  if (state.customer) {
    return {
      ...state,
      error: null,
      updating: false,
      customer: {
        ...state.customer,
        invoices: updatedInvoices,
      },
    };
  }
};

const invoiceUpdateFailure = (state, action) => {
  return {
    ...state,
    error: action.error.message,
    updating: false,
  };
};

const invoiceDeleteBegin = state => {
  return {
    ...state,
    error: null,
    updating: true,
  };
};

const invoiceDeleteSuccess = (state, action) => {
  const {id} = action;
  const updatedInvoices = state.project.invoices.filter(invoice => {
    return invoice.id !== id;
  });

  return {
    ...state,
    error: null,
    updating: false,
    project: {
      ...state.project,
      invoices: updatedInvoices,
    },
  };
};

const invoiceDeleteFailure = (state, action) => {
  return {
    ...state,
    error: action.error.message,
    updating: false,
  };
};

/* TIME CREATE */
const timeCreateBegin = state => {
  return {
    ...state,
    error: null,
    updating: true,
    addTimeLoading: true,
  };
};

const timeCreateSuccess = (state, action) => {
  const {time} = action;
  time.active = true;

  return {
    ...state,
    error: null,
    updating: false,
    project: {
      ...state.project,
      times: [time, ...state.project.times],
    },
    addTimeLoading: false,
  };
};

const timeCreateFailure = (state, action) => {
  return {
    ...state,
    error: action.error.message,
    updating: false,
    addTimeLoading: false,
  };
};

/* TIME UPDATE */
const timeUpdateBegin = state => {
  return {
    ...state,
    error: null,
    updating: true,
  };
};

const timeUpdateSuccess = (state, action) => {
  const times = [...state.project.times];

  const updatedTimes = times.map(time => {
    if (time.id !== action.time.id) {
      return time;
    }
    return {
      ...time,
      ...action.time,
    };
  });
  return {
    ...state,
    error: null,
    updating: false,
    project: {
      ...state.project,
      times: updatedTimes,
    },
  };
};

const timeUpdateFailure = (state, action) => {
  return {
    ...state,
    error: action.error.message,
    updating: false,
  };
};

/* TIME DELETE */
const timeDeleteBegin = state => {
  return {
    ...state,
    error: null,
    updating: true,
  };
};

const timeDeleteSuccess = (state, action) => {
  const {id} = action;
  const updatedTimes = state.project.times.filter(time => {
    return Number(time.id) !== Number(id);
  });

  return {
    ...state,
    error: null,
    updating: false,
    project: {
      ...state.project,
      times: updatedTimes,
    },
  };
};

const timeDeleteFailure = (state, action) => {
  return {
    ...state,
    error: action.error.message,
    updating: false,
  };
};

/* Economy Create */
const economyCreateBegin = state => {
  return {
    ...state,
    error: null,
    updating: true,
    addEconomyLoading: true,
  };
};

const economyCreateSuccess = (state, action) => {
  const {economy} = action;
  economy.active = true;

  return {
    ...state,
    error: null,
    updating: false,
    project: {
      ...state.project,
      economies: [...state.project.economies, economy],
    },
    addEconomyLoading: false,
  };
};

const economyCreateFailure = (state, action) => {
  return {
    ...state,
    error: action.error.message,
    updating: false,
    addEconomyLoading: false,
  };
};

/* Economy Update */
const economyUpdateBegin = state => {
  return {
    ...state,
    error: null,
    updating: true,
  };
};

const economyUpdateSuccess = (state, action) => {
  const economies = [...state.project.economies];

  const updatedEconomies = economies.map(economy => {
    if (economy.id !== action.economy.id) {
      return economy;
    }
    return {
      ...economy,
      ...action.economy,
    };
  });

  return {
    ...state,
    error: null,
    updating: false,
    project: {
      ...state.project,
      economies: updatedEconomies,
    },
  };
};

const economyUpdateFailure = (state, action) => {
  return {
    ...state,
    error: action.error.message,
    updating: false,
  };
};

/* Economy Delete */
const economyDeleteBegin = state => {
  return {
    ...state,
    error: null,
    updating: true,
  };
};

const economyDeleteSuccess = (state, action) => {
  const {id} = action;
  const updatedEconomies = state.project.economies.filter(economy => {
    return Number(economy.id) !== Number(id);
  });

  return {
    ...state,
    error: null,
    updating: false,
    project: {
      ...state.project,
      economies: updatedEconomies,
    },
  };
};

const economyDeleteFailure = (state, action) => {
  return {
    ...state,
    error: action.error.message,
    updating: false,
  };
};

/* Reality Create */
const realityCreateBegin = state => {
  return {
    ...state,
    error: null,
    updating: true,
    addRealityLoading: true,
  };
};

const realityCreateSuccess = (state, action) => {
  const {reality} = action;
  reality.active = true;

  return {
    ...state,
    error: null,
    updating: false,
    project: {
      ...state.project,
      realities: [...state.project.realities, reality],
    },
    addRealityLoading: false,
  };
};

const realityCreateFailure = (state, action) => {
  return {
    ...state,
    error: action.error.message,
    updating: false,
    addRealityLoading: false,
  };
};

/* Reality Update */
const realityUpdateBegin = state => {
  return {
    ...state,
    error: null,
    updating: true,
  };
};

const realityUpdateSuccess = (state, action) => {
  const realities = [...state.project.realities];

  const updatedRealities = realities.map(reality => {
    if (reality.id !== action.reality.id) {
      return reality;
    }
    return {
      ...reality,
      ...action.reality,
    };
  });

  return {
    ...state,
    error: null,
    updating: false,
    project: {
      ...state.project,
      realities: updatedRealities,
    },
  };
};

const realityUpdateFailure = (state, action) => {
  return {
    ...state,
    error: action.error.message,
    updating: false,
  };
};

/* Reality Delete */
const realityDeleteBegin = state => {
  return {
    ...state,
    error: null,
    updating: true,
  };
};

const realityDeleteSuccess = (state, action) => {
  const {id} = action;
  const updatedRealities = state.project.realities.filter(reality => {
    return Number(reality.id) !== Number(id);
  });

  return {
    ...state,
    error: null,
    updating: false,
    project: {
      ...state.project,
      realities: updatedRealities,
    },
  };
};

const realityDeleteFailure = (state, action) => {
  return {
    ...state,
    error: action.error.message,
    updating: false,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROJECT_BEGIN:
      return fetchProjectBegin(state);
    case actionTypes.FETCH_PROJECT_SUCCESS:
      return fetchProjectSuccess(state, action);
    case actionTypes.FETCH_PROJECT_FAILURE:
      return fetchProjectFailure(state);

    case actionTypes.PROJECT_CLEANUP:
      return projectCleanup(state, action);

    case actionTypes.TIME_CREATE_BEGIN:
      return timeCreateBegin(state);
    case actionTypes.TIME_CREATE_SUCCESS:
      return timeCreateSuccess(state, action);
    case actionTypes.TIME_CREATE_FAILURE:
      return timeCreateFailure(state, action);
    case actionTypes.TIME_UPDATE_BEGIN:
      return timeUpdateBegin(state);
    case actionTypes.TIME_UPDATE_SUCCESS:
      return timeUpdateSuccess(state, action);
    case actionTypes.TIME_UPDATE_FAILURE:
      return timeUpdateFailure(state, action);
    case actionTypes.TIME_DELETE_BEGIN:
      return timeDeleteBegin(state);
    case actionTypes.TIME_DELETE_SUCCESS:
      return timeDeleteSuccess(state, action);
    case actionTypes.TIME_DELETE_FAILURE:
      return timeDeleteFailure(state, action);

    case actionTypes.PROJECT_UPDATE_BEGIN:
      return projectUpdateBegin(state, action);
    case actionTypes.PROJECT_UPDATE_SUCCESS:
      return projectUpdateSuccess(state, action);
    case actionTypes.PROJECT_UPDATE_FAILURE:
      return projectUpdateFailure(state, action);
    case actionTypes.PROJECT_UPDATE_VALUES:
      return projectUpdateValues(state, action);

    case actionTypes.INVOICE_ADD_BEGIN:
      return invoiceAddBegin(state);
    case actionTypes.INVOICE_ADD_SUCCESS:
      return invoiceAddSuccess(state, action);
    case actionTypes.INVOICE_ADD_FAILURE:
      return invoiceAddFailure(state, action);
    case actionTypes.INVOICE_UPDATE_BEGIN:
      return invoiceUpdateBegin(state);
    case actionTypes.INVOICE_UPDATE_SUCCESS:
      return invoiceUpdateSuccess(state, action);
    case actionTypes.INVOICE_UPDATE_FAILURE:
      return invoiceUpdateFailure(state, action);
    case actionTypes.INVOICE_DELETE_BEGIN:
      return invoiceDeleteBegin(state);
    case actionTypes.INVOICE_DELETE_SUCCESS:
      return invoiceDeleteSuccess(state, action);
    case actionTypes.INVOICE_DELETE_FAILURE:
      return invoiceDeleteFailure(state, action);

    case actionTypes.ECONOMY_CREATE_BEGIN:
      return economyCreateBegin(state);
    case actionTypes.ECONOMY_CREATE_SUCCESS:
      return economyCreateSuccess(state, action);
    case actionTypes.ECONOMY_CREATE_FAILURE:
      return economyCreateFailure(state, action);
    case actionTypes.ECONOMY_UPDATE_BEGIN:
      return economyUpdateBegin(state);
    case actionTypes.ECONOMY_UPDATE_SUCCESS:
      return economyUpdateSuccess(state, action);
    case actionTypes.ECONOMY_UPDATE_FAILURE:
      return economyUpdateFailure(state, action);
    case actionTypes.ECONOMY_DELETE_BEGIN:
      return economyDeleteBegin(state);
    case actionTypes.ECONOMY_DELETE_SUCCESS:
      return economyDeleteSuccess(state, action);
    case actionTypes.ECONOMY_DELETE_FAILURE:
      return economyDeleteFailure(state, action);

    case actionTypes.REALITY_CREATE_BEGIN:
      return realityCreateBegin(state);
    case actionTypes.REALITY_CREATE_SUCCESS:
      return realityCreateSuccess(state, action);
    case actionTypes.REALITY_CREATE_FAILURE:
      return realityCreateFailure(state, action);
    case actionTypes.REALITY_UPDATE_BEGIN:
      return realityUpdateBegin(state);
    case actionTypes.REALITY_UPDATE_SUCCESS:
      return realityUpdateSuccess(state, action);
    case actionTypes.REALITY_UPDATE_FAILURE:
      return realityUpdateFailure(state, action);
    case actionTypes.REALITY_DELETE_BEGIN:
      return realityDeleteBegin(state);
    case actionTypes.REALITY_DELETE_SUCCESS:
      return realityDeleteSuccess(state, action);
    case actionTypes.REALITY_DELETE_FAILURE:
      return realityDeleteFailure(state, action);

    default:
      return state;
  }
};

export default reducer;
