/* eslint-disable react/jsx-props-no-spreading, no-undef */
import {yupResolver} from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import * as yup from 'yup';
import Form from '../../components/Form/Form';
import FormActions from '../../components/FormActions/FormActions';
import FormField from '../../components/FormField/FormField';
import Button from '../../components/UI/Button/Button';
import InputTypePrice from '../../components/UI/InputTypeText/InputTypePrice';
import InputTypeText from '../../components/UI/InputTypeText/InputTypeText';
import SelectCustomer from '../../components/UI/SelectCustomer/SelectCustomer';
import {formatDecimalNumber} from '../../helpers/helpers';
import useReduxForm from '../../hooks/useReduxForm';
import {economyCreate} from '../../store/actions';

// Constraints.
const constraints = yup.object().shape({});

const ProjectPlanCreateItemItemForm = ({projectId, onSubmit}) => {
  const dispatch = useDispatch();
  const {loading, submitErrors} = useReduxForm(onSubmit);

  // Default values.
  const defaultValues = {
    referenceId: null,
    description: '',
    quantity: 0,
    unit: 'item',
    buyPrice: 0,
    sellPrice: 0,
  };

  const {
    control,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm({
    defaultValues,
    resolver: yupResolver(constraints),
  });

  // Handle submit.
  const handleFormSubmit = data => {
    const payload = {};
    payload.project = projectId;
    payload.referenceId = data.referenceId || null;
    payload.description = data.description || '';
    payload.quantity = parseFloat(data.quantity) || 0;
    payload.unit = 'items';
    payload.buyPrice = data.buyPrice || 0;
    payload.sellPrice = data.sellPrice || 0;

    dispatch(
      economyCreate(
        payload.project,
        payload.referenceId,
        payload.description,
        payload.quantity,
        payload.unit,
        payload.buyPrice,
        payload.sellPrice
      )
    );
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Form loading={loading} errors={submitErrors}>
        <FormField label='Dodavatel' errors={errors.referenceId?.message}>
          <Controller
            name='referenceId'
            control={control}
            value={getValues('referenceId')}
            render={({field}) => <SelectCustomer {...field} />}
          />
        </FormField>
        <FormField label='Popis práce' errors={errors.description?.message}>
          <Controller
            name='description'
            control={control}
            value={getValues('description')}
            render={({field}) => <InputTypeText {...field} />}
          />
        </FormField>
        <FormField label='Kusů' errors={errors.quantity?.message}>
          <Controller
            name='quantity'
            control={control}
            value={getValues('quantity')}
            render={({field}) => <InputTypeText {...field} />}
          />
        </FormField>
        <div className='field__group'>
          <FormField label='Cena nákupu za jednotku' errors={errors.buyPrice?.message}>
            <Controller
              name='buyPrice'
              control={control}
              value={getValues('buyPrice')}
              render={({field}) => <InputTypePrice {...field} />}
            />
          </FormField>
          <FormField label='Cena za nákup celkem'>
            {formatDecimalNumber((getValues('buyPrice') || 0) * getValues('quantity'))}
          </FormField>
        </div>
        <div className='field__group'>
          <FormField label='Cena prodeje za jednotku' errors={errors.buyPrice?.message}>
            <Controller
              name='sellPrice'
              control={control}
              value={getValues('sellPrice')}
              render={({field}) => <InputTypePrice {...field} />}
            />
          </FormField>
          <FormField label='Cena za rodej celkem'>
            {formatDecimalNumber((getValues('sellPrice') || 0) * getValues('quantity'))}
          </FormField>
        </div>
        <FormActions>
          <Button value='Vytvořit' type='submit' width='full' />
        </FormActions>
      </Form>
    </form>
  );
};

export default ProjectPlanCreateItemItemForm;

ProjectPlanCreateItemItemForm.defaultProps = {
  projectId: null,
};

ProjectPlanCreateItemItemForm.propTypes = {
  projectId: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
};
