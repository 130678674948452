import * as actionTypes from '../actions/actionTypes';

const initialState = {
  balance: [],
  error: null,
  loading: false,
  filters: {
    dateFrom: null,
    dateTo: null,
  },
};

/* FETCH */
const balanceFetchBegin = state => {
  return {
    ...state,
    error: null,
    loading: true,
  };
};

const balanceFetchSuccess = (state, action) => {
  return {
    ...state,
    balance: action.balance,
    error: null,
    loading: false,
  };
};

const balanceFetchFailure = (state, action) => {
  return {
    ...state,
    error: action.error.message,
    loading: false,
  };
};

const updateBalanceDateFromFilters = (state, action) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      dateFrom: action.dateFrom,
    },
  };
};

const updateBalanceDateToFilters = (state, action) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      dateTo: action.dateTo,
    },
  };
};

const cleanupBalance = state => {
  return {
    ...state,
    balance: [],
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_BALANCE_BEGIN:
      return balanceFetchBegin(state, action);
    case actionTypes.FETCH_BALANCE_SUCCESS:
      return balanceFetchSuccess(state, action);
    case actionTypes.FETCH_BALANCE_FAILURE:
      return balanceFetchFailure(state, action);
    case actionTypes.UPDATE_BALANCE_DATE_FROM_FILTERS:
      return updateBalanceDateFromFilters(state, action);
    case actionTypes.UPDATE_BALANCE_DATE_TO_FILTERS:
      return updateBalanceDateToFilters(state, action);
    case actionTypes.BALANCE_CLEAR:
      return cleanupBalance(state, action);
    default:
      return state;
  }
};

export default reducer;
