import isDate from 'date-fns/isDate';
import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import Empty from '../../components/Empty/Empty';
import List from '../../components/List/List';
import ReportsFilters from '../../components/ReportsFilters/ReportsFilters';
import ReportsTabs from '../../components/ReportsTabs/ReportsTabs';
import Spinner from '../../components/UI/Spinner/Spinner';
import {dateToTimestamp} from '../../helpers/utils';
import {balanceFetch, cleanBalance} from '../../store/actions';
import Page from '../../components/Page/Page';
import PageTitle from '../../components/PageTitle/PageTitle';
import ReportsBalanceProject from '../../components/ReportsBalanceProjects/ReportsBalanceProject';
import './Reports.scss';
import Section from '../../components/Section/Section';

const Reports = () => {
  const {projects, dateFrom, dateTo, customer, member, loading} = useSelector(
    state => ({
      balance: state.balance.balance,
      projects: state.balance.balance.projects,
      loading: state.balance.loading,
      dateFrom: state.reports.filters.dateFrom,
      dateTo: state.reports.filters.dateTo,
      customer: state.reports.filters.customer,
      member: state.reports.filters.member,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const memberValue = member || 0;
    const customerValue = customer || 0;
    if (isDate(dateFrom) && isDate(dateTo)) {
      dispatch(
        balanceFetch(dateToTimestamp(dateFrom), dateToTimestamp(dateTo), customerValue, memberValue)
      );
    } else {
      dispatch(cleanBalance());
    }

    return () => {
      dispatch(cleanBalance());
    };
  }, [dispatch, dateFrom, dateTo, member, customer]);

  let projectsList = [];
  if (projects !== undefined) {
    projectsList = projects.map(project => (
      <ReportsBalanceProject project={project} key={`projects-list-${project.id}`} />
    ));
  }

  const hasTermsSelected = dateFrom != null && dateTo != null;
  const canDisplayProjects = projectsList.length > 0;

  const emptyProjects = (
    <Empty title='Žádné nákupy'>Ve vybraném termínu nebyli nalezeny žádné nákupy.</Empty>
  );

  const emptyTerms = <Empty title='Zvolte termín'>Zvolte termín pro zobrazení nákupů.</Empty>;

  return (
    <Page>
      <Section type='no_border'>
        <PageTitle title='Projekty' />
      </Section>
      <Section type='filters'>
        <ReportsFilters withCustomers />
      </Section>
      <Section type='tabs'>
        <ReportsTabs />
      </Section>
      <Section type='no_border'>
        {loading ? <Spinner /> : null}
        {!loading && !hasTermsSelected ? emptyTerms : null}
        {!loading && hasTermsSelected && !canDisplayProjects ? emptyProjects : null}
        {!loading && canDisplayProjects ? <List type='row'>{projectsList}</List> : null}
      </Section>
    </Page>
  );
};

export default Reports;
