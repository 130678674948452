import React from 'react';
import {IconSettings} from '../../../helpers/icons';
import './ButtonEdit.scss';

const ButtonEdit = ({callback}) => {
  return (
    <button type='button' className='btn--edit' onClick={callback}>
      <IconSettings size={18} stroke={2} />
    </button>
  );
};

export default ButtonEdit;
