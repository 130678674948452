import React, {Component, createRef} from 'react';
import {connect} from 'react-redux';
import {validateEmail} from '../../helpers/helpers';
import * as actions from '../../store/actions';
import Form from '../../components/Form/Form';
import FormField from '../../components/FormField/FormField';
import InputText from '../../components/UI/InputText/InputText';
import Spinner from '../../components/UI/Spinner/Spinner';

class AnonymousIndexInviteMe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formIsValid: false,
      email: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
    this.emailRef = createRef();
  }

  handleChange(value) {
    this.validate(value);
    this.setState({email: value});
  }

  handleSubmit(event) {
    event.preventDefault();
    const {email, formIsValid} = this.state;
    const {onInviteMe} = this.props;
    if (formIsValid) {
      onInviteMe(email);
      this.setState({email: '', formIsValid: false});
      this.emailRef.current.clear();
    }
  }

  validate(value) {
    this.setState({formIsValid: false});
    const valid = validateEmail(value);
    if (valid) {
      this.setState({formIsValid: true});
    } else {
      this.setState({formIsValid: false});
    }
  }

  renderFormContent() {
    const {formIsValid} = this.state;
    return (
      <Form className='form--centered form--bordered form--white'>
        <FormField label='Váš email'>
          <InputText
            onBlur={this.validate}
            onChange={this.handleChange}
            index={1}
            ref={this.emailRef}
          />
        </FormField>
        <div className='form__actions'>
          <button type='submit' className='btn btn--secondary' disabled={!formIsValid}>
            Zkusit zdarma
          </button>
        </div>
        <div className='form__instructions'>
          Na zadaný e-mail obdržíte další informace k&nbsp;dokončení registrace. Souhlasím s GDPR a
          použitím cookies
        </div>
      </Form>
    );
  }

  render() {
    const {loading} = this.props;
    return (
      <form className='register-form form' onSubmit={this.handleSubmit}>
        {loading ? <Spinner /> : this.renderFormContent()}
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInviteMe: email => dispatch(actions.inviteMe(email)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnonymousIndexInviteMe);
