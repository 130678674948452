/* eslint-disable react/jsx-props-no-spreading, no-undef */
import React from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import Form from '../../components/Form/Form';
import FormActions from '../../components/FormActions/FormActions';
import Button from '../../components/UI/Button/Button';
import useReduxForm from '../../hooks/useReduxForm';
import {economyDelete} from '../../store/actions';

const ProjectPlanDeleteItemItem = ({plan, onDelete}) => {
  const dispatch = useDispatch();
  const {loading, submitErrors} = useReduxForm(onDelete);
  const {handleSubmit} = useForm();

  // Handle submit.
  const handleFormSubmit = () => {
    const {id} = plan;
    dispatch(economyDelete(id));
  };

  // Render component.
  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Form loading={loading} errors={submitErrors}>
        <div className='form__warning'>
          Opravdu si přejete smazat položku plánu? Tato akce je nevratná.
        </div>
        <FormActions>
          <Button value='Smazat' type='submit' width='full' />
        </FormActions>
      </Form>
    </form>
  );
};

export default ProjectPlanDeleteItemItem;
