import React, {useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {planUnits} from '../../consts/consts';
import ProjectPlanDeleteItemItem from '../../forms/ProjectPlanDeleteItemItem/ProjectPlanDeleteItemItem';
import ProjectPlanDeleteItemWork from '../../forms/ProjectPlanDeleteItemWork/ProjectPlanDeleteItemWork';
import ProjectPlanUpdateItemItem from '../../forms/ProjectPlanUpdateItemItem/ProjectPlanUpdateItemItem';
import ProjectPlanUpdateItemWork from '../../forms/ProjectPlanUpdateItemWork/ProjectPlanUpdateItemWork';
import {formatDecimalNumber, formattedTime} from '../../helpers/helpers';
import {IconDelete, IconEdit} from '../../helpers/icons';
import {formInit} from '../../store/actions';
import Cell from '../Cell/Cell';
import CellGroup from '../CellGroup/CellGroup';
import Details from '../Details/Details';
import Row from '../Row/Row';
import Modal from '../UI/Modal/Modal';
import Total from '../UI/Total/Total';

const BudgetThumbnail = ({plan}) => {
  const {name, plan_quantity, plan_unit, plan_buy_price, plan_sell_price, reference_id} = plan;

  const [isShowingUpdateModal, toggleUpdateModal] = useState(false);
  const [isShowingDeleteModal, toggleDeleteModal] = useState(false);

  const {members, suppliers} = useSelector(
    state => ({
      members: state.team.members,
      suppliers: state.customers.items,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const planMemberOptions = members.map(member => {
    return {
      label: member.user.name,
      value: member.id,
    };
  });

  const planMemberValue = planMemberOptions.find(planMemberOption => {
    return planMemberOption.value === reference_id;
  });

  const planSupplierOptions = suppliers.map(supplier => {
    return {
      label: supplier.name,
      value: supplier.id,
    };
  });

  const planSupplierValue = planSupplierOptions.find(planSupplierOption => {
    return planSupplierOption.value === reference_id;
  });

  const planUnitOptions = planUnits.map(unitsValue => {
    return {
      label: unitsValue.name,
      value: unitsValue.id,
    };
  });

  const planUnitValue = planUnitOptions.find(planUnitOption => {
    return planUnitOption.value === plan_unit;
  });

  const openUpdateForm = () => {
    dispatch(formInit());
    toggleUpdateModal(true);
  };

  const handleUpdate = () => {
    toggleUpdateModal(false);
  };

  const openDeleteForm = () => {
    dispatch(formInit());
    toggleDeleteModal(true);
  };

  const handleDelete = () => {
    toggleDeleteModal(false);
  };

  /* const handleToReality = () => {
    dispatch(
      realityCreate(
        project ? project.id : null,
        id,
        reference_id,
        name,
        plan_quantity,
        plan_unit,
        plan_buy_price,
        plan_sell_price
      )
    );
  }; */

  let formattedQuantity = plan_quantity;

  let multiplier = plan_quantity;
  const economyClasses = ['plan'];

  if (plan_unit === 'time') {
    economyClasses.push('plan--hours');
    formattedQuantity = formattedTime(plan_quantity);

    const hours = Math.floor(plan_quantity / 60);
    const minutes = (plan_quantity % 60) / 60;
    multiplier = hours + minutes;
  } else {
    economyClasses.push('plan--items');
  }

  const actions = (
    <>
      <button type='button' onClick={openUpdateForm}>
        <IconEdit />
      </button>
      <button type='button' onClick={openDeleteForm}>
        <IconDelete />
      </button>
    </>
  );

  return (
    <Row actions={actions}>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <CellGroup type='info'>
        <Cell type='description' size='auto'>
          {name}
          <Details>
            {/* eslint-disable-next-line no-nested-ternary */}
            {plan_unit === 'time'
              ? planMemberValue
                ? planMemberValue.label
                : '---'
              : planSupplierValue
              ? planSupplierValue.label
              : '---'}
          </Details>
        </Cell>
      </CellGroup>
      <CellGroup type='quantity'>
        <Cell type='quantity' align='right'>
          {formattedQuantity} {planUnitValue ? planUnitValue.label : null}
        </Cell>
      </CellGroup>
      <CellGroup type='prices'>
        <Cell type='price' align='right'>
          {formatDecimalNumber(plan_buy_price)}
          <Total>{formatDecimalNumber(multiplier * plan_buy_price)}</Total>
        </Cell>
        <Cell type='price' align='right'>
          {formatDecimalNumber(plan_sell_price)}
          <Total>{formatDecimalNumber(multiplier * plan_sell_price)}</Total>
        </Cell>
      </CellGroup>
      <Modal
        isShowing={isShowingUpdateModal}
        title='Upravit plán'
        hide={() => toggleUpdateModal(false)}
        type='large'
      >
        {plan_unit === 'time' ? (
          <ProjectPlanUpdateItemWork onSubmit={handleUpdate} plan={plan} />
        ) : (
          <ProjectPlanUpdateItemItem onSubmit={handleUpdate} plan={plan} />
        )}
      </Modal>
      <Modal
        isShowing={isShowingDeleteModal}
        title='Smazat plán'
        hide={() => toggleDeleteModal(false)}
      >
        {plan_unit === 'time' ? (
          <ProjectPlanDeleteItemWork onDelete={handleDelete} plan={plan} />
        ) : (
          <ProjectPlanDeleteItemItem onDelete={handleDelete} plan={plan} />
        )}
      </Modal>
    </Row>
  );
};

export default BudgetThumbnail;
