// Form state.
import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {formInit} from '../store/actions';

const useReduxForm = callback => {
  const dispatch = useDispatch();

  const {loading, submitErrors, submitted} = useSelector(state => ({
    loading: state.forms.form.loading,
    submitErrors: state.forms.form.errors,
    submitted: state.forms.form.submitted,
  }));

  useEffect(() => {
    dispatch(formInit());
  }, []);

  // Close form if form is submitted and without errors.
  useEffect(() => {
    if (submitted === true && submitErrors === null) {
      callback();
    }
  }, [submitted, submitErrors]);

  return {
    loading,
    submitErrors,
    submitted,
  };
};

export default useReduxForm;
