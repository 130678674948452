import * as actionTypes from '../actions/actionTypes';

const initialState = {
  times: [],
  error: null,
  loading: false,
  filters: {
    dateFrom: null,
    dateTo: null,
  },
};

/* FETCH */
const timeFetchBegin = state => {
  return {
    ...state,
    error: null,
    loading: true,
  };
};

const timeFetchSuccess = (state, action) => {
  return {
    ...state,
    times: action.times,
    error: null,
    loading: false,
  };
};

const timeFetchFailure = (state, action) => {
  return {
    ...state,
    error: action.error.message,
    loading: false,
  };
};

/* CREATE */
const timeCreateBegin = state => {
  return {
    ...state,
    error: null,
    loading: true,
  };
};

const timeCreateSuccess = (state, action) => {
  const {time} = action;
  return {
    ...state,
    times: [time, ...state.times],
    error: null,
    loading: false,
  };
};

const timeCreateFailure = (state, action) => {
  return {
    ...state,
    error: action.error.message,
    loading: false,
  };
};

/* UPDATE */
const timeUpdateBegin = state => {
  return {
    ...state,
    error: null,
    loading: true,
  };
};

const timeUpdateSuccess = (state, action) => {
  const times = [...state.times];
  const updatedTimes = times.map(time => {
    if (Number(time.id) !== Number(action.time.id)) {
      return time;
    }
    return {
      ...time,
      ...action.time,
    };
  });

  return {
    ...state,
    times: updatedTimes,
    error: null,
    loading: false,
  };
};

const timeUpdateFailure = (state, action) => {
  return {
    ...state,
    error: action.error.message,
    loading: false,
  };
};

/* DELETE */
const timeDeleteBegin = state => {
  return {
    ...state,
    error: null,
    loading: true,
  };
};

const timeDeleteSuccess = (state, action) => {
  const times = [...state.times];

  const updatedTimes = times.filter(time => {
    return Number(time.id) !== Number(action.id);
  });

  return {
    ...state,
    times: updatedTimes,
    error: null,
    loading: false,
  };
};

const timeDeleteFailure = (state, action) => {
  return {
    ...state,
    error: action.error.message,
    loading: false,
  };
};

const updateTimesDateFromFilters = (state, action) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      dateFrom: action.dateFrom,
    },
  };
};

const updateTimesDateToFilters = (state, action) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      dateTo: action.dateTo,
    },
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TIME_FETCH_BEGIN:
      return timeFetchBegin(state, action);
    case actionTypes.TIME_FETCH_SUCCESS:
      return timeFetchSuccess(state, action);
    case actionTypes.TIME_FETCH_FAILURE:
      return timeFetchFailure(state, action);

    case actionTypes.TIME_CREATE_BEGIN:
      return timeCreateBegin(state);
    case actionTypes.TIME_CREATE_SUCCESS:
      return timeCreateSuccess(state, action);
    case actionTypes.TIME_CREATE_FAILURE:
      return timeCreateFailure(state, action);

    case actionTypes.TIME_UPDATE_BEGIN:
      return timeUpdateBegin(state);
    case actionTypes.TIME_UPDATE_SUCCESS:
      return timeUpdateSuccess(state, action);
    case actionTypes.TIME_UPDATE_FAILURE:
      return timeUpdateFailure(state, action);

    case actionTypes.TIME_DELETE_BEGIN:
      return timeDeleteBegin(state);
    case actionTypes.TIME_DELETE_SUCCESS:
      return timeDeleteSuccess(state, action);
    case actionTypes.TIME_DELETE_FAILURE:
      return timeDeleteFailure(state, action);
    case actionTypes.UPDATE_TIMES_DATE_FROM_FILTERS:
      return updateTimesDateFromFilters(state, action);
    case actionTypes.UPDATE_TIMES_DATE_TO_FILTERS:
      return updateTimesDateToFilters(state, action);
    default:
      return state;
  }
};

export default reducer;
