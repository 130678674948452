import axios from 'axios';
import {displayErrors} from '../../api';
import {memberUpdateMutation} from '../../api/queries';
import * as actionTypes from './actionTypes';
import {formBegin, formError, formSuccess} from './forms';

export const memberUpdateBegin = id => {
  return {
    type: actionTypes.MEMBER_UPDATE_BEGIN,
    id,
  };
};

export const memberUpdateSuccess = member => {
  return {
    type: actionTypes.MEMBER_UPDATE_SUCCESS,
    member,
  };
};

export const memberUpdateFailure = (error, id) => {
  return {
    type: actionTypes.MEMBER_UPDATE_FAILURE,
    error,
    id,
  };
};

export const memberUpdate = (id, payload) => {
  return dispatch => {
    dispatch(formBegin());
    dispatch(memberUpdateBegin(id));

    axios
      .post('/graphql', memberUpdateMutation(id, payload))
      .then(res => {
        displayErrors(res);
        const member = res.data.data.updateMember;
        dispatch(memberUpdateSuccess(member));
        dispatch(formSuccess());
      })
      .catch(error => {
        dispatch(memberUpdateFailure(error, id));
        dispatch(formError('Formulář se nepodařilo odeslat.'));
      });
  };
};
