import isDate from 'date-fns/isDate';

import {cs} from 'date-fns/locale';
import React, {useEffect, useState} from 'react';
import {DatePicker} from 'react-nice-dates';
import {IconDelete} from '../../../helpers/icons';
import './InputDate.scss';

const InputDate = ({value, onChange}) => {
  const [localValue, setLocalValue] = useState(null);

  useEffect(() => {
    if (isDate(value)) {
      setLocalValue(value);
    } else {
      setLocalValue(null);
    }
  }, [value]);

  const handleSelection = value => {
    if (isDate(value)) {
      setLocalValue(value);
      onChange(value);
    }
  };

  const handleReset = () => {
    setLocalValue(null);
    onChange(null);
  };

  return (
    <div className='field_input_wrapper'>
      <DatePicker
        date={localValue}
        onDateChange={handleSelection}
        format='dd. MM. yyyy'
        locale={cs}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {({inputProps}) => <input className='field_input--date' {...inputProps} />}
      </DatePicker>
      {localValue ? (
        <button type='button' className='button--reset' onClick={handleReset}>
          <IconDelete size={16} />
        </button>
      ) : null}
    </div>
  );
};

export default InputDate;
