import React from 'react';
import {NavLink} from 'react-router-dom';
import './DashboardLinks.scss';
import {IconAddresses, IconBalance, IconProjects} from '../../helpers/icons';

const DashboardLinks = () => {
  return (
    <div className='dashboard-links'>
      <NavLink to='/projects' className='dashboard-link'>
        <IconProjects size={16} /> Projekty
      </NavLink>
      <NavLink to='/address-book' className='dashboard-link'>
        <IconAddresses size={16} /> Adresář
      </NavLink>
      <NavLink to='/reports' className='dashboard-link'>
        <IconBalance size={16} /> Reporty
      </NavLink>
    </div>
  );
};

export default DashboardLinks;
