import * as actionTypes from '../actions/actionTypes';

const initialState = {
  members: [],
  invitations: [],
  name: null,
  id: null,
  error: null,
  loading: false,
  updateMemberLoading: [],
  membersInviteLoading: false,
  acceptedInvitation: false,
  subscription: 1,
  teams: [],
};

const fetchTeamBegin = state => {
  return {
    ...state,
    error: null,
    loading: true,
  };
};

const fetchTeamSuccess = (state, action) => {
  return {
    ...state,
    error: null,
    loading: false,
    name: action.name,
    id: action.id,
    members: action.members,
    invitations: action.invitations,
    subscription: action.subscription,
  };
};

const fetchTeamFailure = state => {
  return {
    ...state,
    error: null,
    loading: false,
  };
};

const memberUpdateBegin = (state, action) => {
  return {
    ...state,
    error: null,
    updateMemberLoading: state.updateMemberLoading.concat(action.id),
  };
};

const memberUpdateSuccess = (state, action) => {
  const members = [...state.members];

  const updateMemberProgress = [...state.updateMemberLoading];
  const updateMemberLoading = updateMemberProgress.filter(state => {
    return state !== action.member.id;
  });

  const updatedMembers = members.map(member => {
    if (member.id !== action.member.id) {
      return member;
    }
    return action.member;
  });

  return {
    ...state,
    members: updatedMembers,
    error: null,
    updateMemberLoading,
  };
};

const memberUpdateFailure = (state, action) => {
  const updateMemberProgress = state.updateMemberLoading;
  const updateMemberLoading = updateMemberProgress.filter(state => {
    return state !== action.id;
  });

  return {
    ...state,
    error: action.error.message,
    updateMemberLoading,
  };
};

const membersInviteBegin = state => {
  return {
    ...state,
    error: null,
    membersInviteLoading: true,
  };
};

const membersInviteSuccess = (state, action) => {
  const {invitation} = action;
  return {
    ...state,
    invitations: [...state.invitations, invitation],
    error: null,
    membersInviteLoading: false,
  };
};

const membersInviteFailure = (state, action) => {
  return {
    ...state,
    error: action.error.message,
    membersInviteLoading: false,
  };
};

const acceptAuthInvitationBegin = state => {
  return {
    ...state,
    error: null,
    acceptedInvitation: false,
  };
};

const acceptAuthInvitationSuccess = state => {
  return {
    ...state,
    error: null,
    acceptedInvitation: true,
  };
};

const acceptAuthInvitationFailure = (state, action) => {
  return {
    ...state,
    error: action.error.message,
    acceptedInvitation: false,
  };
};

const teamUpdateBegin = state => {
  return {
    ...state,
    error: null,
  };
};

const teamUpdateSuccess = (state, action) => {
  return {
    ...state,
    name: action.team.name,
    error: null,
  };
};

const teamUpdateFailure = (state, action) => {
  return {
    ...state,
    error: action.error.message,
  };
};

const createTeamBegin = state => {
  return {
    ...state,
    loading: true,
    error: null,
  };
};

const createTeamSuccess = (state, action) => {
  return {
    ...state,
    id: action.team.id,
    name: action.team.name,
    members: action.team.members,
    invitations: action.team.invitations,
    loading: false,
    error: null,
  };
};

const createTeamFailure = (state, action) => {
  return {
    ...state,
    loading: false,
    error: action.error.message,
  };
};

/* FETCH USER TEAMS */
const fetchUserTeamsBegin = state => {
  return {
    ...state,
    error: null,
    loading: true,
  };
};

const fetchUserTeamsSuccess = (state, action) => {
  return {
    ...state,
    error: null,
    loading: false,
    teams: action.teams,
  };
};

const fetchUserTeamsFailure = state => {
  return {
    ...state,
    error: null,
    loading: false,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TEAM_BEGIN:
      return fetchTeamBegin(state);
    case actionTypes.FETCH_TEAM_SUCCESS:
      return fetchTeamSuccess(state, action);
    case actionTypes.FETCH_TEAM_FAILURE:
      return fetchTeamFailure(state);
    case actionTypes.TEAM_CREATE_BEGIN:
      return createTeamBegin(state);
    case actionTypes.TEAM_CREATE_SUCCESS:
      return createTeamSuccess(state, action);
    case actionTypes.TEAM_CREATE_FAILURE:
      return createTeamFailure(state, action);
    case actionTypes.TEAM_UPDATE_BEGIN:
      return teamUpdateBegin(state);
    case actionTypes.TEAM_UPDATE_SUCCESS:
      return teamUpdateSuccess(state, action);
    case actionTypes.TEAM_UPDATE_FAILURE:
      return teamUpdateFailure(state, action);
    case actionTypes.MEMBER_UPDATE_BEGIN:
      return memberUpdateBegin(state, action);
    case actionTypes.MEMBER_UPDATE_SUCCESS:
      return memberUpdateSuccess(state, action);
    case actionTypes.MEMBER_UPDATE_FAILURE:
      return memberUpdateFailure(state, action);
    case actionTypes.MEMBERS_INVITE_BEGIN:
      return membersInviteBegin(state);
    case actionTypes.MEMBERS_INVITE_SUCCESS:
      return membersInviteSuccess(state, action);
    case actionTypes.MEMBERS_INVITE_FAILURE:
      return membersInviteFailure(state, action);
    case actionTypes.ACCEPT_AUTH_INVITATION_BEGIN:
      return acceptAuthInvitationBegin(state);
    case actionTypes.ACCEPT_AUTH_INVITATION_SUCCESS:
      return acceptAuthInvitationSuccess(state);
    case actionTypes.ACCEPT_AUTH_INVITATION_FAILURE:
      return acceptAuthInvitationFailure(state, action);
    case actionTypes.FETCH_USER_TEAMS_BEGIN:
      return fetchUserTeamsBegin(state);
    case actionTypes.FETCH_USER_TEAMS_SUCCESS:
      return fetchUserTeamsSuccess(state, action);
    case actionTypes.FETCH_USER_TEAMS_FAILURE:
      return fetchUserTeamsFailure(state);
    default:
      return state;
  }
};

export default reducer;
