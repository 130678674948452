/* eslint-disable react/jsx-props-no-spreading, no-undef */
import {yupResolver} from '@hookform/resolvers/yup';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import * as yup from 'yup';
import Form from '../../components/Form/Form';
import FormActions from '../../components/FormActions/FormActions';
import FormField from '../../components/FormField/FormField';
import Button from '../../components/UI/Button/Button';
import InputTypeText from '../../components/UI/InputTypeText/InputTypeText';
import SelectCustomer from '../../components/UI/SelectCustomer/SelectCustomer';
import useReduxForm from '../../hooks/useReduxForm';
import {projectCreate} from '../../store/actions';

// Constraints.
const constraints = yup.object().shape({
  name: yup.string().required('Je nutné zadat název projektu.'),
  customer: yup.number().positive().integer().required('Je nutné vybrat klienta.'),
});

const ProjectCreateForm = ({onSubmit}) => {
  const dispatch = useDispatch();
  const {loading, submitErrors} = useReduxForm(onSubmit);

  // Default values.
  const defaultValues = {
    name: '',
    customer: null,
  };

  const {
    control,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm({
    defaultValues,
    resolver: yupResolver(constraints),
  });

  // Handle submit.
  const handleFormSubmit = data => {
    const payload = {};
    payload.name = data.name || '';
    payload.customerId = Number(data.customer) || null;
    payload.redirect = true;
    payload.isNew = false;

    dispatch(projectCreate(payload.name, payload.customerId, payload.redirect, payload.isNew));
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Form loading={loading} errors={submitErrors}>
        <FormField label='Název projektu' errors={errors.name?.message} required>
          <Controller
            name='name'
            control={control}
            value={getValues('name')}
            render={({field}) => <InputTypeText {...field} />}
          />
        </FormField>
        <FormField label='Klient' errors={errors.customer?.message} required>
          <Controller
            name='customer'
            control={control}
            value={getValues('customer')}
            render={({field}) => <SelectCustomer {...field} />}
          />
        </FormField>
        <FormActions>
          <Button value='Vytvořit' type='submit' width='full' />
        </FormActions>
      </Form>
    </form>
  );
};

export default ProjectCreateForm;
