import React from 'react';
import './Cell.scss';

const Cell = ({children, type = null, size = null, align = null}) => {
  const cellClass = ['cell'];

  if (type) {
    cellClass.push(`cell--${type}`);
  }

  if (size) {
    cellClass.push(`cell--${size}`);
  }

  if (align) {
    cellClass.push(`cell--${align}`);
  }

  return <div className={cellClass.join(' ')}>{children}</div>;
};

export default Cell;
