import axios from 'axios';
import {getCookie} from './helpers/utils';
// window.axios = require('axios').default;

// Axios configuration

// Add a request interceptor for checking if access_token exists and is valid.
axios.interceptors.request.use(
  config => {
    if (getCookie('access_token') !== null) {
      // eslint-disable-next-line no-param-reassign
      config.headers.common.Authorization = `${getCookie('token_type')} ${getCookie(
        'access_token'
      )}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

let baseURL = 'https://api.adminic.cz';
let clientId = 2;
let clientSecret = 'r7GKr5di9WyvQ7JLl2fqeOssiEO6Frch6Wwdj8rO';

if (process.env.NODE_ENV === 'development') {
  baseURL = 'http://terik-api.test';
  clientId = 2;
  clientSecret = 'r7GKr5di9WyvQ7JLl2fqeOssiEO6Frch6Wwdj8rO';
}
axios.defaults.baseURL = baseURL;
window.clientId = clientId;
window.clientSecret = clientSecret;
