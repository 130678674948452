import React from 'react';
import './ReportsTabs.scss';
import Tabs from '../Tabs/Tabs';

const ReportsTabs = () => {
  const tabs = [
    {link: '/reports', text: 'Projekty'},
    {link: '/reports/costs', text: 'Nákupy'},
    {link: '/reports/work', text: 'Práce'},
    {link: '/reports/invoices', text: 'Fakturace'},
  ];

  return <Tabs tabs={tabs} />;
};

export default ReportsTabs;
