import {connectRouter} from 'connected-react-router';
import {combineReducers} from 'redux';

import authReducer from './auth';
import balanceReducer from './balance';
import customerReducer from './customer';
import customersReducer from './customers';
import formReducer from './forms';
import projectReducer from './project';
import projectsReducer from './projects';
import teamReducer from './team';
import timeReducer from './time';
import invoicesReducer from './invoices';
import reportsReducer from './reports';
import realitiesReducer from './reality';

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    team: teamReducer,
    customers: customersReducer,
    customer: customerReducer,
    projects: projectsReducer,
    project: projectReducer,
    times: timeReducer,
    invoices: invoicesReducer,
    balance: balanceReducer,
    forms: formReducer,
    reports: reportsReducer,
    realities: realitiesReducer,
  });
