import React from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {projectStates} from '../../consts/consts';
import {
  updateProjectsDateFromFilters,
  updateProjectsDateToFilters,
  updateProjectsFilters,
  updateProjectsSearchFilters,
} from '../../store/actions';
import './ProjectsFilters.scss';
import InputDateRange from '../UI/InputDate/InputDateRange';
import InputTypeText from '../UI/InputTypeText/InputTypeText';

const ProjectsFilters = () => {
  const {statuses, projects, searchText, dateFrom, dateTo} = useSelector(
    state => ({
      statuses: state.projects.filters.status,
      projects: state.projects.items,
      searchText: state.projects.filters.text,
      dateFrom: state.projects.filters.dateFrom,
      dateTo: state.projects.filters.dateTo,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const handleDateFromChange = value => {
    dispatch(updateProjectsDateFromFilters(value));
  };

  const handleDateToChange = value => {
    dispatch(updateProjectsDateToFilters(value));
  };

  const handleChange = value => {
    dispatch(updateProjectsSearchFilters(value));
  };

  const statusFilters = [];
  const projectStatesKeys = Object.keys(projectStates);
  projectStatesKeys.forEach(key => {
    const state = projectStates[key];
    let projectsByState = null;
    if (projects.length !== 0) {
      projectsByState = projects.filter(project => {
        return project.state === state.id;
      });
    }

    statusFilters.push(
      <button
        key={state.id}
        type='button'
        className={`state-filter state-filter--${state.id}${
          statuses[state.id] === true ? ' active' : ''
        }`}
        onClick={() => dispatch(updateProjectsFilters('status', Number(state.id), true))}
      >
        {state.title}
        {projectsByState ? (
          <span className='state-filter__badge'>{projectsByState.length}</span>
        ) : null}
      </button>
    );
  });

  return (
    <div className='filters'>
      <div className='filters__section filters__section--fulltext'>
        <div className='search'>
          <InputTypeText onChange={handleChange} value={searchText} />
        </div>
        <div className='dates'>
          <InputDateRange
            startDate={dateFrom}
            endDate={dateTo}
            onStartDateSelect={handleDateFromChange}
            onEndDateSelect={handleDateToChange}
          />
        </div>
      </div>
      <div className='filters__section filters__section--state'>{statusFilters}</div>
    </div>
  );
};

export default ProjectsFilters;
