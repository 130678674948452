import React, {useMemo} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import CustomersFilters from '../../components/CustomersFilters/CustomersFilters';
import CustomerThumbnail from '../../components/CustomerThumbnail/CustomerThumbnail';
import List from '../../components/List/List';
import Page from '../../components/Page/Page';
import PageTitle from '../../components/PageTitle/PageTitle';
import Section from '../../components/Section/Section';
import PagePreloader from '../../components/UI/PagePreloader/PagePreloader';

const Customers = () => {
  const {loading, customers, customersFilters} = useSelector(
    state => ({
      customers: state.customers.items,
      loading: state.customers.loading,
      customersFilters: state.customers.filters,
    }),
    shallowEqual
  );

  const filteredCustomersList = useMemo(() => {
    if (customers.length === 0) {
      return customers;
    }

    let filteredCustomers = customers;

    // Search
    if (customersFilters.text !== '') {
      filteredCustomers = filteredCustomers.filter(customer => {
        return customer.name.toLowerCase().includes(customersFilters.text.toLowerCase());
      });
    }

    return filteredCustomers;
  }, [customers, customersFilters]);

  if (loading === true) {
    return <PagePreloader />;
  }

  const customersList = filteredCustomersList.map(customer => (
    <CustomerThumbnail customer={customer} key={`customers-list-${customer.id}`} />
  ));

  return (
    <Page>
      <Section type='no_border'>
        <PageTitle title='Adresář' />
      </Section>
      <Section type='filters'>
        <CustomersFilters />
      </Section>
      <Section type='no_border'>
        <List>{customersList}</List>
      </Section>
    </Page>
  );
};

export default Customers;
