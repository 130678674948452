import axios from 'axios';
import {displayErrors} from '../../api';
import {membersInviteMutation} from '../../api/queries';
import * as actionTypes from './actionTypes';
import {formBegin, formError, formSuccess} from './forms';

export const membersInviteBegin = () => {
  return {
    type: actionTypes.MEMBERS_INVITE_BEGIN,
  };
};

export const membersInviteSuccess = invitation => {
  return {
    type: actionTypes.MEMBERS_INVITE_SUCCESS,
    invitation,
  };
};

export const membersInviteFailure = error => {
  return {
    type: actionTypes.MEMBERS_INVITE_FAILURE,
    error,
  };
};

export const membersInvite = (id, emails) => {
  return dispatch => {
    dispatch(formBegin());
    dispatch(membersInviteBegin());

    axios
      .post('/graphql', membersInviteMutation(id, emails))
      .then(res => {
        displayErrors(res);
        const invitation = res.data.data.membersInvite;
        dispatch(membersInviteSuccess(invitation));
        dispatch(formSuccess());
      })
      .catch(error => {
        dispatch(membersInviteFailure(error, id));
        dispatch(formError('Formulář se nepodařilo odeslat.'));
      });
  };
};
