import PropTypes from 'prop-types';
import React from 'react';
import DonutChart from '../UI/Charts/DonutChart';
import './Part.scss';

const Part = ({
  title = null,
  className = null,
  content = null,
  graphValue = null,
  badgeColor = null,
}) => {
  const contentContent = content ? <div className='part-content'>{content}</div> : null;
  const graphContent =
    graphValue !== null ? (
      <div className='part-donut'>
        <DonutChart size={48} strokewidth={4} text='' value={graphValue} showPercentage={false} />
      </div>
    ) : null;

  const badgeStyle = badgeColor ? {backgroundColor: badgeColor} : null;
  const badgeContent = badgeColor ? <div className='part-badge' style={badgeStyle} /> : null;

  const titleContent = title ? (
    <div className='part-title'>
      {badgeContent}
      {title}
    </div>
  ) : null;

  const classes = ['part'];
  if (className) {
    classes.push(className);
  }
  if (graphValue !== null) {
    classes.push('part--with-donut');
  }

  return (
    <div className={classes.join(' ')}>
      {graphContent}
      {titleContent}
      {contentContent}
    </div>
  );
};

Part.propTypes = {
  className: PropTypes.string,
  content: PropTypes.element,
  graphValue: PropTypes.number,
  title: PropTypes.string,
  badgeColor: PropTypes.string,
};

Part.defaultProps = {
  className: null,
  badgeColor: null,
  content: null,
  graphValue: null,
  title: null,
};

export default Part;
