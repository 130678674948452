import React, {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import ProjectTimeCreateForm from '../../forms/ProjectTimeCreateForm/ProjectTimeCreateForm';
import {formatDecimalNumber, formattedTime} from '../../helpers/helpers';
import {IconWork} from '../../helpers/icons';

import {
  memberLoggedTimes,
  memberPlans,
  totalPlansTime,
  totalTimesTime,
  totalWorkPrices,
} from '../../helpers/utils';
import {formInit, me} from '../../store/actions';
import Cell from '../Cell/Cell';
import CellGroup from '../CellGroup/CellGroup';
import Empty from '../Empty/Empty';
import List from '../List/List';
import ListSummary from '../ListSummary/ListSummary';
import Part from '../Part/Part';
import Parts from '../Parts/Parts';
import GroupDay from '../ProjectTimeDay/ProjectTimeDay';
import WorkThumbnail from '../WorkThumbnail/WorkThumbnail';
import SectionActions from '../SectionActions/SectionActions';
import Button from '../UI/Button/Button';
import Modal from '../UI/Modal/Modal';
import Total from '../UI/Total/Total';

const ProjectWork = () => {
  const {times, projectId, plans} = useSelector(
    state => ({
      projectId: state.project.project.id,
      times: state.project.project.times,
      plans: state.project.project.economies,
    }),
    shallowEqual
  );

  const [workTotalBuy, setWorkTotalBuy] = useState(0);
  const [workTotalSell, setWorkTotalSell] = useState(0);

  useEffect(() => {
    const workPrices = totalWorkPrices(times);
    setWorkTotalBuy(workPrices.totalBuy);
    setWorkTotalSell(workPrices.totalSell);
  }, [times]);

  const dispatch = useDispatch();

  // Create modal.
  const [isShowingCreateModal, toggleCreateModal] = useState(false);

  const handleCreateModalOpen = () => {
    dispatch(formInit());
    toggleCreateModal(true);
  };

  const handleCreateModalClose = () => {
    toggleCreateModal(false);
  };

  const createModal = (
    <Modal
      isShowing={isShowingCreateModal}
      title='Vytvořit časový záznam'
      hide={handleCreateModalClose}
      type='large'
    >
      <ProjectTimeCreateForm onSubmit={handleCreateModalClose} projectId={projectId} />
    </Modal>
  );

  const activeUser = dispatch(me());

  const memberTimes = memberLoggedTimes(activeUser.id, times);
  const memberEconomies = memberPlans(activeUser.id, plans);

  const totalEconomyTime = totalPlansTime(plans);
  const memberEconomyTime = totalPlansTime(memberEconomies);

  const totalLoggedTime = totalTimesTime(times);
  const memberLoggedTime = totalTimesTime(memberTimes);

  // Create groups of times by day.
  const groups = times.reduce((groups, time) => {
    const date = time.day.split(' ')[0];
    if (!groups[date]) {
      // eslint-disable-next-line no-param-reassign
      groups[date] = [];
    }
    groups[date].push(time);
    return groups;
  }, {});

  const projectWorks = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(groups)) {
    const date = parseISO(key);
    const formatedDate = format(date, 'dd. MM. yyyy');
    projectWorks.push(<GroupDay key={`date-${formatedDate}`} day={formatedDate} />);

    const listItems = Object.values(value).map(time => {
      return <WorkThumbnail key={`time-${time.id}`} time={time} />;
    });
    projectWorks.push(
      <List type='row' key={`list-${key}`}>
        {listItems}
      </List>
    );
  }

  const hasProjectWorks = projectWorks.length > 0;

  const projectWorksEmpty = (
    <Empty title='Zaznamenávejte odpracované hodiny'>
      Každý pracovník by měl zaznamenávat odpracované hodiny, aby jste měli přehled, zda jste
      správně navrhnuli rozpočet projektu.
      <br />
      Začněte přidáním položek přes tlačítko +.
    </Empty>
  );

  const projectWorksFooter = (
    <ListSummary>
      <CellGroup type='info' />
      <CellGroup type='quantity'>
        <Cell type='day' align='right'>
          <Total>{formattedTime(totalLoggedTime)}</Total>
        </Cell>
      </CellGroup>
      <CellGroup type='prices'>
        <Cell type='price' align='right'>
          <Total>{formatDecimalNumber(workTotalBuy)}</Total>
        </Cell>
        <Cell type='price' align='right'>
          <Total>{formatDecimalNumber(workTotalSell)}</Total>
        </Cell>
      </CellGroup>
    </ListSummary>
  );

  return (
    <>
      {hasProjectWorks ? (
        <Parts>
          <Part
            title={`Odpracováno ${activeUser.user.name}`}
            content={
              <>
                <strong>{formattedTime(memberLoggedTime)}</strong>/
                {formattedTime(memberEconomyTime)} hod.
              </>
            }
          />

          <Part
            title='Odpracováno celkem'
            content={
              <>
                <strong>{formattedTime(totalLoggedTime)}</strong>/{formattedTime(totalEconomyTime)}{' '}
                hod.
              </>
            }
          />
        </Parts>
      ) : null}

      <List type='row'>
        {hasProjectWorks ? projectWorks : projectWorksEmpty}
        {hasProjectWorks ? projectWorksFooter : null}
      </List>
      <SectionActions>
        <Button
          onClick={handleCreateModalOpen}
          icon={<IconWork />}
          value='Přidat práci'
          size='bigger'
        ></Button>
        {createModal}
      </SectionActions>
    </>
  );
};

export default ProjectWork;
