import {useEffect, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';

const useCustomers = id => {
  const [customerId, setCustomerId] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [customers, setCustomers] = useState([]);

  const {storedCustomers} = useSelector(
    state => ({
      storedCustomers: state.customers.items,
    }),
    shallowEqual
  );

  // Save customerId.
  useEffect(() => {
    setCustomerId(id);
  }, [id]);

  // Save customer options.
  useEffect(() => {
    const customersOptions = storedCustomers.map(customersOption => {
      return {
        label: customersOption.name,
        value: customersOption.id,
      };
    });

    setCustomers(customersOptions);
  }, [storedCustomers]);

  // Save selected customer.
  useEffect(() => {
    if (customerId !== null && customers.length > 0) {
      const customerValue = customers.find(customerOption => {
        return customerOption.value === customerId;
      });

      if (customerValue !== undefined) {
        setCustomer(customerValue);
      }
    }

    if (customerId === null) {
      setCustomer(null);
    }
  }, [customerId, customers]);

  return {
    customer,
    customers,
    setCustomerId,
  };
};

export default useCustomers;
