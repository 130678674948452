import * as actionTypes from '../actions/actionTypes';

const initialState = {
  filters: {
    dateFrom: null,
    dateTo: null,
    customer: null,
    member: null,
  },
};

const reportsDateFromFilter = (state, action) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      dateFrom: action.dateFrom,
    },
  };
};

const reportsDateToFilter = (state, action) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      dateTo: action.dateTo,
    },
  };
};

const reportsCustomerFilter = (state, action) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      customer: action.customer,
    },
  };
};

const reportsMemberFilter = (state, action) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      member: action.member,
    },
  };
};

const resetReportsFilters = state => {
  return {
    ...state,
    filters: {
      dateFrom: null,
      dateTo: null,
    },
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_REPORTS_DATE_FROM_FILTER:
      return reportsDateFromFilter(state, action);
    case actionTypes.UPDATE_REPORTS_DATE_TO_FILTER:
      return reportsDateToFilter(state, action);
    case actionTypes.UPDATE_REPORTS_CUSTOMER_FILTER:
      return reportsCustomerFilter(state, action);
    case actionTypes.UPDATE_REPORTS_MEMBER_FILTER:
      return reportsMemberFilter(state, action);
    case actionTypes.RESET_REPORTS_FILTERS:
      return resetReportsFilters(state, action);
    default:
      return state;
  }
};

export default reducer;
