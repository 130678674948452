import * as actionTypes from '../actions/actionTypes';

const initialState = {
  invoices: [],
  error: null,
  loading: false,
  filters: {
    dateFrom: null,
    dateTo: null,
  },
};

/* FETCH */
const invoicesFetchBegin = state => {
  return {
    ...state,
    error: null,
    loading: true,
  };
};

const invoicesFetchSuccess = (state, action) => {
  console.log(action);
  return {
    ...state,
    invoices: action.invoices,
    error: null,
    loading: false,
  };
};

const invoicesFetchFailure = (state, action) => {
  return {
    ...state,
    error: action.error.message,
    loading: false,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INVOICES_FETCH_BEGIN:
      return invoicesFetchBegin(state);
    case actionTypes.INVOICES_FETCH_SUCCESS:
      return invoicesFetchSuccess(state, action);
    case actionTypes.INVOICES_FETCH_FAILURE:
      return invoicesFetchFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
