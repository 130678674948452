import {useEffect, useState} from 'react';
// eslint-disable-next-line import/named
import {memberStates} from '../consts/consts';

const useMembersState = id => {
  const [memberState, setMemberState] = useState(1);

  // Save selected customer.
  useEffect(() => {
    const stateValue = memberStates.find(memberState => {
      return memberState.id === id;
    });

    if (stateValue !== undefined) {
      setMemberState(stateValue);
    }
  }, [memberStates, id]);

  return {
    id: memberState.id,
    value: memberState.title,
  };
};

export default useMembersState;
