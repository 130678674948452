import {format} from 'date-fns';
import parseISO from 'date-fns/parseISO';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {projectStates} from '../../consts/consts';
import {formatProjectNumber} from '../../helpers/utils';
import Cell from '../Cell/Cell';
import CellGroup from '../CellGroup/CellGroup';
import Row from '../Row/Row';

const ReportsBalanceProject = ({project}) => {
  const history = useHistory();
  const {uuid, deadline, project_number, name, state, created_at, customer} = project;

  const handleClick = () => {
    history.push(`/projects/${uuid}`);
  };

  const formattedDeadline = deadline ? format(parseISO(deadline), 'dd. MM. yyyy') : '---';
  let selectedState = null;
  let selectedStateBadge = null;
  if (state !== undefined) {
    selectedState = projectStates.find(projectState => {
      return projectState.id === state;
    });
    selectedStateBadge = (
      <div className={`state ${selectedState.style}`}>{selectedState.title}</div>
    );
  }

  const details = [];

  if (project && project.customer !== undefined) {
    details.push(project.customer.name);
  }

  details.push(formattedDeadline);

  return (
    <Row onClick={handleClick}>
      <CellGroup type='info'>
        <Cell type='state'>{selectedState ? selectedStateBadge : null}</Cell>
        <Cell size='auto'>
          {formatProjectNumber(project_number, created_at)} {name}
        </Cell>
        <Cell>{customer ? customer.name : null}</Cell>
        <Cell type='day' align='right'>
          {formattedDeadline}
        </Cell>
      </CellGroup>
    </Row>
  );
};

export default ReportsBalanceProject;
