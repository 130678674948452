import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import useProjectStates from '../../../hooks/useProjectStates';

const SelectProjectState = ({value, onChange}) => {
  const {states, setStateId} = useProjectStates();

  // Initialize values.
  useEffect(() => {
    setStateId(value);
  }, [value]);

  // Handle selection.
  const handleSelect = value => {
    const newId = value;
    setStateId(newId);
    onChange(parseInt(newId, 10));
  };

  const statesButtons = states.map(stateOption => {
    return (
      <button
        type='button'
        onClick={() => handleSelect(stateOption.value)}
        className={`state state--${stateOption.value}${
          value === stateOption.value ? ' active' : ''
        }`}
      >
        {stateOption.label}
      </button>
    );
  });

  return <div className='buttons-group'>{statesButtons}</div>;
};

export default SelectProjectState;

SelectProjectState.defaultProps = {
  value: null,
};

SelectProjectState.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};
