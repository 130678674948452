import React from 'react';
import {useParams} from 'react-router-dom';
import Page from '../../components/Page/Page';
import PageTitle from '../../components/PageTitle/PageTitle';
import Section from '../../components/Section/Section';
import AnonymousLoginForm from '../../forms/AnonymousLoginForm/AnonymousLoginForm';

const AnonymousLogin = () => {
  function getEmailFromUrl() {
    const {email} = useParams();
    if (!email) {
      return null;
    }
    return window.atob(email);
  }

  function getTokenFromUrl() {
    const {token} = useParams();
    if (!token) {
      return null;
    }
    return token;
  }

  /* function getTeamFromUrl() {
    const {team} = useParams();
    if (!team) {
      return null;
    }
    return window.atob(team);
  }

  function getAuthEmail() {
    const {auth} = props;
    if (!auth.email) {
      return null;
    }
    return auth.email;
  } */

  // const {authenticated} = useAuth();
  const email = getEmailFromUrl();
  const token = getTokenFromUrl();
  // const team = getTeamFromUrl();
  // const authEmail = getAuthEmail();
  /* let content = null;

  // INVITATION IS VALID.

  // USER ISN'T LOGGED IN.
  // Invitation have team id.
  // Register or login user and signup to team.
  // Byl jsem pozván do teamu.
  if (!authenticated && team !== null) {
    content = (
      <AnonymousLoginWithTeam
        token={token}
        email={email}
        team={team}
        history={props.history}
        match={props.match}
      />
    );
  }

  // Invitation doesn't have team id.
  // Register user and display team creation form.
  // Zaregitroval jsem se.
  if (!authenticated && !team && !content) {
    content = (
      <LoginMeWithoutTeam
        token={token}
        email={email}
        team={team}
        history={props.history}
        match={props.match}
      />
    );
  }

  if (authenticated && team !== null && email === authEmail && !content) {
    content = (
      <AcceptTeamInvitation
        token={token}
        email={email}
        team={team}
        history={props.history}
        match={props.match}
      />
    );
  } */

  return (
    <Page>
      <Section type='centered-no-border'>
        <PageTitle title='Přihlásit' />
      </Section>
      <Section type='no_border'>
        <AnonymousLoginForm email={email} token={token} />
      </Section>
    </Page>
  );
};

export default AnonymousLogin;
