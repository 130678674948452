import React from 'react';
import './ReportsFilters.scss';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {
  reportsCustomerFilter,
  reportsDateFromFilter,
  reportsDateToFilter,
  reportsMemberFilter,
} from '../../store/actions';
import InputDateRange from '../UI/InputDate/InputDateRange';
import SelectCustomer from '../UI/SelectCustomer/SelectCustomer';
import SelectMember from '../UI/SelectMember/SelectMember';

const ReportsFilters = ({withCustomers = false, withMembers = false}) => {
  const {dateFrom, dateTo, customer, member} = useSelector(
    state => ({
      dateFrom: state.reports.filters.dateFrom,
      dateTo: state.reports.filters.dateTo,
      customer: state.reports.filters.customer,
      member: state.reports.filters.member,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const handleDateFromChange = value => {
    dispatch(reportsDateFromFilter(value));
  };

  const handleDateToChange = value => {
    dispatch(reportsDateToFilter(value));
  };

  const handleCustomerChange = value => {
    dispatch(reportsCustomerFilter(value));
  };

  const handleMemberChange = value => {
    dispatch(reportsMemberFilter(value));
  };

  return (
    <div className='filters'>
      <div className='filters__section filters__section--fulltext'>
        <div className='dates'>
          <InputDateRange
            startDate={dateFrom}
            endDate={dateTo}
            onStartDateSelect={handleDateFromChange}
            onEndDateSelect={handleDateToChange}
          />
        </div>
        {withCustomers ? (
          <div className='clients'>
            <SelectCustomer value={customer} onChange={handleCustomerChange} />
          </div>
        ) : null}
        {withMembers ? (
          <div className='members'>
            <SelectMember value={member} onChange={handleMemberChange} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ReportsFilters;
