import {format} from 'date-fns';
import parseISO from 'date-fns/parseISO';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {projectStates} from '../../consts/consts';
import {IconConfirmed} from '../../helpers/icons';
import {formatProjectNumber} from '../../helpers/utils';
import './ProjectThumnail.scss';
import State from '../State/State';

const ProjectThumbnail = ({project}) => {
  const history = useHistory();
  const {
    uuid,
    deadline,
    project_number,
    customer,
    name,
    state,
    created_at,
    economy_state,
  } = project;

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      history.push(`/projects/${uuid}`);
    }
  };

  const handleClick = () => {
    history.push(`/projects/${uuid}`);
  };

  const formattedDeadline = deadline ? format(parseISO(deadline), 'dd. MM. yyyy') : '---';
  let selectedState = null;
  let selectedStateBadge = null;
  if (state !== undefined) {
    selectedState = projectStates.find(projectState => {
      return projectState.id === state;
    });
    selectedStateBadge = <State value={selectedState.title} type={selectedState.id} />;
  }

  return (
    <div
      className='project__thumbnail'
      role='link'
      onClick={handleClick}
      onKeyPress={handleKeyPress}
      tabIndex='0'
    >
      <div className='project__thumbnail__top'>
        <div className='project__thumbnail__number'>
          {economy_state === 1 ? <IconConfirmed /> : null}
          {formatProjectNumber(project_number, created_at)}
        </div>
        <div className='project__thumbnail__deadline'>{formattedDeadline}</div>
      </div>
      <div className='project__thumbnail__name'>{name}</div>
      <div className='project__thumbnail__bottom'>
        <div className='project__thumbnail__customer'>{customer ? customer.name : null}</div>
        <div className='project__thumbnail__state'>{selectedState ? selectedStateBadge : null}</div>
      </div>
    </div>
  );
};

export default ProjectThumbnail;
