import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';
import React, {useMemo} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import PagePreloader from '../../components/UI/PagePreloader/PagePreloader';
import {formatProjectNumber} from '../../helpers/utils';
import List from '../../components/List/List';
import Page from '../../components/Page/Page';
import PageTitle from '../../components/PageTitle/PageTitle';
import ProjectsFilters from '../../components/ProjectsFilters/ProjectsFilters';
import ProjectsThumbnail from '../../components/ProjectThumbnail/ProjectThumbnail';
import Section from '../../components/Section/Section';

const Projects = () => {
  const {projects, projectsFilters, loading} = useSelector(
    state => ({
      projects: state.projects.items,
      projectsFilters: state.projects.filters,
      loading: state.projects.loading,
    }),
    shallowEqual
  );

  const filteredProjectsList = useMemo(() => {
    if (projects.length === 0) {
      return projects;
    }

    const activeStates = [];
    const projectsFiltersStates = Object.keys(projectsFilters.status);
    projectsFiltersStates.forEach(key => {
      if (projectsFilters.status[key] === true) {
        activeStates.push(Number(key));
      }
    });

    // Active projects (not archived).
    const activeProjects = projects.filter(project => {
      return project.archived === 0 || project.archived === null;
    });

    // State
    let filteredProjects = activeProjects.filter(project => {
      return activeStates.includes(project.state);
    });

    // Search
    if (projectsFilters.text !== '' && projectsFilters.text !== undefined) {
      filteredProjects = filteredProjects.filter(project => {
        const textFilter = projectsFilters.text.toLowerCase();
        const lowerName = project.name.toLowerCase();
        // Project name.
        if (lowerName.includes(textFilter)) {
          return true;
        }

        // Project number.
        const lowerNumber = formatProjectNumber(
          project.project_number,
          project.created_at
        ).toLowerCase();
        if (lowerNumber.includes(textFilter)) {
          return true;
        }

        // Customer name.
        return !!(
          project.customer &&
          project.customer.name.toLowerCase().includes(projectsFilters.text.toLowerCase())
        );
      });
    }

    // Dates range
    if (projectsFilters.dateFrom && projectsFilters.dateTo) {
      const {dateFrom} = projectsFilters;
      const {dateTo} = projectsFilters;
      filteredProjects = filteredProjects.filter(project => {
        if (project.deadline) {
          const deadline = parseISO(project.deadline);
          if (isAfter(deadline, dateFrom) && isBefore(deadline, dateTo)) {
            return true;
          }
        }

        return false;
      });
    }
    // Date from
    else if (projectsFilters.dateFrom) {
      const {dateFrom} = projectsFilters;
      filteredProjects = filteredProjects.filter(project => {
        if (project.deadline) {
          const deadline = parseISO(project.deadline);
          if (isAfter(deadline, dateFrom)) {
            return true;
          }
        }

        return false;
      });
    }

    // Date to
    else if (projectsFilters.dateTo) {
      const {dateTo} = projectsFilters;
      filteredProjects = filteredProjects.filter(project => {
        if (project.deadline) {
          const deadline = parseISO(project.deadline);
          if (isBefore(deadline, dateTo)) {
            return true;
          }
        }

        return false;
      });
    }

    return filteredProjects;
  }, [projects, projectsFilters]);

  if (loading === true) {
    return <PagePreloader />;
  }

  const projectsList = filteredProjectsList.map(project => (
    <ProjectsThumbnail project={project} key={`projects-list-${project.id}`} />
  ));

  return (
    <Page>
      <Section type='no_border'>
        <PageTitle title='Projekty' />
      </Section>
      <Section type='filters'>
        <ProjectsFilters projects={filteredProjectsList} />
      </Section>
      <Section type='no_border'>
        <List>{projectsList}</List>
      </Section>
    </Page>
  );
};

export default Projects;
