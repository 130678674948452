import React from 'react';
import FormFieldDescription from '../FormFieldDescription/FormFieldDescription';
import FormFieldError from '../FormFieldError/FormFieldError';
import './FormField.scss';

const FormField = ({
  children,
  label = null,
  errors = null,
  description = null,
  required = false,
  className = '',
}) => {
  const requiredMarkup = !required && <span className='non-required'>(Nevyžadováno)</span>;
  const labelContent = label ? (
    <div className='field__label'>
      {label}
      {requiredMarkup}
    </div>
  ) : null;

  return (
    <div className={`field ${className}`}>
      {labelContent}
      <div className='field__content'>{children}</div>
      {errors && <FormFieldError message={errors} />}
      {description && <FormFieldDescription>{description}</FormFieldDescription>}
    </div>
  );
};

export default FormField;
