import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import './Spinner.scss';

const Spinner = ({type}) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
  }, []);
  return (
    <CSSTransition in={loading} timeout={400} classNames='fade' unmountOnExit>
      <div className={`spinner ${type}`}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </CSSTransition>
  );
};

Spinner.propTypes = {
  type: PropTypes.string,
};

Spinner.defaultProps = {
  type: 'default',
};

export default Spinner;
