import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import React, {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import ProjectCostsItemItemCreateForm from '../../forms/ProjectCostsItemItemCreateForm/ProjectCostsItemItemCreateForm';
import {formatDecimalNumber} from '../../helpers/helpers';
import {IconBuy} from '../../helpers/icons';
import {totalCostsPrices} from '../../helpers/utils';
import {formInit} from '../../store/actions';
import Cell from '../Cell/Cell';
import CellGroup from '../CellGroup/CellGroup';
import Empty from '../Empty/Empty';
import List from '../List/List';
import ListSummary from '../ListSummary/ListSummary';
import GroupDay from '../ProjectTimeDay/ProjectTimeDay';
import ProjectReality from '../PurchaseThumbnail/PurchaseThumbnail';
import SectionActions from '../SectionActions/SectionActions';
import Button from '../UI/Button/Button';
import Modal from '../UI/Modal/Modal';
import Total from '../UI/Total/Total';

const ProjectPurchase = () => {
  const {realities, projectId} = useSelector(
    state => ({
      projectId: state.project.project.id,
      realities: state.project.project.realities,
    }),
    shallowEqual
  );

  const [costsTotalBuy, setCostsTotalBuy] = useState(0);
  const [costsTotalSell, setCostsTotalSell] = useState(0);

  useEffect(() => {
    const costsPrices = totalCostsPrices(realities);
    setCostsTotalBuy(costsPrices.totalBuy);
    setCostsTotalSell(costsPrices.totalSell);
  }, [realities]);

  // Create modal.
  const [isShowingCreateModal, toggleCreateModal] = useState(false);
  const dispatch = useDispatch();

  const handleCreateModalOpen = () => {
    dispatch(formInit());
    toggleCreateModal(true);
  };

  const handleCreateModalClose = () => {
    toggleCreateModal(false);
  };

  const createModal = (
    <Modal
      isShowing={isShowingCreateModal}
      title='Vytvořit položku nákupu'
      hide={handleCreateModalClose}
      type='large'
    >
      <ProjectCostsItemItemCreateForm onSubmit={handleCreateModalClose} projectId={projectId} />
    </Modal>
  );

  // Create groups of times by day.
  const groups = realities.reduce((groups, reality) => {
    if (reality.bought_at) {
      const date = reality.bought_at.split(' ')[0];
      if (!groups[date]) {
        // eslint-disable-next-line no-param-reassign
        groups[date] = [];
      }
      groups[date].push(reality);
    }

    return groups;
  }, {});

  const projectCosts = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(groups)) {
    const date = parseISO(key);
    const formatedDate = format(date, 'dd. MM. yyyy');
    projectCosts.push(<GroupDay key={`date-${formatedDate}`} day={formatedDate} />);

    const listItems = Object.values(value).map(reality => {
      return <ProjectReality key={`project-reality-${reality.id}`} reality={reality} />;
    });

    projectCosts.push(
      <List type='row' key={`list-${key}`}>
        {listItems}
      </List>
    );
  }

  const hasProjectCosts = projectCosts.length > 0;

  const projectCostsEmpty = (
    <Empty title='Sledujte veškeré nákupy'>
      Pro sledování veškerých nákladů spojených s projektem je nutné tyto náklady zaznamenávat.
      Budete mít přehled o tom, zda se vejdete do dohodnuté ceny nebo ji již překračujete.
      <br />
      Položky nákladů zadáte přes tlačítko +.
    </Empty>
  );

  const projectCostsFooter = (
    <ListSummary>
      <CellGroup type='info' />
      <CellGroup type='prices'>
        <Cell type='price' align='right'>
          <Total>{formatDecimalNumber(costsTotalBuy)}</Total>
        </Cell>
        <Cell type='price' align='right'>
          <Total>{formatDecimalNumber(costsTotalSell)}</Total>
        </Cell>
      </CellGroup>
    </ListSummary>
  );

  return (
    <>
      <List type='row'>
        {hasProjectCosts ? projectCosts : projectCostsEmpty}
        {hasProjectCosts ? projectCostsFooter : null}
        {createModal}
      </List>
      <SectionActions>
        <Button
          onClick={handleCreateModalOpen}
          value='Přidat nákup'
          icon={<IconBuy />}
          size='bigger'
        ></Button>
        {createModal}
      </SectionActions>
    </>
  );
};

export default ProjectPurchase;
