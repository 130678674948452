import axios from 'axios';
import {NotificationManager} from 'react-notifications';
import {displayErrors} from '../../api/index';
import {
  timeCreateMutation,
  timeDeleteMutation,
  timeFetchQuery,
  timeUpdateMutation,
} from '../../api/queries';
import * as actionTypes from './actionTypes';
import {formBegin, formError, formSuccess} from './forms';

/* Fetch Time */
export const timeFetchBegin = () => {
  return {
    type: actionTypes.TIME_FETCH_BEGIN,
  };
};

export const timeFetchSuccess = times => {
  return {
    type: actionTypes.TIME_FETCH_SUCCESS,
    times,
  };
};

export const timeFetchFailure = error => {
  return {
    type: actionTypes.TIME_FETCH_FAILURE,
    error,
  };
};

export const timeFetch = (from, to, member, customer) => {
  return dispatch => {
    dispatch(timeFetchBegin());

    axios
      .post('/graphql', timeFetchQuery(from, to, member, customer))
      .then(res => {
        displayErrors(res);
        const times = res.data.data.work;
        dispatch(timeFetchSuccess(times));
      })
      .catch(error => {
        dispatch(timeFetchFailure(error));

        NotificationManager.error('Internal error');
      });
  };
};

export const updateTimesDateFromFilters = value => {
  return {
    type: actionTypes.UPDATE_TIMES_DATE_FROM_FILTERS,
    dateFrom: value,
  };
};

export const updateTimesDateToFilters = value => {
  return {
    type: actionTypes.UPDATE_TIMES_DATE_TO_FILTERS,
    dateTo: value,
  };
};

export const timeCreateBegin = () => {
  return {
    type: actionTypes.TIME_CREATE_BEGIN,
  };
};

export const timeCreateSuccess = time => {
  return {
    type: actionTypes.TIME_CREATE_SUCCESS,
    time,
  };
};

export const timeCreateFailure = error => {
  return {
    type: actionTypes.TIME_CREATE_FAILURE,
    error,
  };
};

export const timeCreate = (member, description, project, time, day, buyPrice, sellPrice) => {
  return (dispatch, getStorage) => {
    dispatch(formBegin());
    dispatch(timeCreateBegin());
    const storage = getStorage();
    const team = storage.team ? Number(storage.team.id) : null;
    axios
      .post(
        '/graphql',
        timeCreateMutation(team, member, description, project, time, day, buyPrice, sellPrice)
      )
      .then(res => {
        displayErrors(res);
        const time = res.data.data.createTime;
        dispatch(timeCreateSuccess(time));
        dispatch(formSuccess());
      })
      .catch(error => {
        dispatch(timeCreateFailure(error));
        dispatch(formError('Formulář se nepodařilo odeslat.'));
      });
  };
};

export const timeUpdateBegin = id => {
  return {
    type: actionTypes.TIME_UPDATE_BEGIN,
    id,
  };
};

export const timeUpdateSuccess = time => {
  return {
    type: actionTypes.TIME_UPDATE_SUCCESS,
    time,
  };
};

export const timeUpdateFailure = (error, id) => {
  return {
    type: actionTypes.TIME_UPDATE_FAILURE,
    error,
    id,
  };
};

export const timeUpdate = (id, payload) => {
  return dispatch => {
    dispatch(formBegin());
    dispatch(timeUpdateBegin(id));

    axios
      .post('/graphql', timeUpdateMutation(id, payload))
      .then(res => {
        displayErrors(res);
        const time = res.data.data.updateTime;
        dispatch(timeUpdateSuccess(time));
        dispatch(formSuccess());
      })
      .catch(error => {
        dispatch(timeUpdateFailure(error, id));
        dispatch(formError('Formulář se nepodařilo odeslat.'));
      });
  };
};

export const timeDeleteBegin = id => {
  return {
    type: actionTypes.TIME_DELETE_BEGIN,
    id,
  };
};

export const timeDeleteSuccess = (id, time) => {
  return {
    type: actionTypes.TIME_DELETE_SUCCESS,
    id,
    time,
  };
};

export const timeDeleteFailure = (error, id) => {
  return {
    type: actionTypes.TIME_DELETE_FAILURE,
    error,
    id,
  };
};

export const timeDelete = id => {
  return dispatch => {
    dispatch(formBegin());
    dispatch(timeDeleteBegin(id));
    axios
      .post('/graphql', timeDeleteMutation(id))
      .then(res => {
        displayErrors(res);
        dispatch(timeDeleteSuccess(id));
        dispatch(formSuccess());
      })
      .catch(error => {
        dispatch(timeDeleteFailure(error, id));
        dispatch(formError('Formulář se nepodařilo odeslat.'));
      });
  };
};
