import {querify} from '../helpers/helpers';

export const userLoginQuery = (clientId, clientSecret, email, password, token) => {
  return {
    query: `
      mutation {
        login(username: "${email}", password: "${password}", token: "${token}", client_id: ${clientId}, client_secret: "${clientSecret}") {
          auth_token {
            access_token
            expires_in
            refresh_token
            token_type
          }
          user {
            id
            email
            selectedTeam {
              id
            }
          }
        }
      }
    `,
  };
};

export const userLogoutQuery = () => {
  return {
    query: `
      query {
        logout {
          message
        }
      }
    `,
  };
};

export const userRegisterQuery = (clientId, clientSecret, name, email, password, token) => {
  return {
    query: `
      mutation {
        createUser(name: "${name}", email: "${email}", password: "${password}", token: "${token}", client_id: ${clientId}, client_secret: "${clientSecret}") {
          auth_token {
            access_token
            expires_in
            refresh_token
            token_type
          }
          user {
            id
            email
            selectedTeam {
              id
            }
          }
        }
      }
    `,
  };
};

export const userVerifyQuery = (clientId, clientSecret, email, password, expires, signature) => {
  return {
    query: `
      mutation {
        accountVerify(username: "${email}", password: "${password}", expires: "${expires}", signature: "${signature}", client_id: ${clientId}, client_secret: "${clientSecret}") {
          auth_token {
            access_token
            expires_in
            refresh_token
            token_type
          }
          user {
            id
            email
            selectedTeam {
              id
            }
          }
        }
      }
    `,
  };
};

export const userInviteMeQuery = (clientId, clientSecret, email) => {
  return {
    query: `
      mutation {
        inviteMe(email: "${email}", client_id: ${clientId}, client_secret: "${clientSecret}") {
          id
          email
        }
      }
    `,
  };
};

export const customerFetchQuery = uuid => {
  return {
    query: `
      query {
        customer(uuid: "${uuid}") {
          id
          uuid
          name
          street
          city
          post_code
          country
          phone_number
          email
          contact_person
          vat_number
          company_number
          projects {
            id
            uuid
            name
            state
            member {
              user {
                name
              }
            }
            customer {
              name
            }
            deadline
            project_number
          }
          invoices {
            id
            uuid
            state
            invoiced_at
            deadline_at
            paid_at
            price
            notes
          }
          notes
        }
      }
    `,
  };
};

export const customerCreateMutation = name => {
  return {
    query: `
      mutation {
        createCustomer(name: "${name}"){
          id
          uuid
          name
          state
        }
      }
    `,
  };
};

export const customerUpdateMutation = (id, payload) => {
  return {
    query: `
      mutation {
        updateCustomer(id: ${id}, payload: ${querify(payload)}) {
          id
          uuid
          name
          street
          city
          post_code
          country
          phone_number
          email
          contact_person
          notes
          vat_number
          company_number
          projects {
            id
            uuid
            name
          }
          invoices {
            id
            uuid
            state
            invoiced_at
            deadline_at
            paid_at
            price
          }
        }
      }
    `,
  };
};

export const customersFetchQuery = () => {
  return {
    query: `
      query {
        customers {
          id
          uuid
          name
          state
          email
          phone_number
        }
      }
    `,
  };
};

export const economyCreateQuery = (
  project,
  referenceId,
  name,
  quantity,
  unit,
  buyPrice,
  sellPrice
) => {
  return {
    query: `
      mutation {
        economyCreate(project_id: ${project}, reference_id: ${referenceId}, name: "${name}", plan_quantity: ${quantity}, plan_unit: "${unit}", plan_buy_price: ${buyPrice}, plan_sell_price: ${sellPrice}) {
          id
          uuid
          delta
          state
          type
          reference_id
          reference_type
          name
          plan_unit
          plan_quantity
          plan_buy_price
          plan_sell_price
          real_state
          real_quantity
          real_buy_price
          real_sell_price
          project {
            id
          }
        }
      }
    `,
  };
};

export const economyUpdateMutation = (id, payload) => {
  return {
    query: `
      mutation {
        economyUpdate(id: ${id}, payload: ${querify(payload)}) {
          id
          uuid
          delta
          state
          type
          reference_id
          reference_type
          name
          plan_unit
          plan_quantity
          plan_buy_price
          plan_sell_price
          real_state
          real_quantity
          real_buy_price
          real_sell_price
        }
      }
    `,
  };
};

export const economyDeleteMutation = id => {
  return {
    query: `
      mutation {
        economyDelete(id: ${id}) {
          id
        }
      }
    `,
  };
};

export const projectFetchQuery = uuid => {
  return {
    query: `
      query {
        project(uuid: "${uuid}") {
          id
          uuid
          name
          state
          created_at
          customer_specification
          member {
            id
            user {
              name
            }
          }
          customer {
            id
            uuid
            name
          }
          deadline
          start
          project_number
          notes
          invoices {
            id
            uuid
            state
            number
            invoiced_at
            deadline_at
            paid_at
            price
            notes
            customer {
              id
              uuid
            }
            project {
              id
              uuid
            }
          }
          economies {
            id
            uuid
            delta
            state
            type
            reference_id
            reference_type
            project {
              id
              uuid
            }
            name
            plan_quantity
            plan_unit
            plan_buy_price
            plan_sell_price
            real_state
            real_quantity
            real_buy_price
            real_sell_price
          },
          economy_price,
          economy_number,
          economy_offered_price,
          economy_turnover_price,
          economy_return_price,
          economy_state,
          economy_state_date,
          economy_notes,
          plan_time,
          plan_price,
          realities {
            id
            uuid
            delta
            state
            type
            plan {
              id
              uuid
              state
              type
              reference_id
              reference_type
              name
              plan_unit
              plan_quantity
              plan_buy_price
              plan_sell_price
            }
            name
            unit
            quantity
            buy_price
            sell_price
            reference_id
            reference_type
            bought_at
            project {
              id
              uuid
            }
          },
          times {
            id
            uuid
            project {
              id
              uuid
            }
            member {
              id
              type
              price
              sell_price
              user {
                name
              }
            }
            description
            day
            time
            buy_price
            sell_price
          }
        }
      }
    `,
  };
};

export const invoicesFetchQuery = (from, to, customer) => {
  return {
    query: `
      query {
        invoices(from: ${from}, to: ${to}, customer: ${customer}) {
          id
          uuid
          state
          number
          invoiced_at
          deadline_at
          paid_at
          price
          notes
          customer {
            id
            uuid
            name
          }
          project {
            id
            uuid
            name
          }
        }
      }
    `,
  };
};

export const realitiesFetchQuery = (from, to, customer) => {
  return {
    query: `
      query {
        purchases(from: ${from}, to: ${to}, customer: ${customer}) {
          id
            uuid
            delta
            state
            type
            plan {
              id
              uuid
              state
              type
              reference_id
              reference_type
              name
              plan_unit
              plan_quantity
              plan_buy_price
              plan_sell_price
            }
            name
            unit
            quantity
            buy_price
            sell_price
            reference_id
            reference_type
            bought_at
            project {
              id
              uuid
              name
              customer {
                id
                name
              }
            }
        }
      }
    `,
  };
};

export const projectCreateMutation = (name, clientId) => {
  return {
    query: `
      mutation {
        createProject(name: "${name}", customer: ${clientId}){
          id
          uuid
          name
          state
          member {
            user {
              name
            }
          }
          customer {
            name
            id
            uuid
          }
          start
          deadline
          created_at
          project_number
          economy_price
          economy_state
          archived
        }
      }
    `,
  };
};

export const projectUpdateMutation = (id, payload) => {
  return {
    query: `
      mutation {
        updateProject(id: ${id}, payload: ${querify(payload)}) {
          id
          uuid
          name
          state
          created_at
          customer_specification
          member {
            id
            user {
              name
            }
          }
          customer {
            id
            uuid
            name
          }
          deadline
          start
          project_number
          notes
          invoices {
            id
            uuid
            state
            invoiced_at
            deadline_at
            paid_at
            price
            notes
          }
          economies {
            id
            uuid
            delta
            state
            type
            reference_id
            reference_type
            name
            plan_quantity
            plan_unit
            plan_buy_price
            plan_sell_price
            real_quantity
            real_buy_price
            real_sell_price
          },
          economy_price,
          economy_number,
          economy_offered_price,
          economy_turnover_price,
          economy_return_price,
          economy_state,
          economy_state_date,
          economy_notes,
          plan_time,
          plan_price,
          realities {
            id
            uuid
            delta
            state
            type
            plan {
              id
              uuid
              state
              type
              reference_id
              reference_type
              name
              plan_unit
              plan_quantity
              plan_buy_price
              plan_sell_price
            }
            name
            unit
            quantity
            buy_price
            sell_price
            reference_id
              reference_type
          },
          times {
            id
            uuid
            project {
              id
              uuid
            }
            member {
              id
              type
              price
              sell_price
              user {
                name
              }
            }
            description
            day
            time
          }
        }
      }
    `,
  };
};

export const projectsFetchQuery = () => {
  return {
    query: `
      query {
        projects {
          id
          uuid
          name
          state
          member {
            user {
              name
            }
          }
          customer {
            name
            id
            uuid
          }
          start
          deadline
          created_at
          project_number
          economy_price
          economy_state
          archived
        }
      }
    `,
  };
};

export const balanceFetchQuery = (startDate, endDate, customer, member) => {
  return {
    query: `
      query {
        balance(startDate:  ${startDate}, endDate:  ${endDate}, customer:  ${customer}, member:  ${member}) {
          plan_total
          plan_costs
          plan_earnings
          plan_count
          real_total
          real_costs
          real_earnings
          real_count
          time_total
          time_worked
          time_remains
          turnovers {
            name
            price
          }
          projects {
            id
            uuid
            name
            state
            member {
              user {
                name
              }
            }
            customer {
              name
              id
              uuid
            }
            start
            deadline
            created_at
            project_number
            economy_price
            economy_state
            archived
          }
        }
      }
    `,
  };
};

export const invoiceCreateMutation = (
  project,
  state,
  number,
  amount,
  dateInvoiced,
  dateValidity,
  datePayment
) => {
  return {
    query: `
      mutation {
        createInvoice(project_id: ${project}, state: ${state || 0}, number: "${
      number || ''
    }", price: ${amount || 0}, invoiced_at: ${dateInvoiced || null}, deadline_at: ${
      dateValidity || null
    }, paid_at: ${datePayment || null}) {
          id
          uuid
          state
          invoiced_at
          deadline_at
          paid_at
          price
          number
          project {
            id
            uuid
          }
          customer {
            id
            uuid
          }
        }
      }
    `,
  };
};

export const invoiceUpdateMutation = (id, payload) => {
  return {
    query: `
      mutation {
        updateInvoice(id: ${id}, payload: ${querify(payload)}) {
          id
          uuid
          state
          invoiced_at
          deadline_at
          paid_at
          price
          number
          project {
            id
            uuid
          }
          customer {
            id
            uuid
          }
        }
      }
    `,
  };
};

export const invoiceDeleteMutation = id => {
  return {
    query: `
      mutation {
        deleteInvoice(id: ${id}) {
          id
        }
      }
    `,
  };
};

export const invitationAcceptMutation = token => {
  return {
    query: `
      mutation {
          acceptAuthInvitation(token: "${token}") {
            id
            name
            members {
              id
              user {
                id
                name
              }
              state
              price
              sell_price
            }
            invitations {
              id
              email
            }
          }
        }
    `,
  };
};

export const teamFetchQuery = teamId => {
  return {
    query: `
      query {
        team(id: ${teamId}) {
          id
          uuid
          name
          subscription
          members {
            id
            user {
              id
              name
            }
            state
            price
            sell_price
            roles {
              id
              type
            }
          }
          invitations {
            id
            email
            created_at
            accepted
          }
        }
      }
    `,
  };
};

export const teamFetchDataQuery = teamId => {
  return {
    query: `
      query {
        team(id: ${teamId}) {
          id
          uuid
          name
          members {
            id
            user {
              id
              name
            }
            state
            price
            sell_price
            roles {
              id
              type
            }
          }
          invitations {
            id
            email
            created_at
            accepted
          }
        }
      }
    `,
  };
};

export const teamCreateMutation = (name, emails) => {
  return {
    query: `
      mutation {
        createTeam(name: "${name}", emails: "${emails}") {
          id
          uuid
          name
          members {
            id
            user {
              id
              name
            }
            state
            price
            sell_price
            roles {
              id
              type
            }
          }
          invitations {
            id
            email
            created_at
            accepted
          }
        }
      }
    `,
  };
};

export const teamUpdateMutation = (id, payload) => {
  return {
    query: `
      mutation {
        updateTeam(id: ${id}, payload: ${querify(payload)}) {
          id
          uuid
          name
          members {
            id
            user {
              id
              name
            }
            state
            price
            sell_price
            roles {
              id
              type
            }
          }
          invitations {
            id
            email
            created_at
            accepted
          }
          subscription
        }
      }
    `,
  };
};

export const membersInviteMutation = (id, emails) => {
  return {
    query: `
      mutation {
        membersInvite(id: ${id}, emails: "${emails}") {
          id
          email
          created_at
          accepted
        }
      }
    `,
  };
};

export const userTeamsFetchQuery = () => {
  return {
    query: `
      query {
        teams {
          id
          uuid
          name
        }
      }
    `,
  };
};

export const userSwitchTeamMutation = id => {
  return {
    query: `
      mutation {
        switchTeam(id: ${id}) {
          id
          uuid
        }
      }
    `,
  };
};

export const memberUpdateMutation = (id, payload) => {
  return {
    query: `
      mutation {
        updateMember(id: ${id}, payload: ${querify(payload)}) {
          id
          user {
            id
            name
          }
          state
          price
          sell_price
          roles {
            id
            type
          }
        }
      }
    `,
  };
};

export const timeFetchQuery = (from, to, member, customer) => {
  return {
    query: `
      query {
        work(from: ${from}, to: ${to}, member: ${member}, customer: ${customer}) {
          id
          uuid
          project {
            id
            uuid
            name
            customer {
              id
              name
            }
          }
          member {
            id
            type
            price
            sell_price
            user {
              name
            }
          }
          description
          day
          time
          buy_price
          sell_price
        }
      }
    `,
  };
};

export const timeCreateMutation = (
  team,
  member,
  description,
  project,
  time,
  day,
  buyPrice,
  sellPrice
) => {
  return {
    query: `
      mutation {
        createTime(team: ${team}, member: ${member}, project: ${project}, description: "${description}", time: ${time}, day: "${day}", buy_price: ${buyPrice}, sell_price: ${sellPrice}) {
          id
          uuid
          member {
            id
            type
            price
            sell_price
            user {
              name
            }
          }
          project {
            id
            uuid
          }
          description
          day
          time
          buy_price
          sell_price
        }
      }
    `,
  };
};

export const timeUpdateMutation = (id, payload) => {
  return {
    query: `
      mutation {
        updateTime(id: ${id}, payload: ${querify(payload)}) {
          id
          uuid
          member {
            id
            type
            price
            sell_price
            user {
              name
            }
          }
          project {
            id
            uuid
          }
          description
          day
          time
          buy_price
          sell_price
        }
      }
    `,
  };
};

export const timeDeleteMutation = id => {
  return {
    query: `
      mutation {
        deleteTime(id: ${id}) {
          id
        }
      }
    `,
  };
};

export const realityCreateMutation = (
  project,
  plan,
  referenceId,
  description,
  quantity,
  unit,
  buyPrice,
  sellPrice,
  bought_at
) => {
  return {
    query: `
      mutation {
        realityCreate(project_id: ${project}, plan_id: ${plan}, reference_id: ${referenceId}, name: "${description}", unit: "${unit}", quantity: ${quantity}, buy_price: ${buyPrice}, sell_price: ${sellPrice}, bought_at: ${bought_at}) {
          id
          uuid
          delta
          state
          type
          plan {
            id
            uuid
            state
            type
            reference_id
            reference_type
            name
            plan_unit
            plan_quantity
            plan_buy_price
            plan_sell_price
          }
          name
          unit
          quantity
          buy_price
          sell_price
          reference_id
          reference_type
          bought_at
        }
      }
    `,
  };
};

export const realityUpdateMutation = (id, payload) => {
  return {
    query: `
      mutation {
        realityUpdate(id: ${id}, payload: ${querify(payload)}) {
          id
          uuid
          delta
          state
          type
          plan {
            id
            uuid
            state
            type
            reference_id
            reference_type
            name
            plan_unit
            plan_quantity
            plan_buy_price
            plan_sell_price
          }
          name
          unit
          quantity
          buy_price
          sell_price
          reference_id
          reference_type
          bought_at
        }
      }
    `,
  };
};

export const realityDeleteMutation = id => {
  return {
    query: `
      mutation {
        realityDelete(id: ${id}) {
          id
        }
      }
    `,
  };
};
