import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';

import {
  IconAddresses,
  IconBalance,
  IconProjects,
  IconTeam,
  IconTerik,
  IconUser,
  IconUserLogout,
} from '../../helpers/icons';
import * as actions from '../../store/actions';
import ToolbarPlus from '../ToolbarPlus/ToolbarPlus';
import './Toolbar.scss';

const Toolbar = props => {
  const {checkPermision} = props;
  const canViewProjects = checkPermision('projects.tab.view');
  const canViewCustomers = checkPermision('customers.tab.view');
  const canViewTeam = checkPermision('team.tab.view');

  return (
    <nav className='toolbar'>
      <div className='toolbar__top'>
        <NavLink to='/dashboard' className='tab-dashboard'>
          <IconTerik size={14} />
        </NavLink>
        {canViewProjects ? (
          <NavLink to='/projects' className='tab-projects'>
            <IconProjects size={16} />
          </NavLink>
        ) : null}
        {canViewCustomers ? (
          <NavLink to='/address-book' className='tab-customers'>
            <IconAddresses size={16} />
          </NavLink>
        ) : null}
        <NavLink to='/user/times'>
          <IconUser size={16} />
        </NavLink>
        <NavLink to='/reports'>
          <IconBalance size={16} />
        </NavLink>
      </div>
      <div className='toolbar__center'>
        <ToolbarPlus />
      </div>
      <div className='toolbar__bottom'>
        {canViewTeam ? (
          <NavLink to='/team' className='tab-team'>
            <IconTeam size={16} />
          </NavLink>
        ) : null}
        <NavLink to='/logout'>
          <IconUserLogout size={16} />
        </NavLink>
      </div>
    </nav>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    checkPermision: permission => dispatch(actions.checkPermision(permission)),
  };
};

export default connect(null, mapDispatchToProps)(Toolbar);

Toolbar.propTypes = {
  checkPermision: PropTypes.func.isRequired,
};
