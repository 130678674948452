import React, {Component} from 'react';
import {connect} from 'react-redux';
import Page from '../../components/Page/Page';
import Section from '../../components/Section/Section';
import * as actions from '../../store/actions';
import Spinner from '../../components/UI/Spinner/Spinner';

class UserAcceptInvitation extends Component {
  componentDidMount() {
    let m;
    let token = null;
    const regex = /\?token=(\w*)/gm;
    const {isAuthenticated, location, history, onAcceptAuthInvitation} = this.props;

    // eslint-disable-next-line no-cond-assign
    while ((m = regex.exec(location.search)) !== null) {
      if (m.index === regex.lastIndex) {
        // eslint-disable-next-line no-plusplus
        regex.lastIndex++;
      }
      // eslint-disable-next-line prefer-destructuring
      token = m[1];
    }

    // User is authenticated and token exists.
    if (token !== null && isAuthenticated) {
      onAcceptAuthInvitation(token);
    }
    // User is anonymous and token exists.
    else if (token !== null && !isAuthenticated) {
      history.push(`/register?token=${token}`);
    }
  }

  render() {
    let content = <Spinner />;

    const {error, accepted} = this.props;
    if (error) {
      content = <div className='error-message'>{error}</div>;
    }

    if (accepted) {
      content = <div className='message'>Invitation accepted and verified.</div>;
    }

    return (
      <Page>
        <Section>
          <h1>Accepting invitation</h1>
          {content}
        </Section>
      </Page>
    );
  }
}
const mapStateToProps = state => {
  return {
    error: state.team.error,
    accepted: state.team.acceptedInvitation,
    isAuthenticated: state.auth.access_token !== null,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAcceptAuthInvitation: token => dispatch(actions.invitationAccept(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAcceptInvitation);
