/* eslint-disable react/jsx-props-no-spreading, no-undef */
import {yupResolver} from '@hookform/resolvers/yup';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import * as yup from 'yup';
import Form from '../../components/Form/Form';
import FormActions from '../../components/FormActions/FormActions';
import FormField from '../../components/FormField/FormField';
import Button from '../../components/UI/Button/Button';
import InputTypeHours from '../../components/UI/InputTypeText/InputTypeHours';
import InputTypePrice from '../../components/UI/InputTypeText/InputTypePrice';
import InputTypeText from '../../components/UI/InputTypeText/InputTypeText';
import SelectMember from '../../components/UI/SelectMember/SelectMember';
import {formatDecimalNumber} from '../../helpers/helpers';
import useReduxForm from '../../hooks/useReduxForm';
import {economyUpdate} from '../../store/actions';

// Constraints.
const constraints = yup.object().shape({});

const ProjectPlanUpdateItemWork = ({plan, onSubmit}) => {
  const dispatch = useDispatch();
  const {loading, submitErrors} = useReduxForm(onSubmit);

  const {reference_id, name, plan_quantity, plan_buy_price, plan_sell_price} = plan;

  // Default values.
  const defaultValues = {
    reference_id,
    name,
    plan_unit: 'time',
    plan_quantity,
    plan_buy_price,
    plan_sell_price,
  };

  const {
    control,
    handleSubmit,
    getValues,
    watch,
    formState: {errors},
  } = useForm({
    defaultValues,
    resolver: yupResolver(constraints),
  });

  // Handle submit.
  const handleFormSubmit = data => {
    const {id} = plan;

    const payload = {};
    payload.reference_id = data.reference_id;
    payload.name = data.name || '';
    payload.plan_quantity = parseFloat(data.plan_quantity) || 0;
    payload.plan_buy_price = parseFloat(data.plan_buy_price) || 0;
    payload.plan_sell_price = parseFloat(data.plan_sell_price) || 0;

    dispatch(economyUpdate(id, payload));
  };

  // Render component.
  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Form loading={loading} errors={submitErrors}>
        <FormField label='Člen' errors={errors.reference_id?.message}>
          <Controller
            name='reference_id'
            control={control}
            value={getValues('reference_id')}
            render={({field}) => <SelectMember {...field} />}
          />
        </FormField>
        <FormField label='Popis práce' errors={errors.name?.message}>
          <Controller
            name='name'
            control={control}
            value={getValues('name')}
            render={({field}) => <InputTypeText {...field} />}
          />
        </FormField>
        <FormField label='Hodin' errors={errors.plan_quantity?.message}>
          <Controller
            name='plan_quantity'
            control={control}
            value={getValues('plan_quantity')}
            render={({field}) => <InputTypeHours {...field} />}
          />
        </FormField>
        <div className='field__group'>
          <FormField label='Cena nákupu za jednotku' errors={errors.plan_buy_price?.message}>
            <Controller
              name='plan_buy_price'
              control={control}
              value={getValues('plan_buy_price')}
              render={({field}) => <InputTypePrice {...field} />}
            />
          </FormField>
          <FormField label='Cena za nákup celkem'>
            {formatDecimalNumber((watch('plan_buy_price') || 0) * (watch('plan_quantity') / 60))}
          </FormField>
        </div>
        <div className='field__group'>
          <FormField label='Cena prodeje za jednotku' errors={errors.plan_sell_price?.message}>
            <Controller
              name='plan_sell_price'
              control={control}
              value={getValues('plan_sell_price')}
              render={({field}) => <InputTypePrice {...field} />}
            />
          </FormField>
          <FormField label='Cena za prodej celkem'>
            {formatDecimalNumber((watch('plan_sell_price') || 0) * (watch('plan_quantity') / 60))}
          </FormField>
        </div>
        <FormActions>
          <Button value='Uložit změny' type='submit' width='full' />
        </FormActions>
      </Form>
    </form>
  );
};

export default ProjectPlanUpdateItemWork;
