import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utils';

const initialState = {
  token_type: null,
  access_token: null,
  refresh_token: null,
  expires_in: null,
  verified: null,
  email: null,
  uid: null,
  error: null,
  loading: false,
  authRedirectPath: '/',
  selected_team: null,
};

/**
 * Authentication start.
 * @param state
 * @returns {{}}
 */
const authStart = state => {
  return updateObject(state, {error: null, loading: true});
};

/**
 * Set authentication success redirection path.
 *
 * @param state
 * @param action
 * @returns {{}}
 */
const setAuthRedirectPath = (state, action) => {
  return updateObject(state, {authRedirectPath: action.path});
};

/**
 * Authentication success.
 *
 * @param state
 * @param action
 * @returns {{}}
 */
const authSuccess = (state, action) => {
  return updateObject(state, {
    token_type: action.auth.token_type,
    access_token: action.auth.access_token,
    refresh_token: action.auth.refresh_token,
    expires_in: action.auth.expires_in,
    email: action.user.email,
    uid: Number(action.user.id),
    error: null,
    loading: false,
    selected_team: action.user.selected_team,
    verified: action.user.verified,
  });
};

/**
 * Authentication fail.
 *
 * @param state
 * @param action
 * @returns {{}}
 */
const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error.message,
    loading: false,
  });
};

const registerBegin = state => {
  return updateObject(state, {error: null, loading: true});
};

const registerSuccess = (state, action) => {
  return updateObject(state, {
    token_type: action.auth.token_type,
    access_token: action.auth.access_token,
    refresh_token: action.auth.refresh_token,
    expires_in: action.auth.expires_in,
    email: action.user.email,
    uid: Number(action.user.id),
    error: null,
    loading: false,
    selected_team: action.user.selectedTeam,
    verified: action.user.email_verified_at,
  });
};

const registerFailure = (state, action) => {
  return updateObject(state, {
    error: action.error.message,
    loading: false,
  });
};

/**
 * INVITE ME
 */
const authLogout = state => {
  return updateObject(state, {
    token_type: null,
    access_token: null,
    refresh_token: null,
    selected_team: null,
    expires_in: null,
    email: null,
    uid: null,
  });
};

export const accountVerifyBegin = state => {
  return updateObject(state, {
    verified: false,
    error: null,
    loading: true,
  });
};

export const accountVerifySuccess = state => {
  return updateObject(state, {
    verified: true,
    error: null,
    loading: false,
  });
};

export const accountVerifyFailure = (state, action) => {
  return updateObject(state, {
    verified: false,
    error: action.error.message,
    loading: false,
  });
};

/**
 * Authentication start.
 * @param state
 * @returns {{}}
 */
const inviteMeBegin = state => {
  return {
    ...state,
    error: null,
    loading: true,
  };
};

/**
 * Authentication success.
 *
 * @param state
 * @returns {{}}
 */
const inviteMeSuccess = state => {
  return {
    ...state,
    error: null,
    loading: false,
  };
};

const inviteMeFailure = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

/**
 * Reducer.
 *
 * @param state
 * @param action
 * @returns {*}
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.REGISTER_BEGIN:
      return registerBegin(state);
    case actionTypes.REGISTER_SUCCESS:
      return registerSuccess(state, action);
    case actionTypes.REGISTER_FAILURE:
      return registerFailure(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state);
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    case actionTypes.ACCOUNT_VERIFY_BEGIN:
      return accountVerifyBegin(state);
    case actionTypes.ACCOUNT_VERIFY_SUCCESS:
      return accountVerifySuccess(state);
    case actionTypes.ACCOUNT_VERIFY_FAILURE:
      return accountVerifyFailure(state, action);
    case actionTypes.INVITE_ME_BEGIN:
      return inviteMeBegin(state);
    case actionTypes.INVITE_ME_SUCCESS:
      return inviteMeSuccess(state);
    case actionTypes.INVITE_ME_FAILURE:
      return inviteMeFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
