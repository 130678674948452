import React from 'react';
import {Cell, Pie, PieChart} from 'recharts';

const DonutChart = ({value, size = 48, stroke = 8}) => {
  return (
    <PieChart width={size} height={size}>
      <Pie
        data={value}
        cx='50%'
        cy='50%'
        labelLine={false}
        innerRadius={size / 2 - stroke}
        outerRadius={size / 2}
        fill='#ccc'
      >
        {value.map((entry, index) => (
          <Cell fill={value[index % value.length].color} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default DonutChart;
