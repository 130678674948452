import {ConnectedRouter} from 'connected-react-router';
import React, {useEffect} from 'react';
import {NotificationContainer} from 'react-notifications';
import {useDispatch} from 'react-redux';
import Routes from './pages/Routes/Routes';
import {authCheckState} from './store/actions';

const App = ({history}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authCheckState());
  }, [dispatch]);
  return (
    <>
      <NotificationContainer />
      <ConnectedRouter history={history}>
        <Routes />
      </ConnectedRouter>
    </>
  );
};

export default App;
