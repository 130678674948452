import {format} from 'date-fns';
import parseISO from 'date-fns/parseISO';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {invoiceStates} from '../../consts/consts';
import ProjectInvoiceDeleteForm from '../../forms/ProjectInvoiceDeleteForm/ProjectInvoiceDeleteForm';
import ProjectInvoiceUpdateForm from '../../forms/ProjectInvoiceUpdateForm/ProjectInvoiceUpdateForm';
import {formatDecimalNumber} from '../../helpers/helpers';
import {IconDelete, IconEdit} from '../../helpers/icons';
import {formInit} from '../../store/actions';
import Cell from '../Cell/Cell';
import CellGroup from '../CellGroup/CellGroup';
import Details from '../Details/Details';
import Row from '../Row/Row';
import State from '../State/State';
import Modal from '../UI/Modal/Modal';
import Total from '../UI/Total/Total';

const InvoiceThumbnail = ({invoice}) => {
  // eslint-disable-next-line camelcase
  console.log(invoice);
  const {state, price, number, invoiced_at, deadline_at, paid_at, project, customer} = invoice;
  const [isShowingUpdateModal, toggleUpdateModal] = useState(false);
  const [isShowingDeleteModal, toggleDeleteModal] = useState(false);

  const dispatch = useDispatch();

  const openUpdateForm = () => {
    dispatch(formInit());
    toggleUpdateModal(true);
  };

  const handleUpdate = () => {
    toggleUpdateModal(false);
  };

  const openDeleteForm = () => {
    dispatch(formInit());
    toggleDeleteModal(true);
  };

  const handleDelete = () => {
    toggleDeleteModal(false);
  };

  // eslint-disable-next-line no-shadow
  const stateOptions = invoiceStates.map(state => {
    return {
      label: state.name,
      value: state.id,
    };
  });

  const stateValue = stateOptions.find(stateOption => {
    return stateOption.value === state;
  });

  const actions = (
    <>
      <button type='button' onClick={openUpdateForm}>
        <IconEdit />
      </button>
      <button type='button' onClick={openDeleteForm}>
        <IconDelete />
      </button>
    </>
  );

  const details = [];

  if (customer && customer.name) {
    details.push(customer.name);
  }

  if (project && project.name) {
    details.push(project.name);
  }

  return (
    <Row actions={actions}>
      <CellGroup type='info'>
        <Cell type='state'>{stateValue ? <State value={stateValue.label} /> : '---'}</Cell>
        <Cell type='number' size='auto'>
          {number}
          <Details>{details.join(' • ')}</Details>
        </Cell>
      </CellGroup>
      <CellGroup type='dates'>
        <Cell type='day' align='right'>
          {/* eslint-disable-next-line camelcase */}
          {invoiced_at ? format(parseISO(invoiced_at), 'dd. MM. yyyy') : '---'}
        </Cell>
        <Cell type='day' align='right'>
          {/* eslint-disable-next-line camelcase */}
          {deadline_at ? format(parseISO(deadline_at), 'dd. MM. yyyy') : '---'}
        </Cell>
        <Cell type='day' align='right'>
          {/* eslint-disable-next-line camelcase */}
          {paid_at ? format(parseISO(paid_at), 'dd. MM. yyyy') : '---'}
        </Cell>
      </CellGroup>
      <CellGroup type='prices'>
        <Cell type='price' align='right'>
          <Total>{formatDecimalNumber(price)}</Total>
        </Cell>
      </CellGroup>
      <Modal
        isShowing={isShowingUpdateModal}
        title='Upravit fakturu'
        hide={() => toggleUpdateModal(false)}
        type='large'
      >
        <ProjectInvoiceUpdateForm onSubmit={handleUpdate} invoice={invoice} />
      </Modal>
      <Modal
        isShowing={isShowingDeleteModal}
        title='Smazat fakturu'
        hide={() => toggleDeleteModal(false)}
      >
        <ProjectInvoiceDeleteForm onDelete={handleDelete} invoice={invoice} />
      </Modal>
    </Row>
  );
};

export default InvoiceThumbnail;
