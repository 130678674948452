import axios from 'axios';
import {NotificationManager} from 'react-notifications';
import {customersFetchQuery} from '../../api/queries';
import * as actionTypes from './actionTypes';

export const customersFetchBegin = () => {
  return {
    type: actionTypes.CUSTOMERS_FETCH_BEGIN,
  };
};

export const customersFetchSuccess = customers => {
  return {
    type: actionTypes.CUSTOMERS_FETCH_SUCCESS,
    customers,
  };
};

export const customersFetchFailure = error => {
  return {
    type: actionTypes.CUSTOMERS_FETCH_FAILURE,
    error,
  };
};

export const customersFetch = () => {
  return dispatch => {
    dispatch(customersFetchBegin());

    axios
      .post('/graphql', customersFetchQuery())
      .then(res => {
        const {customers} = res.data.data;
        dispatch(customersFetchSuccess(customers));
      })
      .catch(error => {
        dispatch(customersFetchFailure(error));
        NotificationManager.error('Internal error');
      });
  };
};

export const updateCustomersFilters = (filter, key, value) => {
  return {
    type: actionTypes.UPDATE_CUSTOMERS_FILTERS,
    filter,
    key,
    value,
  };
};

export const updateCustomersSearchFilters = value => {
  return {
    type: actionTypes.UPDATE_CUSTOMERS_SEARCH_FILTERS,
    search: value,
  };
};
