import axios from 'axios';
import {push} from 'connected-react-router';
import {displayErrors} from '../../api';
import {invitationAcceptMutation} from '../../api/queries';
import * as actionTypes from './actionTypes';
import {formBegin, formError, formSuccess} from './forms';

export const invitationAcceptBegin = () => {
  return {
    type: actionTypes.ACCEPT_AUTH_INVITATION_BEGIN,
  };
};

export const invitationAcceptSuccess = team => {
  return {
    type: actionTypes.ACCEPT_AUTH_INVITATION_SUCCESS,
    team,
  };
};

export const invitationAcceptFailure = error => {
  return {
    type: actionTypes.ACCEPT_AUTH_INVITATION_FAILURE,
    error,
  };
};

export const invitationAccept = token => {
  return dispatch => {
    dispatch(formBegin());
    dispatch(invitationAcceptBegin());

    axios
      .post('/graphql', invitationAcceptMutation(token))
      .then(res => {
        displayErrors(res);
        const team = res.data.data.acceptAuthInvitation;
        dispatch(invitationAcceptSuccess(team));
        dispatch(formSuccess());
        dispatch(push('/'));
      })
      .catch(error => {
        dispatch(invitationAcceptFailure(error));
        dispatch(formError('Formulář se nepodařilo odeslat.'));
      });
  };
};
