import React, {useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {EMPTY, planUnits} from '../../consts/consts';
import ProjectCostsItemItemDeleteForm from '../../forms/ProjectCostsItemItemDeleteForm/ProjectCostsItemItemDeleteForm';
import ProjectCostsItemItemUpdateForm from '../../forms/ProjectCostsItemItemUpdateForm/ProjectCostsItemItemUpdateForm';
import {formatDecimalNumber, formattedTime} from '../../helpers/helpers';
import {IconDelete, IconEdit} from '../../helpers/icons';
import {memberLoggedTimes, totalTimesTime} from '../../helpers/utils';
import {formInit} from '../../store/actions';
import Cell from '../Cell/Cell';
import CellGroup from '../CellGroup/CellGroup';
import Details from '../Details/Details';
import Row from '../Row/Row';
import Modal from '../UI/Modal/Modal';
import Total from '../UI/Total/Total';

const PurchaseThumbnail = ({reality}) => {
  // eslint-disable-next-line camelcase
  const {name, quantity, unit, buy_price, sell_price, plan, reference_id, project} = reality;
  const [isShowingUpdateModal, toggleUpdateModal] = useState(false);
  const [isShowingDeleteModal, toggleDeleteModal] = useState(false);

  const {members, suppliers, plans, times} = useSelector(
    state => ({
      members: state.team.members,
      suppliers: state.customers.items,
      plans: state.project.project ? state.project.project.economies : [],
      times: state.project.project ? state.project.project.times : [],
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const realityMemberOptions = members.map(member => {
    return {
      label: member.user.name,
      value: member.id,
    };
  });

  const realityMemberValue = realityMemberOptions.find(realityMemberOption => {
    return realityMemberOption.value === reference_id;
  });

  const realitySupplierOptions = suppliers.map(supplier => {
    return {
      label: supplier.name,
      value: supplier.id,
    };
  });

  const realitySupplierValue = realitySupplierOptions.find(realitySupplierOption => {
    return realitySupplierOption.value === reference_id;
  });

  const realityUnitOptions = planUnits.map(unitsValue => {
    return {
      label: unitsValue.name,
      value: unitsValue.id,
    };
  });

  const realityUnitValue = realityUnitOptions.find(realityUnitOption => {
    return realityUnitOption.value === unit;
  });

  const realityPlanOptions = plans
    .filter(plan => {
      return plan.state === 1;
    })
    .map(plan => {
      return {
        label: plan.name,
        value: plan.id,
      };
    });

  realityPlanOptions.find(realityPlanOption => {
    if (plan === null) {
      return null;
    }
    return realityPlanOption.value === plan.id;
  });

  let realityMemberLoggedTime = 0;

  if (realityMemberValue !== undefined && unit === 'time' && times) {
    const realityMemberTimes = memberLoggedTimes(realityMemberValue.value, times);
    realityMemberLoggedTime = totalTimesTime(realityMemberTimes);
  }

  const realityClasses = ['cost'];

  if (unit === 'time') {
    realityClasses.push('cost--time');
  } else {
    realityClasses.push('cost--items');
  }

  let formattedQuantity = quantity;
  const selectedUnit = planUnits.find(unit => {
    return unit.id === unit;
  });

  if (!selectedUnit) {
    planUnits.find(unit => {
      return unit.id === 'items';
    });
  }

  let multiplier = quantity;

  if (unit === 'time') {
    let timeQuantity = quantity;
    // If we have user reference we must count quantity from logged times.
    if (reference_id) {
      timeQuantity = realityMemberLoggedTime;
    }
    formattedQuantity = formattedTime(timeQuantity);
    const hours = Math.floor(timeQuantity / 60);
    const minutes = (timeQuantity % 60) / 60;
    multiplier = hours + minutes;
  }

  const openUpdateForm = () => {
    dispatch(formInit());
    toggleUpdateModal(true);
  };

  const handleUpdate = () => {
    toggleUpdateModal(false);
  };

  const openDeleteForm = () => {
    dispatch(formInit());
    toggleDeleteModal(true);
  };

  const handleDelete = () => {
    toggleDeleteModal(false);
  };

  const actions = (
    <>
      <button type='button' onClick={openUpdateForm}>
        <IconEdit />
      </button>
      <button type='button' className='button--danger' onClick={openDeleteForm}>
        <IconDelete />
      </button>
    </>
  );

  const details = [];

  if (project && project.customer !== undefined) {
    details.push(project.customer.name);
  }

  if (project && project.name) {
    details.push(project.name);
  }

  if (realitySupplierValue) {
    details.push(realitySupplierValue.label);
  }

  return (
    <Row actions={actions}>
      <CellGroup type='info'>
        <Cell size='auto'>
          {name || EMPTY}
          <Details>{details.join(' • ')}</Details>
        </Cell>
      </CellGroup>
      <CellGroup type='quantity'>
        <Cell type='quantity' align='right'>
          {formattedQuantity} {realityUnitValue ? realityUnitValue.label : null}
        </Cell>
      </CellGroup>
      <CellGroup type='prices'>
        <Cell type='price' align='right'>
          {formatDecimalNumber(buy_price)}
          <Total>{formatDecimalNumber(multiplier * buy_price)}</Total>
        </Cell>
        <Cell type='price' align='right'>
          {formatDecimalNumber(sell_price)}
          <Total>{formatDecimalNumber(multiplier * sell_price)}</Total>
        </Cell>
      </CellGroup>
      <Modal
        isShowing={isShowingUpdateModal}
        title='Upravit položku nákupu'
        hide={() => toggleUpdateModal(false)}
        type='large'
      >
        <ProjectCostsItemItemUpdateForm onSubmit={handleUpdate} cost={reality} />
      </Modal>
      <Modal
        isShowing={isShowingDeleteModal}
        title='Smazat položku nákupu'
        hide={() => toggleDeleteModal(false)}
      >
        <ProjectCostsItemItemDeleteForm onDelete={handleDelete} cost={reality} />
      </Modal>
    </Row>
  );
};

export default PurchaseThumbnail;
