export const querify = obj => {
  // Make sure we don't alter integers.
  if (typeof obj === 'number') {
    return obj;
  }

  // Stringify everything other than objects.
  // eslint-disable-next-line valid-typeof
  if (typeof obj !== 'object' || Array.isArray(obj)) {
    return JSON.stringify(obj);
  }

  // Iterate through object keys to convert into a string
  // to be interpolated into the query.
  const props = Object.keys(obj)
    .map(key => `${key}:${querify(obj[key])}`)
    .join(',');

  return `{${props}}`;
};

// eslint-disable-next-line consistent-return
export const formatMoney = (amount, decimalCount = 2, decimal = '.', thousands = ',') => {
  try {
    // eslint-disable-next-line no-param-reassign
    decimalCount = Math.abs(decimalCount);
    // eslint-disable-next-line no-param-reassign,no-restricted-globals
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    // eslint-disable-next-line radix,no-param-reassign
    const i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    // Fail silently.
  }
};

export const currencyPrice = (amount, currency) => {
  const formatedPrice = formatMoney(amount, 0, '', ' ');
  return `${formatedPrice} ${currency}`;
};

export const formattedTime = time => {
  const hours = Math.floor(Math.abs(time) / 60);
  const minutes = Math.abs(time) % 60;
  const sign = time < 0 ? '-' : '';
  return `${sign}${`${hours}`}:${`0${minutes}`.slice(-2)}`;
};

export const unformattedTime = value => {
  const timeParts = value.split(':');
  const hours = Number(timeParts[0]);
  const minutes = Number(timeParts[1]);
  return Number(hours * 60 + minutes);
};

export const currencyUnformat = price => {
  const decimal = ',';
  const regex = new RegExp(`[^0-9-${decimal}]`, ['g']);
  return parseFloat(
    `${price}`
      .replace(/\((?=\d+)(.*)\)/, '-$1') // replace bracketed values with negatives
      .replace(regex, '') // strip out any cruft
      .replace(decimal, '.') // make sure decimal point is standard
  );
};

export const addClass = (el, classNameToAdd) => {
  // eslint-disable-next-line no-param-reassign
  el.className += ` ${classNameToAdd}`;
};

export const removeClass = (el, classNameToRemove) => {
  let elClass = ` ${el.className} `;
  while (elClass.indexOf(` ${classNameToRemove} `) !== -1) {
    elClass = elClass.replace(` ${classNameToRemove} `, '');
  }
  // eslint-disable-next-line no-param-reassign
  el.className = elClass;
};

export const validateEmail = email => {
  const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return re.test(String(email).toLowerCase());
};

export const formatDecimalNumber = number => {
  if (typeof number !== 'number') {
    return null;
  }

  return number.toLocaleString('cs-CZ', {
    style: 'currency',
    currency: 'CZK',
    maximumFractionDigits: '2',
  });
};

export const formatRoundNumber = number => {
  if (typeof number !== 'number') {
    return null;
  }

  return number.toLocaleString('cs-CZ', {
    style: 'currency',
    currency: 'CZK',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const formatNumber = number => {
  if (typeof number !== 'number') {
    return null;
  }
  return number.toLocaleString('cs-CZ', {minimumFractionDigits: 0});
};

export const formatPriceNumber = number => {
  if (typeof number !== 'number') {
    return null;
  }

  return number.toLocaleString('cs-CZ', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
