/* eslint-disable react/jsx-props-no-spreading, no-undef, no-use-before-define */
import {yupResolver} from '@hookform/resolvers/yup';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import * as yup from 'yup';
import FormActions from '../../components/FormActions/FormActions';
import Button from '../../components/UI/Button/Button';
import InputTypeText from '../../components/UI/InputTypeText/InputTypeText';
import useReduxForm from '../../hooks/useReduxForm';
import {membersInvite} from '../../store/actions';
import Form from '../../components/Form/Form';
import FormField from '../../components/FormField/FormField';

// Constraints.
const constraints = yup.object().shape({});

const TeamMembersInviteForm = ({teamId}) => {
  const dispatch = useDispatch();
  const {loading, submitErrors} = useReduxForm(() => setValue('email', ''));

  // Default values.
  const defaultValues = {
    email: '',
  };

  const {
    control,
    handleSubmit,
    getValues,
    formState: {errors},
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(constraints),
  });

  // Handle submit.
  const handleFormSubmit = data => {
    const payload = {};
    payload.email = data.email || '';

    dispatch(membersInvite(teamId, payload.email));
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Form loading={loading} errors={submitErrors} className='form--inline form--centered'>
        <FormField label='E-mail' errors={errors.email?.message} required>
          <Controller
            name='email'
            control={control}
            value={getValues('email')}
            render={({field}) => <InputTypeText {...field} />}
          />
        </FormField>
        <FormActions>
          <Button value='Pozvat' type='submit' />
        </FormActions>
      </Form>
    </form>
  );
};

export default TeamMembersInviteForm;
