import React, {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useHistory, useRouteMatch} from 'react-router';
import CustomerProjects from '../../components/CustomerProjects/CustomerProjects';
import CustomerReport from '../../components/CustomerReport/CustomerReport';
import Page from '../../components/Page/Page';
import PageTitle from '../../components/PageTitle/PageTitle';
import Section from '../../components/Section/Section';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import ButtonBack from '../../components/UI/ButtonBack/ButtonBack';
import ButtonEdit from '../../components/UI/ButtonEdit/ButtonEdit';
import Modal from '../../components/UI/Modal/Modal';
import PagePreloader from '../../components/UI/PagePreloader/PagePreloader';
import CustomerAddressUpdateForm from '../../forms/CustomerAddressUpdateForm/CustomerAddressUpdateForm';
import CustomerPersonUpdateForm from '../../forms/CustomerPersonUpdateForm/CustomerPersonUpdateForm';
import useMediaQuery from '../../hooks/useMediaQuery';
import {customerCleanup, customerFetch} from '../../store/actions';

const Customer = () => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const [isShowingPersonUpdateModal, togglePersonUpdateModal] = useState(false);
  const [isShowingAddressUpdateModal, toggleAddressUpdateModal] = useState(false);

  const {customer} = useSelector(
    state => ({
      loading: state.customer.loading,
      errors: state.customer.errors,
      customer: state.customer.customer,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    const {uuid} = match.params;
    if (uuid) {
      dispatch(customerFetch(uuid));
    }

    return () => {
      dispatch(customerCleanup());
    };
  }, [match, dispatch]);

  /* const onTitleChange = value => {
    dispatch(
      customerUpdate(customer.id, {
        name: value,
      })
    );
  }; */

  const backToCustomers = () => {
    history.push(`/address-book`);
  };

  const goToProject = uuid => {
    history.push(`/projects/${uuid}`);
  };

  if (customer === null) {
    return <PagePreloader />;
  }

  const {name, projects} = customer;

  const openPersonUpdateForm = () => {
    togglePersonUpdateModal(true);
  };

  const openAddressUpdateForm = () => {
    toggleAddressUpdateModal(true);
  };

  const handleUpdate = () => {
    togglePersonUpdateModal(false);
    toggleAddressUpdateModal(false);
  };

  const aside = (
    <>
      <Section>
        <SectionTitle title='Hodnocení' />
        <CustomerReport customer={customer} />
      </Section>
      <Section>
        <SectionTitle title='Kontaktní osoba' />
        <ButtonEdit callback={openPersonUpdateForm} />
        {customer.contact_person || '---'}
        <br />
        {customer.email || '---'}
        <br />
        {customer.phone_number || '---'}
        <Modal
          isShowing={isShowingPersonUpdateModal}
          title='Upravit kontaktní osobu'
          hide={() => togglePersonUpdateModal(false)}
          type='large'
        >
          <CustomerPersonUpdateForm onSubmit={handleUpdate} customer={customer} />
        </Modal>
      </Section>
      <Section>
        <SectionTitle title='Adresa' />
        <ButtonEdit callback={openAddressUpdateForm} />
        {customer.company_number || '---'}
        <br />
        {customer.vat_number || '---'}
        <br />
        {customer.street || '---'}
        <br />
        {customer.post_code || '---'}
        <br />
        {customer.city || '---'}
        <br />
        {customer.country || '---'}
        <Modal
          isShowing={isShowingAddressUpdateModal}
          title='Upravit adresu'
          hide={() => toggleAddressUpdateModal(false)}
          type='large'
        >
          <CustomerAddressUpdateForm onSubmit={handleUpdate} customer={customer} />
        </Modal>
      </Section>
    </>
  );

  /* const CustomerName = contentEditable('h1'); */

  return (
    <Page aside={isDesktop ? aside : null}>
      <Section>
        <ButtonBack value='Zpět na adresář' onClick={backToCustomers} />
        <PageTitle title={name} />
      </Section>
      {!isDesktop ? (
        <Section>
          <SectionTitle title='Kontaktní osoba' />
          <ButtonEdit callback={openPersonUpdateForm} />
          {customer.contact_person || '---'}
          <br />
          {customer.email || '---'}
          <br />
          {customer.phone_number || '---'}
          <Modal
            isShowing={isShowingPersonUpdateModal}
            title='Upravit kontaktní osobu'
            hide={() => togglePersonUpdateModal(false)}
            type='large'
          >
            <CustomerPersonUpdateForm onSubmit={handleUpdate} customer={customer} />
          </Modal>
        </Section>
      ) : null}
      {!isDesktop ? (
        <Section>
          <SectionTitle title='Adresa' />
          <ButtonEdit callback={openAddressUpdateForm} />
          {customer.company_number || '---'}
          <br />
          {customer.vat_number || '---'}
          <br />
          {customer.street || '---'}
          <br />
          {customer.post_code || '---'}
          <br />
          {customer.city || '---'}
          <br />
          {customer.country || '---'}
          <Modal
            isShowing={isShowingAddressUpdateModal}
            title='Upravit adresu'
            hide={() => toggleAddressUpdateModal(false)}
            type='large'
          >
            <CustomerAddressUpdateForm onSubmit={handleUpdate} customer={customer} />
          </Modal>
        </Section>
      ) : null}
      <Section>
        <SectionTitle title='Projekty' />
        <CustomerProjects projects={projects} goToProject={goToProject} />
      </Section>
    </Page>
  );
};

export default Customer;
