import React from 'react';
import TeamMember from '../TeamMember/TeamMember';

const TeamMembers = ({members}) => {
  if (!members) {
    return null;
  }

  let teamMembers = members.map(member => {
    return <TeamMember key={member.id} member={member} />;
  });

  if (!teamMembers) {
    teamMembers = <p>Anybody</p>;
  }

  return <div>{teamMembers}</div>;
};

export default TeamMembers;
