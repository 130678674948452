import React, {Component} from 'react';

class InputText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: props.value || '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.clear = this.clear.bind(this);
  }

  handleChange(event) {
    this.setState({content: event.target.value});
    const {onChange} = this.props;
    if (onChange) {
      onChange(event.target.value);
    }
  }

  handleUpdate() {
    if (this.shouldUpdate()) {
      const {onBlur} = this.props;
      const {content} = this.state;
      onBlur(content);
    }
  }

  handleKeyDown(e) {
    const characterCode = e.keyCode;
    if (characterCode === 13 || characterCode === 27) {
      this.handleUpdate();
    }
  }

  clear() {
    this.setState({content: ''});
  }

  shouldUpdate() {
    const {content} = this.state;
    if (content === undefined) {
      return false;
    }
    if (content === null) {
      return false;
    }
    const {value} = this.props;
    return content !== value;
  }

  render() {
    const {index, type, className, placeholder} = this.props;
    let classes = 'custom-input';
    if (className) {
      classes = `${classes} ${className}`;
    }
    const {content} = this.state;
    return (
      <input
        value={content}
        type={type || 'text'}
        className={classes}
        onBlur={this.handleUpdate}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        tabIndex={index || -1}
        autoCorrect='off'
        autoCapitalize='off'
        placeholder={placeholder || ''}
      />
    );
  }
}

export default InputText;
