import React, {useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {EMPTY} from '../../consts/consts';
import ProjectTimeDeleteForm from '../../forms/ProjectTimeDeleteForm/ProjectTimeDeleteForm';
import ProjectTimeUpdateForm from '../../forms/ProjectTimeUpdateForm/ProjectTimeUpdateForm';
import {formatDecimalNumber, formattedTime} from '../../helpers/helpers';
import {IconDelete, IconEdit} from '../../helpers/icons';
import {formInit} from '../../store/actions';
import Cell from '../Cell/Cell';
import CellGroup from '../CellGroup/CellGroup';
import Details from '../Details/Details';
import Row from '../Row/Row';
import Modal from '../UI/Modal/Modal';
import Total from '../UI/Total/Total';

const WorkThumbnail = ({time}) => {
  const {description, member, time: timeValue, buy_price, sell_price, project} = time;
  const [isShowingTimerUpdateModal, toggleTimerUpdateModal] = useState(false);
  const [isShowingTimerDeleteModal, toggleTimerDeleteModal] = useState(false);

  const {members} = useSelector(
    state => ({
      members: state.team.members,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const timeMemberOptions = members.map(member => {
    return {
      label: member.user.name,
      value: member.id,
    };
  });

  const timeMemberValue = timeMemberOptions.find(timeMemberOption => {
    return timeMemberOption.value === member.id;
  });

  const openUpdateForm = () => {
    dispatch(formInit());
    toggleTimerUpdateModal(true);
  };

  const handleUpdate = () => {
    toggleTimerUpdateModal(false);
  };

  const openDeleteForm = () => {
    dispatch(formInit());
    toggleTimerDeleteModal(true);
  };

  const handleDelete = () => {
    toggleTimerDeleteModal(false);
  };

  const hours = Math.floor(timeValue / 60);
  const buyPrice = buy_price || 0; // member ? member.price : 0;
  const sellPrice = sell_price || 0; // member ? member.sell_price : 0;

  const actions = (
    <>
      <button type='button' onClick={openUpdateForm}>
        <IconEdit />
      </button>
      <button type='button' onClick={openDeleteForm}>
        <IconDelete />
      </button>
    </>
  );

  const details = [];

  if (timeMemberValue) {
    details.push(timeMemberValue.label);
  }

  if (project && project.customer !== undefined) {
    details.push(project.customer.name);
  }

  if (project && project.name) {
    details.push(project.name);
  }

  return (
    <Row actions={actions}>
      <CellGroup type='info'>
        <Cell type='description' size='auto'>
          {description || EMPTY}
          <Details>{details.join(' • ')}</Details>
        </Cell>
      </CellGroup>
      <CellGroup type='quantity'>
        <Cell type='day' align='right'>
          {formattedTime(timeValue)}
        </Cell>
      </CellGroup>
      <CellGroup type='prices'>
        <Cell type='price' align='right'>
          {formatDecimalNumber(buyPrice)}
          <Total>{formatDecimalNumber(hours * buyPrice)}</Total>
        </Cell>
        <Cell type='price' align='right'>
          {formatDecimalNumber(sellPrice)}
          <Total>{formatDecimalNumber(hours * sellPrice)}</Total>
        </Cell>
      </CellGroup>

      <Modal
        isShowing={isShowingTimerUpdateModal}
        title='Upravit časový záznam'
        hide={() => toggleTimerUpdateModal(false)}
        type='large'
      >
        <ProjectTimeUpdateForm onSubmit={handleUpdate} time={time} />
      </Modal>
      <Modal
        isShowing={isShowingTimerDeleteModal}
        title='Smazat časový záznam'
        hide={() => toggleTimerDeleteModal(false)}
      >
        <ProjectTimeDeleteForm onDelete={handleDelete} time={time} />
      </Modal>
    </Row>
  );
};

export default WorkThumbnail;

WorkThumbnail.propTypes = {};

WorkThumbnail.defaultProps = {};
