import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import InputSelect from '../InputSelect/InputSelect';
import {offerStates} from '../../../consts/consts';

const SelectOfferState = ({value, onChange}) => {
  const [stateId, setStateId] = useState(null);
  const [state, setState] = useState(null);
  const [states, setStates] = useState([]);

  // Save stateId.
  useEffect(() => {
    setStateId(value);
  }, [value]);

  // Save offer states options.
  useEffect(() => {
    const statesOptions = offerStates.map(state => {
      return {
        label: state.name,
        value: state.id,
      };
    });

    setStates(statesOptions);
  }, []);

  // Save selected state.
  useEffect(() => {
    if (stateId !== null && states.length > 0) {
      const stateValue = states.find(stateOption => {
        return stateOption.value === stateId;
      });

      if (stateValue !== undefined) {
        setState(stateValue);
      }
    }
  }, [stateId, states]);

  // Handle selection.
  const handleSelect = value => {
    const newStateId = value.value;
    setStateId(newStateId);
    onChange(newStateId);
  };

  return (
    <InputSelect
      options={states}
      value={state}
      placeholder={state ? state.label : null}
      onChange={handleSelect}
      classNamePrefix
    />
  );
};

export default SelectOfferState;

SelectOfferState.defaultProps = {
  value: null,
};

SelectOfferState.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};
