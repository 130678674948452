/* eslint-disable react/jsx-props-no-spreading, no-undef */
import {yupResolver} from '@hookform/resolvers/yup';
import parseISO from 'date-fns/parseISO';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import * as yup from 'yup';
import Form from '../../components/Form/Form';
import FormActions from '../../components/FormActions/FormActions';
import FormField from '../../components/FormField/FormField';
import Button from '../../components/UI/Button/Button';
import InputDate from '../../components/UI/InputDate/InputDate';
import InputTypePrice from '../../components/UI/InputTypeText/InputTypePrice';
import InputTypeText from '../../components/UI/InputTypeText/InputTypeText';
import SelectOfferState from '../../components/UI/SelectOfferState/SelectOfferState';
import {dateToTimestamp} from '../../helpers/utils';
import useReduxForm from '../../hooks/useReduxForm';
import {projectUpdate} from '../../store/actions';

// Constraints.
const constraints = yup.object().shape({});

const ProjectsOfferUpdateForm = ({onSubmit, project}) => {
  const dispatch = useDispatch();
  const {loading, submitErrors} = useReduxForm(onSubmit);

  const {economy_price, economy_state, economy_number, economy_state_date, economy_notes} = project;

  // Default values.
  const defaultValues = {
    economy_price,
    economy_state,
    economy_number,
    economy_state_date: economy_state_date ? parseISO(economy_state_date) : null,
    economy_notes,
  };

  const {
    control,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm({
    defaultValues,
    resolver: yupResolver(constraints),
  });

  // Handle submit.
  const handleFormSubmit = data => {
    const {id} = project;
    const payload = {};
    payload.economy_price = parseFloat(data.economy_price) || 0;
    payload.economy_state = data.economy_state || 0;
    if (data.economy_state_date) {
      payload.economy_state_date = dateToTimestamp(data.economy_state_date);
    }

    payload.economy_number = data.economy_number || '';
    payload.economy_notes = data.economy_notes || '';
    dispatch(projectUpdate(id, payload));
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Form loading={loading} errors={submitErrors}>
        <FormField label='Dohodnutá cena' errors={errors.economy_price?.message}>
          <Controller
            name='economy_price'
            control={control}
            value={getValues('economy_price')}
            render={({field}) => <InputTypePrice {...field} />}
          />
        </FormField>
        <FormField label='Stav' errors={errors.economy_state?.message}>
          <Controller
            name='economy_state'
            control={control}
            value={getValues('economy_state')}
            render={({field}) => <SelectOfferState {...field} />}
          />
        </FormField>
        <FormField label='Datum potvrzení klientem' errors={errors.economy_state_date?.message}>
          <Controller
            name='economy_state_date'
            control={control}
            value={getValues('economy_state_date')}
            render={({field}) => <InputDate {...field} />}
          />
        </FormField>
        <FormField label='Číslo nabídky' errors={errors.economy_number?.message}>
          <Controller
            name='economy_number'
            control={control}
            value={getValues('economy_number')}
            render={({field}) => <InputTypeText {...field} />}
          />
        </FormField>
        <FormField label='Způsob objednání' errors={errors.economy_notes?.message}>
          <Controller
            name='economy_notes'
            control={control}
            value={getValues('economy_notes')}
            render={({field}) => <InputTypeText {...field} />}
          />
        </FormField>
        <FormActions>
          <Button value='Uložit změny' type='submit' width='full' />
        </FormActions>
      </Form>
    </form>
  );
};

export default ProjectsOfferUpdateForm;
