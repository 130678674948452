import axios from 'axios';
import {NotificationManager} from 'react-notifications';
import {balanceFetchQuery} from '../../api/queries';
import * as actionTypes from './actionTypes';

export const updateBalanceDateFromFilters = value => {
  return {
    type: actionTypes.UPDATE_BALANCE_DATE_FROM_FILTERS,
    dateFrom: value,
  };
};

export const updateBalanceDateToFilters = value => {
  return {
    type: actionTypes.UPDATE_BALANCE_DATE_TO_FILTERS,
    dateTo: value,
  };
};

export const balanceFetchBegin = () => {
  return {
    type: actionTypes.FETCH_BALANCE_BEGIN,
  };
};

export const balanceFetchSuccess = balance => {
  return {
    type: actionTypes.FETCH_BALANCE_SUCCESS,
    balance,
  };
};

export const balanceFetchFailure = error => {
  return {
    type: actionTypes.FETCH_BALANCE_FAILURE,
    error,
  };
};

export const balanceFetch = (startDate, endDate, customer, member) => {
  return dispatch => {
    dispatch(balanceFetchBegin());

    axios
      .post('/graphql', balanceFetchQuery(startDate, endDate, customer, member))
      .then(res => {
        const {balance} = res.data.data;
        dispatch(balanceFetchSuccess(balance));
      })
      .catch(error => {
        dispatch(balanceFetchFailure(error));
        NotificationManager.error('Internal error');
      });
  };
};
